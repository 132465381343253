import React, { useEffect } from 'react';
import _ from 'lodash';

import DefaultLayout from '@/Components/DefaultLayout';
import { LIST } from '@/Constants';
import { CommonColors } from '@/Themes';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { IDataWhyIdraPoll } from '@/Interfaces/WhyIDRApoll.interface';
import { useSelector } from 'react-redux';
import { RootState, useTypedDispatch } from '@/store';
import WhyIdraPollACtions from '@/Actions/WhyIDRApoll.action';
import Media from '@/Components/Dialogs/Media';

const { getWhyIdraPoll, createWhyIdraPoll, updateWhyIdraPoll } =
  WhyIdraPollACtions;

interface isMessage {
  title: string;
}

const DEFAULT_DATA_WHY_IDRA_POLL = {
  title: '',
  content: [{ title: '', image: '', description: '' }],
  languageCode: 'en',
};

const WhyIDRApoll = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();

  const [currentLanguage, setCurrentLanguage] = React.useState<string>('en');
  const [dataIDRApoll, setDataIDRApoll] = React.useState<IDataWhyIdraPoll>(
    DEFAULT_DATA_WHY_IDRA_POLL
  );
  const [isMessage, setIsMessage] = React.useState<isMessage>({
    title: '',
  });
  const [isOpenMedia, setIsOpenMedia] = React.useState<{
    isOpen: boolean;
    type: string;
    key: any;
  }>({ isOpen: false, type: '', key: '' });

  const payload: IDataWhyIdraPoll = useSelector((state: RootState) =>
    _.get(state.WHY_IDRA_POLL, 'dataWhyIdrapoll')
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.WHY_IDRA_POLL, 'isActionLoading')
  );

  useEffect(() => {
    if (currentLanguage) dispatch(getWhyIdraPoll(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if (!_.isEmpty(payload)) setDataIDRApoll(payload);
  }, [payload]);

  const handleAddMore = () => {
    const newAddmore = [
      ...dataIDRApoll.content,
      { title: '', image: '', description: '' },
    ];
    setDataIDRApoll({ ...dataIDRApoll, content: newAddmore });
  };

  const handRemove = (key: number) => {
    const newForm = _.filter(
      dataIDRApoll.content,
      (_item, index) => index !== key
    );
    setDataIDRApoll({ ...dataIDRApoll, content: newForm });
  };

  const handleOnChange = (contentKey: string, value: any, key: number) => {
    const newForm = _.map(dataIDRApoll.content, (item, index) => {
      if (index === key) {
        return {
          ...item,
          [contentKey]: value,
        };
      }
      return item;
    });
    setDataIDRApoll({ ...dataIDRApoll, content: newForm });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!_.trim(dataIDRApoll.title)) {
      setIsMessage({
        ...isMessage,
        title: `${t('message.canNotLeaveTheTitleBlank')}`,
      });
    } else {
      if (_.isEmpty(payload)) {
        dispatch(
          createWhyIdraPoll({ ...dataIDRApoll, languageCode: currentLanguage })
        );
      } else {
        dispatch(
          updateWhyIdraPoll(currentLanguage, {
            ...dataIDRApoll,
            languageCode: currentLanguage,
          })
        );
      }
    }
  };

  const _renderHeader = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
          variant="h4"
        >
          {t('title.whyIDRApoll')}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={2} display={'flex'}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 80,
            }}
          >
            {t('label.language')}
          </InputLabel>
          <Select
            value={currentLanguage}
            fullWidth
            size="small"
            onChange={(e: any) => {
              setCurrentLanguage(e.target.value);
              setDataIDRApoll({
                ...dataIDRApoll,
                languageCode: e.target.value,
              });
            }}
            sx={{
              minWidth: '100px',
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
              height: '45px',
            }}
          >
            {LIST.DROPDOWN_LIST.OPTIONS_LANGUAGE.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              my: 1,
            }}
          >
            {t('label.title')}
          </InputLabel>
          <TextField
            size="small"
            label={t('label.title')}
            required
            fullWidth
            value={dataIDRApoll.title}
            error={!_.isEmpty(isMessage.title)}
            helperText={isMessage.title}
            onChange={(e: any) => {
              setDataIDRApoll({ ...dataIDRApoll, title: e.target.value });
              setIsMessage({ title: '' });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {_renderContent()}
        </Grid>
      </Grid>
    );
  };

  const _renderContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {dataIDRApoll.content &&
            _.map(dataIDRApoll.content, (itemContent, index) => {
              return (
                <Grid container key={index}>
                  <Grid item xs={12}>
                    <Typography>
                      {t('title.content')} {index + 1}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      border: '1px solid',
                      p: 4,
                      mt: 1,
                      position: 'relative',
                    }}
                    key={`row${dataIDRApoll.content.length}-${index}`}
                  >
                    <Button
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      color="error"
                      onClick={() => handRemove(index)}
                      disabled={dataIDRApoll?.content?.length <= 1}
                    >
                      <CloseIcon />
                    </Button>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        label={t('label.title')}
                        fullWidth
                        value={itemContent.title}
                        onChange={(e) =>
                          handleOnChange('title', e.target.value, index)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        label={t('label.linkImage')}
                        fullWidth
                        value={itemContent.image}
                        onChange={(e) =>
                          handleOnChange('image', e.target.value, index)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setIsOpenMedia({
                                    isOpen: true,
                                    type: 'image',
                                    key: index,
                                  });
                                }}
                              >
                                <CloudUploadIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={itemContent.description}
                        label={t('label.description')}
                        multiline
                        rows={4}
                        fullWidth
                        onChange={(e) =>
                          handleOnChange('description', e.target.value, index)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
        <Grid item textAlign={'center'} xs={12}>
          <Button onClick={() => handleAddMore()}>
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderFooter = () => {
    return (
      <Grid container>
        <Grid item mt={2} textAlign={'end'} xs={12}>
          <LoadingButton
            variant="contained"
            color="diSerria"
            sx={{ mr: 2 }}
            loading={isActionLoading}
            onClick={() => setDataIDRApoll(DEFAULT_DATA_WHY_IDRA_POLL)}
          >
            {t('button.reset')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            color="oceanGreen"
            loading={isActionLoading}
          >
            {t('button.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2}>
        {_renderHeader()}
        <Grid item xs={12} mt={2} component="form" onSubmit={handleSubmit}>
          {_renderBody()}
          {_renderFooter()}
        </Grid>
        {isOpenMedia.isOpen && (
          <Media
            onOpen={{
              status: isOpenMedia.isOpen,
              type: isOpenMedia.type,
              key: isOpenMedia.key,
            }}
            onClose={() => {
              setIsOpenMedia({ isOpen: false, type: '', key: '' });
            }}
            callback={(file: any, type: string, key: any) => {
              if (type) {
                handleOnChange(type, file, key);
                setIsOpenMedia({ isOpen: false, type: '', key: '' });
              }
            }}
          />
        )}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default WhyIDRApoll;
