import React, { useEffect } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import { LIST, ROUTERS } from '@/Constants';
import { DatePicker, TextEditor } from '@/Components/Common';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Utils from '@/Utils';
import {
  IDataCreateResults,
  IDataResults,
} from '@/Interfaces/Results.interface';
import { RootState, useTypedDispatch } from '@/store';
import _ from 'lodash';
import { ResultsActions } from '@/Actions';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import Media from '@/Components/Dialogs/Media';

const { updateResults, getResultsById } = ResultsActions;

const DEFAULT_DATA_RESULTS = {
  languageCode: 'en',
  title: '',
  meta: '',
  tag: '',
  category: 'politics',
  imageDescription: '',
  imageUrl: '',
  content: '',
  publishDate: '',
};

const DEFAULT_MESSAGE = {
  title: '',
  imageDescription: '',
  imageUrl: '',
  content: '',
};

interface isMessage {
  title: string;
  imageDescription: string;
  imageUrl: string;
  content: string;
}

const ResultsUpdate = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const locationState = useLocation().state;
  const [dataResults, setDataResults] =
    React.useState<IDataCreateResults>(DEFAULT_DATA_RESULTS);
  const [isMessage, setIsMessage] = React.useState<isMessage>(DEFAULT_MESSAGE);
  const [isOpenMedia, setIsOpenMedia] = React.useState<{
    isOpen: boolean;
    type: string;
  }>({ isOpen: false, type: '' });

  const resultsDetail: IDataResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'resultsDetail')
  );

  const validatePayload = () => {
    const requiredPayload: {
      key: string;
      label: string;
      value: any;
      type: string;
    }[] = [
        {
          key: 'title',
          label: 'title',
          value: dataResults.title,
          type: 'title',
        },
      ];
    const result = Utils.detectValidPayload(requiredPayload);
    return result;
  };

  useEffect(() => {
    if (locationState && locationState.id) {
      dispatch(getResultsById(locationState.id));
    } else {
      Utils.redirect(ROUTERS.RESULTS);
    }
  }, [locationState]);

  useEffect(() => {
    if (_.isEmpty(resultsDetail)) setDataResults(resultsDetail);
  }, [resultsDetail]);

  useEffect(() => {
    if (resultsDetail) setDataResults(resultsDetail);
  }, [resultsDetail]);

  const handleOnChange = (key: string, value: string) => {
    if (key) setDataResults({ ...dataResults, [key]: value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let message: isMessage = isMessage;
    const { isValid, invalidMsg } = validatePayload();
    if (!isValid) message = invalidMsg;
    if (!_.trim(dataResults.title))
      message = {
        ...message,
        title: `${t('message.canNotLeaveTheTitleBlank')}`,
      };
    if (!_.trim(dataResults.imageDescription))
      message = {
        ...message,
        imageDescription: `${t('message.imageDescriptionCannotBeEmpty')}`,
      };
    if (!_.trim(dataResults.imageUrl))
      message = {
        ...message,
        imageUrl: `${t('message.imageUrlCannotBeEmpty')}`,
      };
    if (!_.trim(dataResults.content))
      message = { ...message, content: `${t('message.contentCannotBeEmpty')}` };
    if (_.find(message, (mess) => mess.length > 0)) {
      setIsMessage(message);
    } else dispatch(updateResults(locationState.id, dataResults));
  };

  const _renderTop = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
            variant="h4"
          >
            {t('title.updateResults')}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2} direction={'row'}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2.5} display={'flex'}>
              <InputLabel
                sx={{
                  fontWeight: '500',
                  fontSize: 16,
                  mt: 1,
                  minWidth: 80,
                }}
              >
                {t('label.language')}
              </InputLabel>
              <Select
                value={dataResults.languageCode || ''}
                fullWidth
                size="small"
                onChange={(e: any) =>
                  handleOnChange('languageCode', e.target.value)
                }
                sx={{
                  minWidth: '80px',
                  '	.MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                  height: '45px',
                }}
              >
                {LIST.DROPDOWN_LIST.OPTIONS_LANGUAGE_RESULT.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} display={'flex'}>
              <InputLabel
                sx={{
                  fontWeight: '500',
                  fontSize: 16,
                  mt: 1,
                  minWidth: 80,
                }}
              >
                {t('label.title')}
              </InputLabel>
              <TextField
                value={dataResults.title || ''}
                size="small"
                label={t('label.title')}
                required
                error={!_.isEmpty(isMessage.title)}
                helperText={isMessage.title}
                fullWidth
                onChange={(e: any) => {
                  handleOnChange('title', e.target.value);
                  setIsMessage({ ...isMessage, title: '' });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} display={'flex'}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 80,
            }}
          >
            {t('label.tags')}
          </InputLabel>
          <TextField
            value={dataResults.tag || ''}
            size="small"
            label={t('label.tags')}
            fullWidth
            onChange={(e: any) => handleOnChange('tag', e.target.value)}
          />
        </Grid>
        <Grid item xs={4} display={'flex'}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 50,
            }}
          >
            {t('label.meta')}
          </InputLabel>
          <TextField
            value={dataResults.meta || ''}
            size="small"
            label={t('label.meta')}
            fullWidth
            onChange={(e: any) => handleOnChange('meta', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2.5} display={'flex'}>
              <InputLabel
                sx={{ fontWeight: '500', fontSize: 16, mt: 1, minWidth: 80 }}
              >
                {t('label.category')}
              </InputLabel>
              <Select
                value={dataResults.category || ''}
                fullWidth
                size="small"
                sx={{
                  minWidth: '80px',
                  '	.MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                  height: '45px',
                }}
                onChange={(e: any) =>
                  handleOnChange('category', e.target.value)
                }
              >
                {LIST.DROPDOWN_LIST.OPTIONS_CATEGORY.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value || ''}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>

        {/* Published date */}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2.5} display={'flex'}>
              <InputLabel
                sx={{ fontWeight: '500', fontSize: 16, mt: 1, minWidth: 80 }}
              >
                {t('table.published')}
              </InputLabel>
              <DatePicker
                value={dataResults.publishedDate || ''}
                onChange={(date: any) => handleOnChange('publishedDate', date)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <InputLabel
                sx={{
                  fontWeight: '500',
                  fontSize: 16,
                  my: 1,
                  minWidth: 120,
                }}
              >
                {t('label.carouselImageDescription')}
              </InputLabel>
              <TextField
                value={dataResults.imageDescription || ''}
                size="small"
                label={t('label.description')}
                fullWidth
                multiline
                rows={4}
                required
                error={!_.isEmpty(isMessage.imageDescription)}
                helperText={isMessage.imageDescription}
                onChange={(e: any) => {
                  handleOnChange('imageDescription', e.target.value);
                  setIsMessage({ ...isMessage, imageDescription: '' });
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <InputLabel
                sx={{
                  fontWeight: '500',
                  fontSize: 16,
                  my: 1,
                  minWidth: 120,
                }}
              >
                {t('label.linkImage')}
              </InputLabel>
              <TextField
                size="small"
                label={t('label.linkImage')}
                required
                fullWidth
                error={!_.isEmpty(isMessage.imageUrl)}
                helperText={isMessage.imageUrl}
                value={dataResults.imageUrl || ''}
                onChange={(e: any) => {
                  handleOnChange('imageUrl', e.target.value);
                  setIsMessage({ ...isMessage, imageUrl: '' });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setIsOpenMedia({
                            isOpen: true,
                            type: 'imageUrl',
                          });
                        }}
                      >
                        <CloudUploadIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 80,
            }}
            required
          >
            {t('label.editor')}
          </InputLabel>
          <TextEditor
            value={dataResults.content || ''}
            message={isMessage.content}
            onChange={(e: any) => {
              handleOnChange('content', e);
              setIsMessage({ ...isMessage, content: '' });
            }}
          />
          {isMessage.content && (
            <Typography color="error">{isMessage.content}</Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const _renderFooter = () => {
    return (
      <Grid container mt={2}>
        <Grid item xs={4}>
          <LoadingButton
            variant="contained"
            color="astronaut"
            onClick={() => Utils.redirect(ROUTERS.RESULTS)}
          >
            {t('button.cancel')}
          </LoadingButton>
        </Grid>
        <Grid item textAlign={'end'} xs={8}>
          <LoadingButton
            variant="contained"
            color="diSerria"
            sx={{ mr: 2 }}
            onClick={() => setDataResults(DEFAULT_DATA_RESULTS)}
          >
            {t('button.reset')}
          </LoadingButton>
          <LoadingButton type="submit" variant="contained" color="oceanGreen">
            {t('button.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2} spacing={2}>
        <Grid item xs={12}>
          {_renderTop()}
          <Divider />
        </Grid>
        <Grid item xs={12} component="form" onSubmit={handleSubmit}>
          {_renderBody()}
          {_renderFooter()}
        </Grid>
        {isOpenMedia.isOpen && (
          <Media
            onOpen={{
              status: isOpenMedia.isOpen,
              type: isOpenMedia.type,
            }}
            onClose={() => {
              setIsOpenMedia({ isOpen: false, type: '' });
            }}
            callback={(file: any, type: string) => {
              if (type) {
                handleOnChange(type, file);
                setIsOpenMedia({ isOpen: false, type: '' });
              }
            }}
          />
        )}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default ResultsUpdate;
