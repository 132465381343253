import * as React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import WhyIdraPollActions from '@/Actions/WhyIDRApoll.action';
import { IDataWhyIdraPoll } from '@/Interfaces/WhyIDRApoll.interface';
import { Stack, Box, Typography, Grid } from '@mui/material';
import { sectionTitleStyle } from './home.style';
import { Loading } from '@/Components/Common';

const { getWhyIdraPoll, clearWhyIdraPoll } = WhyIdraPollActions;

const WhyIdraPollSection: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const payload: IDataWhyIdraPoll = useSelector((state: RootState) =>
    _.get(state.WHY_IDRA_POLL, 'dataWhyIdrapoll')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.WHY_IDRA_POLL, 'isGetLoading')
  );
  const currentLanguage = Utils.getSavedLanguage();

  React.useEffect(() => {
    dispatch(getWhyIdraPoll(currentLanguage));
    return () => {
      dispatch(clearWhyIdraPoll());
    };
  }, [currentLanguage]);

  const _renderWhyIdraPollContent = () => (
    <Box className="content" sx={{ textAlign: 'center', mt: '5vw' }}>
      <Grid className="whyIdraPoll" container spacing={2}>
        {!_.isEmpty(payload) &&
          payload.content.map((items, index) => (
            <Grid
              className="WhyIdraPollItems"
              key={index}
              item
              xs={12}
              md={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: '10%',
              }}
            >
              <Box
                className="itemImage"
                sx={{
                  position: 'relative',
                  mb: { xs: 'none', md: '4vw' },
                }}
              >
                <Box
                  sx={{
                    width: '40%',
                    pb: '40%',
                    m: 'auto',
                    mb: '-3%',
                    background: 'transition',
                    position: 'absolute',
                    border: 'solid thin #FF6347',
                    bottom: 0,
                    left: { xs: '30%', lg: '30%', xl: '30%' },
                    transform: 'rotate(45deg)',
                    transition: 'all .3s ease-in-out',
                    display: { xs: 'none', md: 'block' },
                    '&:hover': {
                      transform: 'rotate(0deg)',
                    },
                  }}
                ></Box>

                <Box sx={{ width: '30%', m: '0 auto' }}>
                  <Box
                    component="img"
                    src={items.image}
                    sx={{
                      width: '100%',
                      transition: 'all .3s ease-in-out',
                    }}
                  />
                </Box>
              </Box>
              <Typography
                sx={{
                  mb: '0.5vw',
                  fontSize: { xs: 'calc(1.3rem + .6vw)', lg: '1.75rem' },
                  fontWeight: 500,
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                {items.title}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily:
                    'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                }}
              >
                {items.description}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Box>
  );

  return (
    <Box component="section" className="whyIdraPoll">
      <Stack
        className="whyIdraPoll-container"
        sx={{
          width: '100%',
          px: 'var(--bs-gutter-x,.75rem)',
          py: '6%',
          mx: 'auto',
          maxWidth: {
            xs: '540px',
            md: '960px',
            lg: '1140px',
            xl: '1320px',
          },
        }}
      >
        <Box className="title">
          <Typography sx={sectionTitleStyle}>
            {t('title.whyIDRApoll')}
          </Typography>
        </Box>
        {isLoading ? <Loading /> : _renderWhyIdraPollContent()}
      </Stack>
    </Box>
  );
};

export default WhyIdraPollSection;
