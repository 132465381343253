import _ from 'lodash';
import { ROUTERS, ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { LoginStructure } from '@interfaces/Auth.interface';
import Swal from 'sweetalert2';

// SINGLE ACTIONS

const setAuthActionLoading = () => {
  return {
    type: ACTION_TYPES.SET_AUTH_ACTION_LOADING,
  };
};

const setLogged = () => {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
};

const logout = () => {
  return (dispatch: any) => {
    Utils.clearAllSavedData();
    dispatch(setLogged());
    Utils.replace(ROUTERS.ADMIN);
  };
};

// ASYNC ACTIONS
const loginSuccess = () => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
  };
};

const loginFail = () => {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE,
  };
};

const login = (payload: LoginStructure) => {
  return async (dispatch: any) => {
    dispatch(setAuthActionLoading());
    await API.login(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          Swal.fire({
            title: 'Login Failed',
            text: _.get(response, 'data.message'),
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
          await dispatch(loginFail())
        } else {
          const token = _.get(result, 'accessToken.token') || '';
          const refreshToken = _.get(result, 'accessToken.refreshToken') || '';
          const userPayload = _.get(result, 'user');
          Utils.saveToken(token);
          Utils.saveRefreshToken(refreshToken);
          Utils.saveUserData(userPayload);
          await Utils.sleep(2000);
          Utils.redirect(ROUTERS.ADMIN_DASHBOARD);
          dispatch(loginSuccess());
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(loginFail());
      });
  };
};

// const refreshTokenSuccess = () => {
//   return {
//     type: ACTION_TYPES.REFRESH_TOKEN_SUCCESS,
//   };
// };

// const refreshTokenFailure = () => {
//   return {
//     type: ACTION_TYPES.REFRESH_TOKEN_FAILURE,
//   };
// };

// const requestForgotPasswordSuccess = (payload: string) => {
//   return {
//     type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_SUCCESS,
//     payload,
//   };
// };

// const requestForgotPasswordFailure = () => {
//   return {
//     type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE,
//   };
// };

// const requestForgotPassword = (payload: IForgotPasswordStructure) => {
//   return async (dispatch: any) => {
//     dispatch(setAuthActionLoading());
//     await API.forgotPassword(payload)
//       .then(async (response: any) => {
//         const result = await Utils.resolveResponse(response);
//         if (!result) await dispatch(requestForgotPasswordFailure());
//         else {
//           await dispatch(requestForgotPasswordSuccess(payload.email));
//           Utils.redirect(ROUTERS.CHANGE_PASSWORD);
//         }
//       })
//       .catch(async (error) => {
//         await Utils.resolveFailureResponse(error);
//         dispatch(requestForgotPasswordFailure());
//       });
//   };
// };

// const resetPasswordSuccess = () => {
//   return {
//     type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_SUCCESS,
//   };
// };

// const resetPasswordFailure = () => {
//   return {
//     type: ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE,
//   };
// };

// const resetPassword = (payload: IResetPasswordStructure) => {
//   return async (dispatch: any) => {
//     dispatch(setAuthActionLoading());
//     await API.resetPassword(payload)
//       .then(async (response: any) => {
//         if (!response?.data?.status) await dispatch(resetPasswordFailure());
//         else {
//           await dispatch(resetPasswordSuccess());
//           Utils.redirect(ROUTERS.AUTH);
//         }
//       })
//       .catch(async (error) => {
//         await Utils.resolveFailureResponse(error);
//         dispatch(resetPasswordFailure());
//       });
//   };
// };

// const verifySuccess = () => {
//   return {
//     type: ACTION_TYPES.VERIFY_ACCOUNT_SUCCESS,
//   };
// };

// const verifyFailure = () => {
//   return {
//     type: ACTION_TYPES.VERIFY_ACCOUNT_FAILURE,
//   };
// };

// const verifyAccount = (payload: IVerifyAccount) => {
//   return async (dispatch: any) => {
//     dispatch(setAuthActionLoading());
//     await API.verifyAccount(payload)
//       .then(async (response: any) => {
//         if (!response?.data?.status) {
//           await dispatch(verifyFailure());
//           Utils.sleep(5000).then(() =>
//             Utils.redirect(ROUTERS.VERIFY_ACCOUNT_FAIL)
//           );
//         } else {
//           await dispatch(verifySuccess());
//           Utils.sleep(5000).then(() =>
//             Utils.redirect(ROUTERS.VERIFY_ACCOUNT_SUCCESS)
//           );
//         }
//       })
//       .catch(async (error) => {
//         await Utils.resolveFailureResponse(error);
//         dispatch(verifyFailure());
//         Utils.sleep(5000).then(() =>
//           Utils.redirect(ROUTERS.VERIFY_ACCOUNT_FAIL)
//         );
//       });
//   };
// };

export default {
  login,
  logout,
  setLogged,
};
