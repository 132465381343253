import { Box, InputBase } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ForwardRefRenderFunction } from 'react';

interface IDatePickerInputProps {
  labelSx?: object;
  value: any;
  onChange?: any;
  containerSx?: object;
  disabled?: boolean;
}

const DatePickerInput: ForwardRefRenderFunction<
  HTMLInputElement,
  IDatePickerInputProps
> = ({
  value,
  labelSx,
  onChange,
  disabled = false,
  ...otherProps
}) => {
    return (

      <DatePicker
        label="Basic example"
        onChange={onChange}
        value={value}
        disabled={disabled}
        inputFormat='DD/MM/YYYY'
        {...otherProps}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px',
              border: '1px solid gray',
              borderRadius: '5px'
            }}
            component="div"
          >
            <InputBase
              ref={inputRef}
              value={inputProps?.value || ''}
              placeholder="__/__/____"
              style={{
                fontSize: '14px',
                paddingLeft: '5px',
                paddingRight: '5px',
                width: '6rem',
              }}
            />
            {InputProps?.endAdornment}
          </Box>
        )}
      />
    );
  };

export default DatePickerInput;
