import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { tableContainerStyles, tableFooterStyles } from './DataTable.styles';
import _ from 'lodash';
import { TABLES } from '@/Constants';
import { useTranslation } from 'react-i18next';
import { PaginationActions } from '@/Components/Common';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { ICustomersData, ICustommers } from '@/Interfaces/Customers.interface';
import { useEffect, useState } from 'react';
import { CustommersActions } from '@/Actions';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';

const { fetchCustommers } = CustommersActions;

const { CUSTOMERS_TABLE_HEADER } = TABLES;

interface ICustommersProps {
  onRowAction(data: ICustomersData, action: 'detail' | 'delete'): void;
}

const CustomersTable: React.FC<ICustommersProps> = ({ onRowAction }) => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();

  const colSpan = CUSTOMERS_TABLE_HEADER.length + 5;
  const [pagination, setPaginations] = useState<IPaginationFilter>({
    page: 1,
    limit: 10,
  });
  const [dataCustommers, setDataCustommers] = useState<ICustomersData[]>([]);
  const payload: ICustommers = useSelector((state: RootState) =>
    _.get(state.CUSTOMMERS, 'custommers')
  );
  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CUSTOMMERS, 'isFetchLoading')
  );

  useEffect(() => {
    dispatch(fetchCustommers(pagination));
  }, []);

  useEffect(() => {
    if (payload) {
      setPaginations({ ...pagination, page: payload.currentPage });
      setDataCustommers(payload.data);
    }
  }, [payload]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    type?: string
  ) => {
    let page = pagination.page;
    if (type === 'lastPage') page = newPage;
    else {
      if (newPage === 0) page = 1;
      else if (newPage < page) page -= 1;
      else if (newPage >= page) page += 1;
    }
    dispatch(fetchCustommers({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchCustommers({ ...pagination, limit: perPage }));
  };

  const _renderTableHeader = () => {
    return (
      <TableRow>
        <TableCell sx={{ maxWidth: 50 }}>{t('table.no')}</TableCell>
        {_.map(CUSTOMERS_TABLE_HEADER, (item: any) => (
          <TableCell
            key={`head-${item.value}`}
            sx={{ fontWeight: '700', minWidth: 250 }}
          >
            {t(`table.${item?.value}`)}
          </TableCell>
        ))}
        <TableCell sx={{ minWidth: 100, textAlign: 'center' }}>
          {t('table.action')}
        </TableCell>
      </TableRow>
    );
  };

  const _renderTableBody = () => {
    if (_.isEmpty(dataCustommers))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 1}>
            There is no customers to display
          </TableCell>
        </TableRow>
      );
    return _.map(dataCustommers, (item: ICustomersData, index: number) => {
      return (
        <TableRow hover key={index}>
          <TableCell>
            {index + 1 + (payload.currentPage - 1) * pagination.limit}
          </TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>
            <Box component="img" src={item.logo} sx={{ height: 75 }} />
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: 350,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
            }}
          >
            {item.url}
          </TableCell>
          <TableCell>
            <Tooltip title={t('tooltip.edit')}>
              <IconButton onClick={() => onRowAction(item, 'detail')}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tooltip.delete')}>
              <IconButton onClick={() => onRowAction(item, 'delete')}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10]}
            colSpan={colSpan + 5}
            count={payload.totalItems || 0}
            rowsPerPage={payload.itemsPerPage || 0}
            page={payload.currentPage - 1 || 0}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader()}</TableHead>
          <TableBody>
            {isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default CustomersTable;
