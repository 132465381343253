import React from 'react';

import { Images } from '@/Themes';
import { Box, Stack } from '@mui/material';
import DefaultLayout from '@/Components/DefaultLayout';

import {
  CustomersSection,
  ForClientsSection,
  HeroSection,
  WhatIdraPollSection,
  ProductsSection,
  RegisterSection,
  ResultsSection,
  RewardsSection,
  StatisticalSection,
  WhyIdraPollSection,
} from './index';
import ScrollTopButton from '@/Components/Common/ScrollTopButton';

const Home: React.FC = () => {
  const renderMain = () => {
    return (
      <Box
        className="main-container"
        sx={{
          position: 'relative',
          body: {
            position: 'relative',
            fontFamily:
              'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important',
          },
        }}
      >
        <ScrollTopButton />
        <HeroSection />
        <WhatIdraPollSection />
        <RewardsSection />
        <RegisterSection />
        <ResultsSection />
        <Stack
          sx={{
            background: `url('${Images.b2b}')`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <ForClientsSection />
          <ProductsSection />
        </Stack>
        <WhyIdraPollSection />
        <StatisticalSection />
        <CustomersSection />
      </Box>
    );
  };
  return <DefaultLayout content={renderMain()} />;
};

export default Home;
