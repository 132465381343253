import { useCallback, useEffect, useState } from 'react';

import DefaultLayout from '@/Components/DefaultLayout';
import Popup from '@/Components/LayoutPart/Popup';
import { CommonColors, Images } from '@/Themes';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useTranslation } from 'react-i18next';
import { Upload } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { NoDataWereFound } from '@/Components/Common';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { DEFAULT_CONFIRM } from '../SystemUser/SystemUserList';
import { Confirm } from '@/Components/Dialogs';

const { fetchGalleries, deleteGallery } = Upload;

type IOpenKey = 'uploads' | 'details' | 'lightbox';

type IOpenStructure = {
  [key in IOpenKey]: boolean;
};

const UploadGallery = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [isOpen, setIsOpen] = useState<IOpenStructure>({
    uploads: false,
    details: false,
    lightbox: false,
  });
  const [details, setDetails] = useState<any[] | null>(null);
  const [confirmDialog, setConfirmDialog] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  const galleries = useSelector((state: RootState) =>
    _.get(state.UPLOAD, 'galleries')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.UPLOAD, 'isFetchLoading')
  );

  useEffect(() => {
    dispatch(fetchGalleries());
  }, []);

  useEffect(() => {
    setDetails(galleries);
  }, [galleries]);

  const handleOpen = (key: IOpenKey, value: boolean) => {
    setIsOpen((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleDelete = (state: { id?: string }) => {
    const { id } = state;
    dispatch(deleteGallery({ fileIds: [id] }));
    setConfirmDialog(DEFAULT_CONFIRM);
  };

  const handleOpenDialogDelete = (value: any) => {
    const description = `${t('message.areYouSureYouWantToDelelteImage')}`;
    setConfirmDialog({
      isOpen: true,
      message: description,
      state: {
        id: value,
      },
    });
  };

  const onCancel = () => {
    setConfirmDialog(DEFAULT_CONFIRM);
    dispatch(fetchGalleries());
  };

  const truncateFileName = (fileName: string) => {
    const extension = '...pdf';

    if (fileName.length + extension.length <= 20) {
      return fileName + extension;
    } else {
      const truncatedName = fileName.slice(0, 20 - extension.length);
      return truncatedName + extension;
    }
  }

  const _renderGallery = useCallback(() => {
    if (_.isEmpty(details) && _.isEmpty(galleries)) return <NoDataWereFound />;
    return (
      <Box sx={{ minHeight: 700 }}>
        <ImageList
          sx={{ width: '100%', minHeight: 1 }}
          cols={4}
          gap={20}
          variant="standard"
        >
          {_.map(details, (item: any) => {
            const fileExtension = item.filename.split('.').pop().toLowerCase();

            return (
              <ImageListItem
                key={item.id}
                sx={{
                  cursor: 'pointer',
                }}
              >
                {fileExtension === 'pdf' && (
                  <img
                    src={Images.pdfFile}
                    loading="lazy"
                    style={{ width: '100%', objectFit: 'cover' }}
                  />
                )}
                {fileExtension !== 'pdf' && (
                  <img
                    src={item.filepath}
                    loading="lazy"
                    style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                  />
                )}
                <ImageListItemBar
                  sx={{ transition: 'background 1s' }}
                  actionIcon={
                    <Stack flexDirection="row">
                      {fileExtension === 'pdf' && (
                        <Tooltip title={item.filename}>
                          <Typography sx={{ display: 'flex', alignItems: 'center' }} color={'white'}>{truncateFileName(item.filename)}</Typography>
                        </Tooltip>
                      )}
                      <Tooltip title={t('tooltip.deleteThisImage')}>
                        <IconButton
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          onClick={() => {
                            handleOpenDialogDelete(item.id);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    );
  }, [details, galleries]);

  const _renderHeader = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography
            sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
            variant="h4"
          >
            {t('title.upload')}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={'end'}>
          <Button
            variant="contained"
            onClick={() => handleOpen('uploads', !isOpen.uploads)}
          >
            {t('button.upload')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {!isFetchLoading ? _renderGallery() : <Skeleton />}
          <Popup.UploadMedia
            open={isOpen.uploads}
            onClose={() => handleOpen('uploads', !isOpen.uploads)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2}>
        <Grid item xs={12}>
          {_renderHeader()}
          <Divider />
        </Grid>
        <Grid item xs={12} mt={2}>
          {_renderBody()}
        </Grid>
        <Confirm
          confirm={confirmDialog}
          onCancel={() => onCancel()}
          callback={() => handleDelete(confirmDialog.state)}
        />
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default UploadGallery;
