export default {
  AUTH: {
    LOGIN: 'auth/login',
    CHANGE_PASSWORD: 'auth/changePassword',
    FORGOT_PASSWORD: 'auth/forgot-password',
    RESET_PASSWORD: 'auth/reset-password',
    REFRESH_TOKEN: 'auth/refresh',
    VERIFY_USER: 'auth/verify-account',
  },
  SYSTEM_USER: {
    ROOT: 'users',
    CHANGE_PASSWORD: 'users/change-password',
    CHANGE_STATUS: 'users/change-status',
  },
  ABOUT_US: {
    ROOT: 'about-us/',
  },
  REWARDS: {
    ROOT: 'reward/',
  },
  PRODUCT_AND_SERVICES: {
    ROOT: 'products/',
  },
  WHY_IDRA_POLL: {
    ROOT: 'why-idrapoll/',
  },
  UPLOAD: {
    ROOT: 'upload/',
    DELETE: 'upload/delete',
  },
  CUSTOMMERS: {
    ROOT: 'customers/',
  },
  RESULT: {
    ROOT: 'results/',
    PUBLISH: '/ispublished',
    LANDINGPAGE: '/islandingpage',
  },
  STATISTICAL: {
    ROOT: 'statistical/',
  },
  CONTACT: {
    ROOT: 'contact/',
  },
  // Guest portal
  SEND_CONTACT: {
    ROOT: 'contact/',
  },
  INBOX: {
    ROOT: 'unsubscribe/',
  },
};
