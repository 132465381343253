import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { AppBarComponent, Footer } from '../LayoutPart';
import Utils from '@utils';
interface ISectionProps {
  content: JSX.Element;
}

// Declare utils
// const { checkAppMode } = Utils;

const UserLayout: React.FC<ISectionProps> = (props: ISectionProps) => {
  // Constructors
  const { content } = props;
  const [scrollTop, setScrollTop] = React.useState(0);

  useEffect(() => {
    const token = Utils.getSavedToken();
    if (!token) {
      Utils.clearAllSavedData();
    }
  }, []);

  const handleScroll = (event: any) => {
    setScrollTop(event.currentTarget.scrollTop);
    console.log(scrollTop);
  };

  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      sx={{
        overflow: 'hidden',
        position: 'relative',
        background: '#fff',
        display: 'block',
        fontFamily:
          'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      onScroll={handleScroll}
    >
      <Box
        component="nav"
        className="navbar"
        sx={{ position: 'fixed', width: '100%', zIndex: '99' }}
      >
        <AppBarComponent />
      </Box>
      <Grid sx={{ width: 1 }} minHeight={'60vh'} item>
        {content}
      </Grid>
      <Grid item mt="auto">
        <Footer />
      </Grid>
    </Grid>
  );
};

export default UserLayout;
