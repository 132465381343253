import { sendRequest } from '@/Configs';
import { IDataWhyIdraPoll } from '@/Interfaces/WhyIDRApoll.interface';
import { API } from '@constants';

const { WHY_IDRA_POLL } = API;

export const getWhyIdraPoll = async (id: string) => {
  return await sendRequest(`${WHY_IDRA_POLL.ROOT}${id}`, 'GET');
};

export const updateWhyIdraPoll = async (
  id: string,
  payload: IDataWhyIdraPoll
) => {
  return sendRequest(`${WHY_IDRA_POLL.ROOT}${id}`, 'PUT', payload);
};

export const createWhyIdraPoll = async (payload: IDataWhyIdraPoll) => {
  return sendRequest(WHY_IDRA_POLL.ROOT, 'POST', payload);
};
