import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Utils from '@/Utils';
import { ICustommers } from '@/Interfaces/Customers.interface';
import { useTypedDispatch, RootState } from '@/store';
import CustommersAction from '@/Actions/Custommers.action';
import { Box, Stack, Typography } from '@mui/material';
import { containerStyle, sectionTitleStyle } from './home.style';
import { Loading } from '@/Components/Common';

const { fetchCustommers, clearCustommers } = CustommersAction;

const CustomersSection: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const payload: ICustommers = useSelector((state: RootState) =>
    _.get(state.CUSTOMMERS, 'custommers')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.CUSTOMMERS, 'isGetLoading')
  );
  const currentLanguage = Utils.getSavedLanguage();

  React.useEffect(() => {
    dispatch(fetchCustommers(currentLanguage));
    return () => {
      dispatch(clearCustommers());
    };
  }, [currentLanguage]);

  const _renderCustomers = () => {
    const render =
      !_.isEmpty(payload) &&
      payload.data?.map((item, index) => (
        <Box key={index}>
          <Box
            component="img"
            src={item.logo}
            sx={{
              objectFit: 'contain',
              width: { xs: '80%', lg: 'auto' },
              height: 100,
              pointerEvents: 'none',
              textAlign: 'center',
            }}
          />
        </Box>
      ));
    return render;
  };

  const _renderCustomersContent = () => {
    const numberSlide = payload.data?.length > 6 ? 6 : payload.data?.length;
    return (
      <Box className="content" sx={{ mt: '60px', pointerEvents: 'none', '& img': {width: '80%'} }}>
        <Slider
          dots={false}
          infinite={true}
          slidesToShow={numberSlide}
          slidesToScroll={1}
          autoplay={true}
          speed={1000}
          autoplaySpeed={5000}
          cssEase="linear"
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
          ]
          }
        >
          {_renderCustomers()}
        </Slider>
      </Box>
    );
  }


  const _renderCustomersSection = () => (
    <Box component="section" className="customers">
      <Stack className="customers-container" sx={containerStyle}>
        <Box className="title">
          <Typography sx={sectionTitleStyle}>{t('title.trustedBy')}</Typography>
        </Box>
        {isLoading ? <Loading /> : _renderCustomersContent()}
      </Stack>
    </Box>
  );
  const renderMain = () => <>{_renderCustomersSection()}</>;

  return renderMain();
};

export default CustomersSection;
