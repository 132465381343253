import { SxProps, Theme } from '@mui/system';

export const heroTitleStyle: SxProps<Theme> = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  p: '15px',
  mx: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '80%',
  color: 'white',
  textAlign: 'center',
  fontSize: '35px',
  fontFamily: '"Poppins", sans-serif',
};
export const resultItems: SxProps<Theme> = {
  position: 'relative',
  color: 'white',
  width: { xs: '300px', sm: '400px', md: '100%' },
  height: { xs: '300px', sm: '400px', md: '100%' },
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: '5px',
  mx: 'auto',
  '.MuiGrid-item': {
    pl: { xs: 0, md: '16px' },
  },
  '.imagesItem:hover': {
    transform: 'scale(1.2)',
  },
};

export const imageStyle: SxProps<Theme> = {
  width: '100%',
  height: { xs: '100%', md: '200px' },
  transition: 'all 1s',
  objectFit: 'cover',
};
