interface ActionTypes {
  [key: string]: string;
}

const generateSyncActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== '') {
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
    }
  });
  return map;
};

const generateLoadingActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== '') {
      map[`SET_${name}_FETCH_LOADING`] = `SET_${name}_FETCH_LOADING`;
      map[`SET_${name}_GET_LOADING`] = `SET_${name}_GET_LOADING`;
      map[`SET_${name}_ACTION_LOADING`] = `SET_${name}_ACTION_LOADING`;
    }
  });
  return map;
};

const _loadingActions: ActionTypes = generateLoadingActions([
  'AUTH',
  'SYSTEM_USER',
  'ABOUT_US',
  'REWARDS',
  'PRODUCT_AND_SERVICES',
  'WHY_IDRA_POLL',
  'UPLOAD',
  'CUSTOMMERS',
  'RESULTS',
  'STATISTICAL',
  'CONTACT',
]);

const _asyncActions: ActionTypes = generateSyncActions([
  'LOGIN',
  'REFRESH_TOKEN',
  'FETCH_SYSTEM_USER',
  'GET_SYSTEM_USER_BY_ID',
  'UPDATE_SYSTEM_USER',
  'DELETE_SYSTEM_USER',
  'CREATE_SYSTEM_USER',
  'UPDATE_ABOUT_US',
  'GET_ABOUT_US',
  'GET_REWARDS',
  'UPDATE_REWARDS',
  'GET_PRODUCT_AND_SERVICES',
  'UPDATE_PRODUCT_AND_SERVICES',
  'CREATE_PRODUCT_AND_SERVICES',
  'GET_WHY_IDRA_POLL',
  'CREATE_WHY_IDRA_POLL',
  'UPDATE_WHY_IDRA_POLL',
  'FETCH_UPLOAD',
  'GET_UPLOAD_BY_ID',
  'CREATE_UPLOAD',
  'DELETE_UPLOAD',
  'FETCH_CUSTOMMERS',
  'GET_CUSTOMMERS_BY_ID',
  'CREATE_CUSTOMMERS',
  'UPDATE_CUSTOMMERS',
  'DELETE_CUSTOMMERS',
  'FETCH_RESULTS',
  'GET_RESULTS_BY_ID',
  'CREATE_RESULTS',
  'UPDATE_RESULTS',
  'LANDINGPAGE_RESULTS',
  'PUBLISHT_RESULTS',
  'DELETE_RESULTS',
  'CREATE_STATISTICAL',
  'UPDATE_STATISTICAL',
  'FETCH_STATISTICAL',
  'GET_CONTACT_BY_ID',
  'FETCH_CONTACT',
  'CLEAR_CONTACT',
  'SEND_CONTACT',
  'CLEAR_UPLOAD',
  'GET_INBOX_BY_ID',
  'FETCH_INBOX',
  'CLEAR_INBOX',
  'CHANGE_STATUS_USER',
]);

const _singleActions: ActionTypes = {
  // Locale actions
  SET_LOCALE: 'SET_LOCALE',
  // Widget actions
  SET_NOTIFICATION_MESSAGE: 'SET_NOTIFICATION_MESSAGE',
  SET_START_LOADING: 'SET_START_LOADING',
  SET_ALERT_MESSAGE: 'SET_ALERT_MESSAGE',
  // Authentication actions
  LOGOUT: 'LOGOUT',
  //SystemUser
  SET_SYSTEM_USER_PAGINATION: 'SET_SYSTEM_USER_PAGINATION',
  SET_SYSTEM_USER_META: 'SET_SYSTEM_USER_META',
  CLEAR_SYSTEM_USER: 'CLEAR_SYSTEM_USER',

  //About us
  CLEAR_ABOUT_US: 'CLEAR_ABOUT_US',
  //Customers
  CLEAR_CUSTOMMERS: 'CLEAR_CUSTOMMERS',
  //Result
  CLEAR_RESULTS: 'CLEAR_RESULTS',
  SET_RESULTS_FETCH_LOADING: 'SET_RESULTS_FETCH_LOADING',
  //Why idra poll
  CLEAR_WHY_IDRA_POLL: 'CLEAR_WHY_IDRA_POLL',
  //Statistical
  CLEAR_STATISTICAL: 'CLEAR_STATISTICAL',
  // Products and service
  CLEAR_PRODUCT_AND_SERVICES: 'CLEAR_PRODUCT_AND_SERVICES',
  // Rewards
  CLEAR_REWARDS: 'CLEAR_REWARDS',

  //Guest portal
  SET_INBOX_FETCH_LOADING: 'SET_INBOX_FETCH_LOADING',
  SET_INBOX_ACTION_LOADING: 'SET_INBOX_ACTION_LOADING',
  UNSUBSCRIBE_SUCCESS: 'UNSUBSCRIBE_SUCCESS',
  UNSUBSCRIBE_FAILURE: 'UNSUBSCRIBE_FAILURE',
  SET_INBOX_PAGINATION: 'SET_INBOX_PAGINATION',
  SET_INBOX_META: 'SET_INBOX_META',
  //Contact
  SET_CONTACT_ACTION_LOADING: 'SET_CONTACT_ACTION_LOADING',
  SET_CONTACT_META: 'SET_CONTACT_META',
  SET_CONTACT_PAGINATION: 'SET_CONTACT_PAGINATION',
};

const ACTION_TYPES = {
  ..._asyncActions,
  ..._singleActions,
  ..._loadingActions,
};

export default ACTION_TYPES;
