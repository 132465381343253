import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';
import { ICustomersData } from '@/Interfaces/Customers.interface';

// SINGLE ACTIONS

const clearCustommers = () => {
  return {
    type: ACTION_TYPES.CLEAR_CUSTOMMERS,
  };
};

const setCustommersActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_CUSTOMMERS_ACTION_LOADING,
    payload,
  };
};
const getCustommersActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_CUSTOMMERS_GET_LOADING,
    payload,
  };
};

const fetchCustommersActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_CUSTOMMERS_FETCH_LOADING,
    payload,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_CUSTOMMERS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_CUSTOMMERS_FAILURE,
  };
};

const fetchCustommers = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(fetchCustommersActionLoading(true));
    await API.fetchCustommers(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          dispatch(fetchSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_CUSTOMMERS_BY_ID_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_CUSTOMMERS_BY_ID_FAILURE,
  };
};

const getCustommers = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getCustommersActionLoading(true));
    await API.getCustommers(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else {
          dispatch(getSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_CUSTOMMERS_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_CUSTOMMERS_FAILURE,
  };
};

const createCustommers = (
  payload: ICustomersData,
  PaginationMeta: IPaginationFilter
) => {
  return async (dispatch: any) => {
    dispatch(setCustommersActionLoading(true));
    await API.createCustommers(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else dispatch(createSuccess());
        await dispatch(fetchCustommers(PaginationMeta));
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = () => {
  return {
    type: ACTION_TYPES.UPDATE_CUSTOMMERS_SUCCESS,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_CUSTOMMERS_FAILURE,
  };
};

const updateCustommers = (
  filter: IPaginationFilter,
  payload: ICustomersData
) => {
  return async (dispatch: any) => {
    dispatch(setCustommersActionLoading(true));
    await API.updateCustommers(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          await dispatch(fetchCustommers(filter));
          dispatch(updateSuccess());
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_CUSTOMMERS_SUCCESS,
  };
};

const deleteFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_CUSTOMMERS_FAILURE,
  };
};

const deleteCustommers = (id: any, payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(setCustommersActionLoading(true));
    await API.deleteCustommers(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(deleteFail());
        else dispatch(deleteSuccess());
        await dispatch(fetchCustommers(payload));
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deleteFail());
      });
  };
};

export default {
  fetchCustommers,
  getCustommers,
  createCustommers,
  updateCustommers,
  deleteCustommers,
  clearCustommers,
};
