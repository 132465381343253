const USER_TABLE_HEADER = [
  {
    label: 'Full name',
    value: 'userData.fullName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Municipality',
    value: 'municipality',
  },
  {
    label: 'Role',
    value: 'role.name',
    align: 'center',
  },
  {
    label: 'Status',
    value: 'status',
    align: 'center',
  },
];

const GEOJSON_TABLE_HEADER = [
  {
    label: 'File name',
    value: 'file.nameOriginal',
  },
  {
    label: 'File Type',
    value: 'extraData.type',
  },
  {
    label: 'Group',
    value: 'groupId.name',
  },
  {
    label: 'Upload at',
    value: 'createdAt',
  },
  {
    label: 'Status',
    value: 'status',
    align: 'center',
  },
];

const GROUP_TABLE_HEADER = [
  {
    label: 'Group name',
    value: 'name',
  },
  {
    label: 'Status',
    value: 'status',
    align: 'center',
  },
];

const SYSTEM_USER_TABLE_HEADER = [
  { label: 'Email', value: 'email' },
  { label: 'Name', value: 'name' },
];

const CUSTOMERS_TABLE_HEADER = [
  { label: 'Name', value: 'name' },
  { label: 'Logo', value: 'logo' },
  { label: 'URL', value: 'url' },
];

const INBOX_TABLE_HEADER = [
  { label: 'Email', value: 'email' },
  { label: 'Reason', value: 'reason' },
  { label: 'Country', value: 'country' },
];

const CONTACT_TABLE_HEADER = [
  { label: 'Email', value: 'email' },
  { label: 'Content', value: 'content' },
];

const RESULTS_TABLE_HEADER = [
  { label: 'Title', value: 'title' },
  { label: 'Date', value: 'date' },
];

export default {
  USER_TABLE_HEADER,
  GEOJSON_TABLE_HEADER,
  GROUP_TABLE_HEADER,
  SYSTEM_USER_TABLE_HEADER,
  CUSTOMERS_TABLE_HEADER,
  INBOX_TABLE_HEADER,
  CONTACT_TABLE_HEADER,
  RESULTS_TABLE_HEADER,
};
