import _ from 'lodash';
import { pushNotification, pushAlert } from './Widget.utils';

export type ResponseValidation = {
  data: {
    status: boolean;
    message: string;
    payload: any;
  };
  isHideAlert: boolean;
};

// RESOLVE REQUEST RESPONSE
const resolveResponse = (response: ResponseValidation, hideAlert?: boolean) => {
  return new Promise((resolve) => {
    const { status, message, payload } = response.data;
    if (status) {
      if (!hideAlert) {
        setTimeout(() => {
          pushNotification({
            type: 'success',
            message,
          });
        }, 1500);
      }
      resolve(payload || true);
    } else {
      setTimeout(() => {
        pushNotification({
          type: 'warning',
          message,
        });
      }, 1500);
      resolve(false);
    }
  });
};

const resolveFailureResponse = (response: {
  type: string;
  message: string | string[];
}) => {
  return new Promise((resolve) => {
    const { message } = response;
    if (message) {
      setTimeout(() => {
        pushAlert({
          type: 'warning',
          message: _.isString(message) ? message : message.join(','),
        });
      }, 1500);
    } else {
      setTimeout(() => {
        pushAlert({
          type: 'error',
          message: 'The server is having problems, please try again later!',
        });
      }, 1500);
    }
    resolve(true);
  });
};

export { resolveResponse, resolveFailureResponse };
