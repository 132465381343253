import { IDataInbox } from '@/Interfaces/Inbox.interface';
import { CommonColors } from '@/Themes';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IPropsSystemUser {
  open: boolean;
  onClose(value?: string): void;
  data?: IDataInbox;
}

const InboxDetail: React.FC<IPropsSystemUser> = ({ open, onClose, data }) => {
  const { t } = useTranslation('translation');

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      PaperProps={{
        style: {
          maxWidth: '50%',
        },
      }}
    >
      <DialogTitle>
        <Typography
          variant="h5"
          sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
        >
          {t('title.inboxDetail')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: '1px solid #0000001f', p: 4 }}>
          <Box display={'flex'} mb={2} mt={1}>
            <InputLabel sx={{ width: '14vh', fontWeight: '500' }}>
              {t('label.email')}:{' '}
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              value={data?.email}
              label={t('label.email')}
              disabled
            />
          </Box>
          <Box display={'flex'}>
            <InputLabel sx={{ width: '14vh', fontWeight: '500' }}>
              {t('label.reason')}:{' '}
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              multiline
              rows={6}
              value={data?.content}
              label={t('label.reason')}
              disabled
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 1 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="fuzzyWuzzyBrown"
            onClick={() => onClose()}
          >
            {t('button.back')}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InboxDetail;
