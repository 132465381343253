import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Utils from '@/Utils';
import DefaultLayout from '@/Components/DefaultLayout';
import { RootState, useTypedDispatch } from '@/store';
import { ROUTERS } from '@/Constants';
import ResultsAction from '@/Actions/Results.action';
import { IPaginationsResults, IResults } from '@/Interfaces/Results.interface';

import {
  Box,
  TextField,
  Typography,
  Stack,
  IconButton,
  Grid,
  Pagination,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { containerStyle } from '../Home/home.style';
import { heroTitleStyle, imageStyle, resultItems } from './results.style';
import allResultsImage from '@/Assets/all_resultss.jpg';
import { Loading } from '@/Components/Common';

const { fetchResultsHomepage, clearResults } = ResultsAction;

const DEFAULT_PAGINATION: IPaginationsResults = {
  page: 1,
  limit: 9,
};

const DEFAULT_RESULT = {
  currentPage: 1,
  data: [],
  itemsPerPage: 1,
  totalItems: 1,
  totalPage: 1,
};

let LIST_SECONDARY_RESULT: any[] = [];

const AllResults = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const payload: IResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'results')
  );
  const isLoading: IResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'isFetchLoading')
  );

  const [searchResultList, setSearchResultList] = React.useState<IResults>();
  const languageCode = Utils.getSavedLanguage();
  const [resolveSearchResults, setResolveSearchResults] = React.useState<any>(
    []
  );
  const [resultListPerPage, setResultListPerPage] =
    React.useState<IResults>(DEFAULT_RESULT);
  const [searched, setSearched] = React.useState('');
  const [pagination, setPaginations] =
    React.useState<IPaginationsResults>(DEFAULT_PAGINATION);
  const [isRenderSearchList, setIsRenderSearchList] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(
      fetchResultsHomepage({
        ...DEFAULT_PAGINATION,
        isPublished: true,
        languageCode,
      })
    );
  }, [languageCode]);

  React.useEffect(() => {
    if (!_.isEmpty(payload)) {
      if (payload.data.length > 9) setSearchResultList(payload);
      else setResultListPerPage(payload);
    }
  }, [payload]);

  React.useEffect(() => {
    //resolve LIST_SECONDARY_RESULT
    if (!_.isEmpty(resultListPerPage)) {
      setPaginations({ ...pagination, page: resultListPerPage.currentPage });
      if (resultListPerPage.currentPage === 1) {
        for (let i = 3; i < resultListPerPage.data.length; i++) {
          LIST_SECONDARY_RESULT.push(resultListPerPage.data[i]);
        }
      } else {
        resultListPerPage.data.map((items) => {
          LIST_SECONDARY_RESULT.push(items);
        });
      }
    }
  }, [resultListPerPage]);

  React.useEffect(() => {
    LIST_SECONDARY_RESULT = [];
    setResultListPerPage(DEFAULT_RESULT);
    return () => {
      LIST_SECONDARY_RESULT = [];
      setResultListPerPage(DEFAULT_RESULT);
      dispatch(clearResults());
    };
  }, []);

  //resolve render result list when searching
  const searchResultsList = (searchedVal: any) => {
    const list = searchResultList ? searchResultList.data : payload.data;
    const resultsFilter = _.filter(list, (results: any) => {
      const label = _.get(results, 'title');
      if (!label) return;
      const lowerLabel = _.toLower(searchedVal.target.value)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      if (lowerLabel) setIsRenderSearchList(true);
      else if (lowerLabel === '') setIsRenderSearchList(false);

      const lowerResult = _.toLower(label)
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const checkLike = lowerResult.indexOf(lowerLabel);
      return checkLike > -1;
    });
    setSearched(searchedVal.target.value.toLowerCase());
    setResolveSearchResults(resultsFilter);
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    let page = payload.currentPage;
    if (newPage !== page) {
      LIST_SECONDARY_RESULT = [];
      dispatch(clearResults());
      if (newPage === 0) page = 1;
      else if (newPage < page) page -= 1;
      else if (newPage >= page) page += 1;
      dispatch(
        fetchResultsHomepage({
          ...pagination,
          page,
          isPublished: true,
          languageCode,
        })
      );
    }
  };

  // render when screen width > 900
  const _renderLatestList = () => (
    <Grid
      className="mainItems"
      container
      spacing={2}
      mb="2%"
      sx={{
        display: { xs: 'none', md: 'flex' },
      }}
    >
      <Grid
        className="latestItem"
        item
        xs={12}
        md={payload.data.length === 1 ? 12 : 8}
      >
        <Box
          sx={resultItems}
          onClick={() =>
            Utils.redirect(`${ROUTERS.RESULTS_DETAIL}?id=${payload.data[0].id}`)
          }
        >
          <Box
            className="imagesItem"
            component="img"
            src={!_.isEmpty(payload.data) ? payload?.data[0].imageUrl : ''}
            sx={{
              maxHeight: '400px',
              width: '100%',
              transition: 'all 1s',
              objectFit: 'cover',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              width: '100%',
              p: '10px 13px',
              zIndex: '2',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '17px', md: '24px' },
                fontFamily: '"Poppins", sans-serif',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
              }}
            >
              {!_.isEmpty(payload.data) && payload.data[0].title}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {payload.data.length > 1 && (
        <Grid
          className="secondNewItem"
          container
          spacing={1}
          item
          xs={12}
          md={4}
        >
          <Grid
            className="secondNewItem_1"
            item
            xs={12}
            sx={{
              pt: { xs: '16px', md: '8px' },
              pl: { md: '8px !important' },
            }}
          >
            <Box
              sx={resultItems}
              onClick={() =>
                Utils.redirect(
                  `${ROUTERS.RESULTS_DETAIL}?id=${payload.data[1].id}`
                )
              }
            >
              <Box
                className="imagesItem"
                component="img"
                src={payload.data[1].imageUrl}
                sx={imageStyle}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  width: '100%',
                  p: '10px 13px',
                  zIndex: '2',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: '17px',
                    fontFamily: '"Poppins", sans-serif',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                  }}
                >
                  {payload.data[1].title}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {payload.data.length > 2 && (
            <Grid
              className="secondNewItem_2"
              item
              xs={12}
              sx={{
                pt: { xs: '16px', md: '8px' },
                pl: { md: '8px !important' },
              }}
            >
              <Box
                sx={resultItems}
                onClick={() =>
                  Utils.redirect(
                    `${ROUTERS.RESULTS_DETAIL}?id=${payload.data[2].id}`
                  )
                }
              >
                <Box
                  className="imagesItem"
                  component="img"
                  src={payload?.data[2]?.imageUrl}
                  sx={imageStyle}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    width: '100%',
                    p: '10px 13px',
                    zIndex: '2',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '17px',
                      fontFamily: '"Poppins", sans-serif',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                    }}
                  >
                    {payload.data[2].title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );

  //render when screen width > 900
  const _renderSecondaryList = () => (
    <Grid
      className="secondaryList"
      container
      spacing={2}
      sx={{
        '.secondaryItems': { pl: { xs: 0, md: '16px' } },
        display: { xs: 'none', md: 'flex' },
      }}
    >
      {LIST_SECONDARY_RESULT.map((items: any, index: number) => (
        <Grid
          className="secondaryItems"
          key={index}
          item
          xs={12}
          md={4}
          sx={{
            '.css-mhc70k-MuiGrid-root>.MuiGrid-item': {
              pl: 0,
            },
          }}
        >
          <Box
            sx={{
              ...resultItems,
              '.css-mhc70k-MuiGrid-root>.MuiGrid-item': {
                pl: 0,
              },
            }}
            onClick={() =>
              Utils.redirect(`${ROUTERS.RESULTS_DETAIL}?id=${items.id}`)
            }
          >
            <Box
              className="imagesItem"
              component="img"
              src={items.imageUrl}
              sx={{
                ...imageStyle,
                height: { xs: '100%', md: '250px' },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                width: '100%',
                p: '10px 13px',
                zIndex: '2',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: '17px',
                  fontFamily: '"Poppins", sans-serif',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  '.MuiTypography-root': {
                    mb: 0,
                  },
                }}
              >
                {items.title}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
  // render when screen width < 900
  const _renderResultList = () => (
    <Grid
      className="resultList"
      container
      spacing={2}
      sx={{
        '.resultItems': { pl: { xs: 0, md: '16px' } },
        display: { xs: 'block', md: 'none' },
      }}
    >
      {!_.isEmpty(resultListPerPage.data) &&
        resultListPerPage.data.map((items: any, index: number) => (
          <Grid
            className="resultItems"
            key={index}
            item
            xs={12}
            sx={{
              '.css-mhc70k-MuiGrid-root>.MuiGrid-item': {
                pl: 0,
              },
            }}
          >
            <Box
              sx={{
                ...resultItems,
                '.css-mhc70k-MuiGrid-root>.MuiGrid-item': {
                  pl: 0,
                },
              }}
              onClick={() =>
                Utils.redirect(`${ROUTERS.RESULTS_DETAIL}?id=${items.id}`)
              }
            >
              <Box
                className="imagesItem"
                component="img"
                src={items.imageUrl}
                sx={{
                  ...imageStyle,
                  height: { xs: '100%', md: '250px' },
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  width: '100%',
                  p: '10px 13px',
                  zIndex: '2',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: '17px',
                    fontFamily: '"Poppins", sans-serif',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    '.MuiTypography-root': {
                      mb: 0,
                    },
                  }}
                >
                  {items.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  );

  // render when searching
  const _renderSearchList = () => {
    return (
      <Grid
        className="secondaryList"
        container
        spacing={2}
        sx={{ '.secondaryItems': { pl: { xs: 0, md: '16px' } } }}
      >
        {resolveSearchResults.map((items: any, index: number) => (
          <Grid className="secondaryItems" key={index} item xs={12} md={4}>
            <Box
              sx={resultItems}
              onClick={() =>
                Utils.redirect(`${ROUTERS.RESULTS_DETAIL}?id=${items.id}`)
              }
            >
              <Box
                className="imagesItem"
                component="img"
                src={items.imageUrl}
                sx={{
                  ...imageStyle,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  width: '100%',
                  p: '10px 13px',
                  zIndex: '2',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: '17px',
                    fontFamily: '"Poppins", sans-serif',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    '.MuiTypography-root': {
                      mb: 0,
                    },
                  }}
                >
                  {items.title}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderMain = () => (
    <Box className="container" sx={{ mt: { md: '5%' } }}>
      <Box
        component="section"
        className="hero"
        sx={{
          background: `url(${allResultsImage}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: '35% 70%',
          position: 'relative',
          width: '100%',
          height: '45vh',
        }}
      >
        <Box sx={heroTitleStyle}>
          <Typography
            variant="h1"
            sx={{
              color: 'white',
              textAlign: 'center',
              fontSize: '35px',
              fontFamily: '"Poppins", sans-serif',
              p: '1%',
            }}
          >
            {t('title.results')}
          </Typography>
        </Box>
      </Box>
      <Box
        component="section"
        className="content"
        sx={{
          ...containerStyle,
          pt: 0,
          mt: '40px',
          // maxWidth: '80vw',
        }}
      >
        <Stack
          className="search"
          direction="row"
          sx={{
            textAlign: 'center',
            mb: '2%',
            justifyContent: 'center',
          }}
        >
          <TextField
            size="small"
            value={searched}
            placeholder={t('placeholder.search')}
            sx={{
              border: '2px solid rgb(92, 125, 149)',
              '.MuiInputBase-colorPrimary': {
                borderRadius: 0,
              },
            }}
            onChange={(searchVal) => {
              searchResultsList(searchVal);
            }}
          />
          <Box
            sx={{
              width: '40px',
              background: 'rgb(92, 125, 149)',
              display: 'flex',
            }}
          >
            <IconButton aria-label="search">
              <SearchIcon
                sx={{
                  fontSize: '17px',
                  color: 'white',
                  height: '100%',
                }}
              />
            </IconButton>
          </Box>
        </Stack>
        <>
          {isLoading ? (
            <Loading />
          ) : _.isEmpty(payload.data) ? (
            'There is no result to display'
          ) : (
            <Grid
              className="allItems"
              container
              sx={{
                display: 'flex',
                mt: '5%',
                maxWidth: '70vw',
                mx: 'auto',
                '.MuiGrid-item': {
                  pl: { xs: 0, md: '16px' },
                },
              }}
            >
              {!isRenderSearchList &&
                payload.currentPage === 1 &&
                _renderLatestList()}
              {!isRenderSearchList && _renderSecondaryList()}
              {!isRenderSearchList && _renderResultList()}
              {isRenderSearchList && _renderSearchList()}
            </Grid>
          )}
        </>

        {payload?.currentPage && !isRenderSearchList && (
          <Box className="pageSelector">
            <Pagination
              page={payload?.currentPage}
              count={resultListPerPage.totalPage}
              onChange={handleChangePage}
              sx={{ display: 'flex', justifyContent: 'center', mt: '50px' }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );

  return <DefaultLayout content={renderMain()} />;
};

export default AllResults;
