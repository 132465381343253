import * as React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LoginUserPopup, RegisterUserPopup } from '@/Components/Dialogs';
import { getSavedLanguage } from '@/Utils/Cookie.utils';
import { Images } from '@/Themes';

const RegisterSection: React.FC = () => {
  const { t } = useTranslation();

  const [isShowRegisterPopup, setIsShowRegisterPopup] =
    React.useState<boolean>(false);
  const [isShowLoginPopup, setIsShowLoginPopup] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const currentLanguage = getSavedLanguage();
    i18next.changeLanguage(currentLanguage);
  }, []);

  const _renderRegisterPopup = () => (
    <RegisterUserPopup
      open={isShowRegisterPopup}
      onClose={() => setIsShowRegisterPopup(false)}
      openLoginForm={() => {
        setIsShowRegisterPopup(false);
        setIsShowLoginPopup(true);
      }}
    />
  );

  const _renderLoginPopup = () => (
    <LoginUserPopup
      open={isShowLoginPopup}
      onClose={() => {
        setIsShowLoginPopup(false);
      }}
      openRegisterForm={() => {
        setIsShowLoginPopup(false);
        setIsShowRegisterPopup(true);
      }}
    />
  );

  const _renderRegisterSection = () => (
    <Box
      className="registerSection"
      component="section"
      sx={{
        // backgroundImage: `url(${'https://idrapoll.com/static/media/register.07aa860f.jpg'})`,
        background:
          `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${Images.register}') fixed center center`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        '@media (max-width: 1024px)': {
          backgroundAttachment: 'scroll',
        },
      }}
    >
      <Box
        className="container"
        sx={{
          p: '80px 0',
          margin: 'auto',
          textAlign: 'center',
          // maxWidth: {
          //   xs: '540px',
          //   sm: '768px',
          //   md: '960px',
          //   lg: '1140px',
          //   xl: '1320px',
          // },
          '@media (min-width: 1400px)': {
            maxWidth: '1320px',
          },
          '@media (min-width: 1200px)': {
            maxWidth: '1140px',
          },
          '@media (min-width: 992px)': {
            maxWidth: '960px',
          },
          '@media (min-width: 768px)': {
            maxWidth: '720px',
          },
          '@media (min-width: 576px': {
            maxWidth: '540px',
          },
        }}
      >
        <Stack sx={{ direction: 'coloumn' }}>
          <Typography
            variant="h3"
            sx={{
              color: '#fff',
              fontSize: '23px',
              fontWeight: 700,
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            {t('title.earnRewardsForYourOpinion')}
          </Typography>
          <Box>
            <Button
              sx={{
                fontFamily: '"Poppins", sans-serif',
                border: '2px solid #fff',
                borderRadius: '50px',
                color: '#fff',
                fontSize: '12px',
                p: '8px 30px',
                mt: '10px',
                transition: '0.5s',
                '&:hover': {
                  backgroundColor: '#F26F26',
                  border: '2px solid #F26F26',
                },
              }}
              onClick={() => setIsShowRegisterPopup(true)}
            >
              {t('button.registerNow')}
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );

  const renderMain = () => (
    <>
      {_renderRegisterSection()}
      {_renderRegisterPopup()}
      {_renderLoginPopup()}
    </>
  );

  return renderMain();
};

export default RegisterSection;
