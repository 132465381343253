import React, { useEffect } from 'react';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';

import { LIST } from '@/Constants';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { RootState, useTypedDispatch } from '@/store';
import ProductsAndServicesAction from '@/Actions/ProductsAndServices.action';
import { IDataProductAndServices } from '@/Interfaces/ProductAndServices.interface';
import { useSelector } from 'react-redux';
import Media from '@/Components/Dialogs/Media';

const {
  getProductAndService,
  createProductAndService,
  updateProductAndService,
} = ProductsAndServicesAction;

const OPTIONS_RESEARCH = [
  { label: 'Market Research', value: 'market research' },
  { label: 'Opinion Research', value: 'opinion research' },
];

const DEFAULT_DATA_PRODUCTS = {
  content: [{ title: '', image: '', description: '' }],
};

const ProductsAndServices = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');

  const [currentLanguage, setCurrentLanguage] = React.useState<string>('en');
  const [dataResearch, setDataResearch] =
    React.useState<string>('opinion research');
  const [dataProducts, setDataProducts] =
    React.useState<IDataProductAndServices>(DEFAULT_DATA_PRODUCTS);
  const [isOpenMedia, setIsOpenMedia] = React.useState<{
    isOpen: boolean;
    type: string;
    key: any;
  }>({ isOpen: false, type: '', key: '' });

  const payload: IDataProductAndServices = useSelector((state: RootState) =>
    _.get(state.PRODUCT_AND_SERVICES, 'productAndServices')
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.PRODUCT_AND_SERVICES, 'isActionLoading')
  );

  useEffect(() => {
    if (currentLanguage && dataResearch)
      dispatch(
        getProductAndService(currentLanguage, {
          research: dataResearch,
        })
      );
  }, [currentLanguage, dataResearch]);

  useEffect(() => {
    if (!_.isEmpty(payload)) setDataProducts(payload);
    else setDataProducts(DEFAULT_DATA_PRODUCTS);
  }, [payload]);

  const handleOnChange = (contentKey: string, value: string, key: number) => {
    const newForm = _.map(dataProducts.content, (item, index) => {
      if (index === key) {
        return {
          ...item,
          [contentKey]: value,
        };
      }
      return item;
    });
    setDataProducts({ ...dataProducts, content: newForm });
  };

  const handleAddMore = () => {
    const newAddmore = [
      ...dataProducts.content,
      { title: '', description: '', image: '' },
    ];
    setDataProducts({ ...dataProducts, content: newAddmore });
  };

  const handRemove = (key: number) => {
    const newForm = _.filter(
      dataProducts.content,
      (_item, index) => index !== key
    );
    setDataProducts({ ...dataProducts, content: newForm });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (payload && payload.id)
      dispatch(
        updateProductAndService(payload.id, {
          ...dataProducts,
          research: dataResearch,
          languageCode: currentLanguage,
        })
      );
    else
      dispatch(
        createProductAndService({
          ...dataProducts,
          research: dataResearch,
          languageCode: currentLanguage,
        })
      );
  };

  const _renderHeader = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
          variant="h4"
        >
          {t('title.productsAndServices')}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  const _renderContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {dataProducts.content &&
            _.map(dataProducts.content, (itemContent, index) => {
              return (
                <Grid
                  container
                  key={`row${dataProducts.content.length}-${index}`}
                  spacing={3}
                >
                  <Grid item xs={12} mt={2}>
                    <Typography>
                      {t('title.content')} {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} ml={2}>
                    <Grid
                      container
                      sx={{ border: '1px solid', p: 2, position: 'relative' }}
                      spacing={2}
                    >
                      <Button
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                        color="error"
                        onClick={() => handRemove(index)}
                        disabled={dataProducts.content.length <= 1}
                      >
                        <CloseIcon />
                      </Button>
                      <Grid item xs={12} display={'flex'}>
                        <TextField
                          size="small"
                          label={t('label.title')}
                          fullWidth
                          value={itemContent.title}
                          onChange={(e: any) =>
                            handleOnChange('title', e.target.value, index)
                          }
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          value={itemContent.description}
                          label={t('label.description')}
                          multiline
                          rows={4}
                          fullWidth
                          onChange={(e: any) =>
                            handleOnChange('description', e.target.value, index)
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          value={itemContent.image}
                          label={t('label.linkImage')}
                          fullWidth
                          multiline
                          rows={4}
                          onChange={(e: any) =>
                            handleOnChange('image', e.target.value, index)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setIsOpenMedia({
                                      isOpen: true,
                                      type: 'image',
                                      key: index,
                                    });
                                  }}
                                >
                                  <CloudUploadIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
        <Grid item textAlign={'center'} xs={12}>
          <Button onClick={() => handleAddMore()}>
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2} direction={'row'}>
        <Grid item xs={3} display={'flex'}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 80,
            }}
          >
            {t('label.language')}
          </InputLabel>
          <Select
            value={currentLanguage}
            size="small"
            onChange={(e: any) => {
              setCurrentLanguage(e.target.value);
              setDataProducts({
                ...dataProducts,
                languageCode: e.target.value,
              });
            }}
            sx={{
              minWidth: '80px',
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
              height: '45px',
            }}
          >
            {LIST.DROPDOWN_LIST.OPTIONS_LANGUAGE.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={3} display={'flex'}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 80,
            }}
          >
            {t('label.research')}
          </InputLabel>
          <Select
            fullWidth
            size="small"
            value={dataResearch}
            sx={{
              minWidth: 110,
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
              height: '45px',
            }}
            onChange={(e: any) => {
              setDataResearch(e.target.value);
            }}
          >
            {OPTIONS_RESEARCH.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12}>
          {_renderContent()}
        </Grid>
      </Grid>
    );
  };

  const _renderFooterProducts = () => {
    return (
      <Grid item mt={2} textAlign={'end'} xs={12}>
        <LoadingButton
          variant="contained"
          color="diSerria"
          sx={{ mr: 2 }}
          loading={isActionLoading}
          onClick={() => setDataProducts(DEFAULT_DATA_PRODUCTS)}
        >
          {t('button.reset')}
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          color="oceanGreen"
          loading={isActionLoading}
        >
          {t('button.save')}
        </LoadingButton>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container>
        {_renderHeader()}
        <Grid item xs={12} mt={2} component="form" onSubmit={handleSubmit}>
          {_renderBody()}
          {_renderFooterProducts()}
        </Grid>
        {isOpenMedia.isOpen && (
          <Media
            onOpen={{
              status: isOpenMedia.isOpen,
              type: isOpenMedia.type,
              key: isOpenMedia.key,
            }}
            onClose={() => {
              setIsOpenMedia({ isOpen: false, type: '', key: '' });
            }}
            callback={(file: any, type: string, key: any) => {
              if (type) {
                handleOnChange(type, file, key);
                setIsOpenMedia({ isOpen: false, type: '', key: '' });
              }
            }}
          />
        )}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default ProductsAndServices;
