import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from '@/Components/DefaultLayout';
import { Box, Typography } from '@mui/material';
import {
  contentDescStyle,
  contentTitleStyle,
  heroPoliciesStyle,
  heroTitleStyle,
} from './policies.style';
import ScrollTopButton from '@/Components/Common/ScrollTopButton';

const Policies: React.FC = () => {
  const { t } = useTranslation('translation');

  const renderMain = () => (
    <Box className="container" sx={{ mx: 'auto', mt: { md: '5%' } }}>
      <ScrollTopButton />
      <Box component="section" className="hero-policies" sx={heroPoliciesStyle}>
        <Box sx={heroTitleStyle}>
          <Typography
            variant="h1"
            sx={{ fontSize: 'inherit', fontFamily: 'inherit' }}
          >
            {t('title.termsAndConditions')}
          </Typography>
        </Box>
      </Box>
      <Box
        component="section"
        className="content-policies"
        sx={{
          py: '50px',
          mx: 'auto',
          // display: 'flex',
          justifyContent: 'left',
          width: '70%',
        }}
      >
        <Box>
          <Typography sx={contentTitleStyle}>IDRApoll</Typography>
          <Typography sx={contentDescStyle}>
            IDRApoll is not just an online survey panel. We are a COMMUNITY that
            gives our members a VOICE. The quality of the panel is ensured
            through several control measures that include email and telephone
            verification, periodic updates on socio-economic profiles and
            limitation of monthly surveys. Panel members are rewarded for the
            polls they receive, through a points system. Points can be converted
            into gifts, cash or can be donated to charity. We are very strict
            and careful about the sensitive information of our panel members.
            Their details are kept in a secure database and are not linked to
            any of the opinions or polls they receive. To become a member of the
            IDRApoll Group, you must be a resident of Albania or Kosovo and be
            16 years of age or older. We offer our members the opportunity to
            participate in testing today and to evaluate tomorrow’s services and
            products. Your voice represents the voice of thousands!
          </Typography>
        </Box>

        <Box>
          <Typography sx={contentTitleStyle}>
            Recognition and Approval
          </Typography>
          <Typography sx={contentDescStyle}>
            By ticking “I agree” during the registration process as an IDRApoll
            member, or by participating in an IDRApoll activity, or simply by
            accessing and using the IDRApoll websites, you accept the terms of
            service, including the Privacy and Cookies Policy incorporated
            herein by reference. If you do not wish to accept these Terms and
            Conditions, you must leave the IDRApoll site immediately / or stop
            participating in any IDRApoll Panel or IDRApoll Activity.
          </Typography>
        </Box>

        <Box>
          <Typography sx={contentTitleStyle}>
            Terms and Conditions of Use
          </Typography>

          <Typography sx={contentDescStyle}>
            Rules for participants in IDRApoll surveys. IDRApoll surveys use
            this Panel which consists of respondents who answer Internet
            questionnaires by entering the links that IDRApoll sends to Panel
            members (“Panel” / “You”) by e-mail or mobile phone.
          </Typography>
        </Box>

        <Box>
          <Typography sx={contentTitleStyle}>Panel Membership</Typography>

          <Typography sx={contentDescStyle}>
            Everyone is free to apply for Panel membership. However, we reserve
            the right to deny membership, without any requirement to justify
            such refusal. In the event that the registration process is not
            completed successfully, your personal information will not be stored
            by us. Membership is not open to IDRA Research & Consulting
            employees or members of their immediate family. Membership is
            limited to one family member.
          </Typography>
        </Box>

        <Box>
          <Typography sx={contentTitleStyle}>
            Panel Membership Profile and Your Account
          </Typography>

          <Typography sx={contentDescStyle}>
            Upon completion of the IDRApoll Registration process, Members will
            be provided with a profile (“Panel Membership Profile”) and an
            account (“IDRApoll Account” for the IDRApoll Panel and Activities)
            for which credit is awarded (as defined below).
          </Typography>

          <Typography sx={contentDescStyle}>
            Only one Profile is allowed for each Member and only one IDRApoll
            Account is allowed for each panel Member for which credit is
            granted. Any Member found to have a Multiple Panel Membership
            Profile and / or secondary Panel Membership profiles will have their
            accounts deleted. Credits accrued to secondary accounts cannot be
            transferred. IDRApoll may request proof of identity in the event of
            a dispute. A unique email address must be used by each Member.
          </Typography>

          <Typography sx={contentDescStyle}>
            Each Panel Membership Profile and Account must be only used by one
            individual Member and may not be used by any other individual
            without the permission and authorization of IDRApoll. You agree to
            notify IDRApoll immediately if you are aware of/suspect any
            unauthorized use of your identifying information, Panel Membership
            profile and / or account, or any other security breach.
          </Typography>
          <Typography sx={contentDescStyle}>
            In the event of access to your IDRApoll account by another
            individual, IDRApoll reserves the right to freeze the account until
            a solution is reached. IDRApoll reserves the right to claim any
            benefit gained from the access of an unauthorized user, for which
            the Member will not have the opportunity to appeal against IDRApoll.
          </Typography>

          <Typography sx={contentDescStyle}>
            Members can create credits for their account (“Credit”) in the
            manner described in detail on the IDRApoll website, including
            completing the registration process and participating in the Panel
            and related activities for which credits are awarded. The amount of
            credits accrued for participation in the Panel or related activity
            will be notified to Members through authorized communications.
          </Typography>

          <Typography sx={contentDescStyle}>
            Unless otherwise specifically stated, credits can only be earned by
            following all the instructions regarding their earnings. Failure of
            a Member to correctly follow any required instructions or procedures
            may result in denial of credit benefits, for which the Member will
            have no opportunity to appeal against IDRA Poll.
          </Typography>

          <Typography sx={contentDescStyle}>
            Credits earned will be credited to your account, but they are not
            available for rewards until the Member has reached the specified
            compliance level announced at the end of the relevant registration
            process.
          </Typography>

          <Typography sx={contentDescStyle}>
            IDRApoll reserves the right to change the range of IDRApoll and
            Panel activities through which credits can be accumulated and to
            change the frequency of invitations to these activities and panels.
            IDRApoll also reserves the right to change the level of minimum
            conversion credits.
          </Typography>

          <Typography sx={contentDescStyle}>
            Credits may not be transferred to other IDRApoll accounts nor may
            they be accumulated together in any way and may not be sold or
            accumulated. Credits have no monetary value and cannot be used for
            any purpose other than the repayment of a certain reward.
          </Typography>
          <Box>
            <Typography sx={contentTitleStyle}>
              Use of your personal information
            </Typography>
            <Typography sx={contentDescStyle}>
              The information you provide to us will be treated in the strictest
              confidence and in accordance with all applicable laws, regulations
              and rules, including those related to privacy and personal data
              and including Albanian Data Protection legislation and Privacy and
              Electronic Communications Regulation.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentDescStyle}>
              As part of this commitment, we promise that:
            </Typography>
            <Box component="ul" fontSize="14px">
              <Box component="li">
                Except the connection with this Panel, we will not attempt to
                sell you anything or pass on your personal information to third
                parties for marketing or sales purposes.
              </Box>
              <Box component="li">
                Your individual survey responses will remain confidential and
                will only be used in groups with other confidential responses,
                unless you expressly agree that your responses to a particular
                survey, together with your personal information, can be passed
                to a client.
              </Box>
              <Box component="li">
                Refusing to answer a specific question will have no consequences
                for your Membership.
              </Box>
              <Box component="li">
                You can request to unsubscribe from the Panel at any time.
              </Box>
              <Box component="li">
                You have the right to request the correction or deletion of your
                personal information and the information you provide about your
                family at any time.
              </Box>
              <Box component="li">
                You have the right to request a copy of your personal
                information that we hold at any time.
              </Box>
            </Box>

            <Typography sx={contentDescStyle}>
              In some cases, in connection with our research, we may ask you to
              provide some sensitive personal information, for example
              disclosure of your racial or ethnic origin, political views,
              religious or philosophical beliefs, trade union membership or
              related information about health or sex life. You do not have to
              answer these questions and such questions will always include a “I
              prefer not to answer” option. The answers to these questions will
              remain confidential unless you expressly and freely accept that
              the answers to these questions, together with your personal
              information, may be passed on to clients for further research
              analysis. For further information on how we collect and / or use
              your personal information, please see our privacy policies below.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>E-MAIL Policy</Typography>
            <Typography sx={contentDescStyle}>
              Once you have voluntarily registered with the Panel and confirmed
              your email address and phone number, you should not mark emails or
              other communications received from IDRApoll as “spam” or
              unsolicited communications as you may be responsible for any
              damages that results in IDRApoll.
            </Typography>
            <Typography sx={contentDescStyle}>
              {' '}
              Due to the security settings of your mail, the survey invitations
              you receive from us may be directed to your distribution / junk
              folder or similar folders. To avoid this, please add to your email
              address noreply@IDRApoll.com
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Recording of general information{' '}
            </Typography>
            <Typography sx={contentDescStyle}>
              Regarding the statistical analysis that IDRApoll sends to its
              customers, IDRApoll needs some demographic and personal
              information about you, including confidential information, for
              example, of your income. The provision of this personal data to
              IDRApoll is a condition for participation in the Panel because
              otherwise it would not be possible for us to determine whether
              your participation in each survey will meet the survey criteria.{' '}
            </Typography>
            <Typography sx={contentDescStyle}>
              By this consensus you agree IDRApoll registers your data in the
              Panel database. We may also ask for certain information about
              other members in your home. Please make sure, and you are
              responsible, that you have the relevant consent of your family
              members. Personal data provided about yourself or other members of
              your family, when participating in a survey, is transformed into
              anonymous data, which will be used for statistical purposes only
              by IDRApoll or its clients, unless you clearly state your consent
              for us to do otherwise. During the registration process and during
              your participation in our research surveys, you agree to provide
              only true, accurate and complete data and agree to update the
              information regarding the Panel account you have completed, to
              ensure that it remains up-to-date and accurate.{' '}
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              How does the Panel work?{' '}
            </Typography>
            <Typography sx={contentDescStyle}>
              All Panelists will receive survey invitations by e-mail or phone
              from IDRApoll via “links” that lead to the completion of the
              survey. There is no obligation for panelists to respond to
              surveys. Each survey must be completed only once. Please do not
              mark IDRApoll emails as spam, according to the previous section.
              IDRApoll is not required to send surveys to all Panelists for each
              new study; nor can it guarantee a minimum or a maximum number of
              surveys per year. IDRApoll has taken high measures for data
              security. However, if any virus appears in the emails sent to you,
              IDRApoll is not responsible for the consequences that such cases
              may bring and therefore no compensation can be claimed from
              IDRApoll. When the required number of panelists has responded to a
              survey, IDRApoll or its clients have the right to end the survey
              and not collect further responses.{' '}
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Acceptable use and participation{' '}
            </Typography>
            <Typography sx={contentDescStyle}>
              {' '}
              IDRApoll is committed to create an environment where members can
              participate safely and respectfully, taking into account the views
              and opinions of other panelists. While participating in the Panel,
              you must understand that any messages, opinions, information,
              photos or any other material posted by the Panelists are the sole
              responsibility of the person who posted the material. You agree
              that you will not post or upload material that:
            </Typography>
            <Box component="ul" fontSize="14px">
              <Box component="li">
                It is illegal. This includes any message or content that
                promotes or discusses illegal activities or encourages others to
                engage in them.
              </Box>
              <Box component="li">
                It is abusive, shameful or hateful. This includes language,
                information or images.
              </Box>
              <Box component="li">
                It is threatening, harassing, defamatory to the Panelists or to
                an IDRApoll moderator or other IDRApoll employee.
              </Box>
              <Box component="li">It is incitement to hatred or violence.</Box>
              <Box component="li">
                It is fanatical, racial, ethnic, sexual or otherwise offensive.
              </Box>
              <Box component="li">
                It is an inappropriate political or religious message.
              </Box>
              <Box component="li">
                It is intended to advertise or market any good or service.
              </Box>
              <Box component="li">
                Contains viruses, corrupt files, “Trojan Horse” or any other
                malicious code.
              </Box>
              <Box component="li">
                Contains your personal information or other people's personal
                data.
              </Box>
              <Box component="li">
                It is owned or copyrighted by someone else.
              </Box>
            </Box>
            <Typography sx={contentDescStyle}>
              IDRApoll reserves the right to remove any content that has been
              posted through the survey tool or other interactive features that
              we may make available to Panelists.{' '}
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Keeping answers from surveys{' '}
            </Typography>
            <Typography sx={contentDescStyle}>
              In addition to your contact information, as part of the responses
              you give to IDRApoll after registration and in each survey,
              IDRApoll will also collect other personal data such as number of
              family members, number of cars in the family, etc. You agree that
              IDRApoll will store and use this data for statistical analysis and
              future selection for surveys. The answers provided by you will be
              nicknamed by IDRApoll for security reasons and will only be shared
              with our clients in an anonymous form, unless you have given your
              clear consent to be identified.{' '}
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>Children's privacy</Typography>
            <Typography sx={contentDescStyle}>
              For any survey targeting children, we will always seek the prior
              consent of our panelists who are either parents or legal
              guardians, before inviting the child to participate in the
              research.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Reward for online surveys
            </Typography>
            <Typography sx={contentDescStyle}>
              Each time you complete a survey ahead of time, you will receive
              points or another reward upon completion. The type and size of the
              reward or prize draw may vary according to the difficulty of the
              study, the length of the questionnaire or other similar elements.
              If you seek to unsubscribe from the Panel, you have one month to
              use your bonus points and exchange them for gifts. At the end of
              this period, the points account will be automatically deleted.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Transfer of recorded data
            </Typography>
            <Typography sx={contentDescStyle}>
              In some very specific cases, such as after you have given your
              consent and agreed to take part in a product test or when you
              place a request to receive a gift, or to process the distribution
              of rewards, IDRApoll will forward your personal information or
              study answers to another research institute market, end customer
              or supplier, as the case may be. With your explicit consent, when
              required in a survey, we may pass your individual survey responses
              along with your personal information (which does not include
              information related to identification e.g., names) to the client
              who requested the survey. The Client will use this information
              only for the purposes explained with your express consent. In the
              event that you become a member of the Panel through a third-party
              supplier, this supplier may be informed that you have become a
              member of the Panel and may access to the information you provided
              during the registration process.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Confidentiality and limitation of liability
            </Typography>
            <Typography sx={contentDescStyle}>
              As a research participant, you may be asked to review new
              concepts, products and packaging, or information that is still in
              the early stages of development. You may be allowed to view, use
              or access any non-commercial, confidential, or trade information
              or materials that are the sole and exclusive property of our
              respective client, including, without limitation, information in
              relation to customer products (collectively, “Confidential
              Information”). You must not copy, print, store, forward or
              disclose to anyone in any way, the Confidential Information to
              which you have access and you must not use the Confidential
              Information for any purpose other than for the purpose for which
              it was assigned to you. IDRApoll will own and retain exclusive
              ownership of its trademarks, logos, copyrights, any content on its
              website and other intellectual property rights. You agree to
              protect and retain undamaged IDRApoll and each of its partners,
              clients, managers, employees, agents and attorneys, from all
              liabilities, claims, actions, lawsuits, proceedings, trials,
              fines, damages (iii) your participation in research surveys
              conducted by IDRApoll, (iii) your membership in the Panel, any
              third-party allegation, or (iv) any breach by you of these Terms
              and Conditions. Any unlawful or discriminatory action committed by
              you during your membership of the Panel and participation in your
              research surveys conducted by IDRApoll, which may be considered a
              violation of criminal and / or civil law, will allow IDRApoll to
              use all legal remedies in this regard to the full extent permitted
              by law. IDRApoll accepts no warranties, express or implied, as to
              your membership of the Panel and your participation in research
              surveys conducted by IDRApoll and will not be liable (including
              any indirect, specific, incidental or passive injury) for you or
              any third party, for your membership within the Panel and for your
              participation in the research conducted.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Terms of participation
            </Typography>
            <Typography sx={contentDescStyle}>
              IDRApoll reserves the right, at any time, to change the current
              “Terms and Conditions”. We will keep you informed of any updates
              through the news section of the Panel members' website. From time
              to time, we may contact you by e-mail or telephone for account
              management purposes, as well as through newsletters with e-mails
              or special notifications containing key information related to the
              Panel membership activity and key Panel Updates (“Updates
              Membership”). We can also send you emails or sms to the number you
              stated during registration, communications about (i) the Panel's
              activities, the rewards you may be eligible to withdraw and for
              participating in our surveys; Engagement Updates”). Receiving any
              of these Engagement updates is optional and you can choose to
              unsubscribe from the Engagement updates at any time using the
              unsubscribe link provided in those emails or the corresponding
              button in your panel, to unsubscribe from the sms list.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Unsubscribe from the Panel
            </Typography>
            <Typography sx={contentDescStyle}>
              These Terms and Conditions are valid for the time being, with a
              notice period of 30 business days applicable to both parties. You
              may at any time request to unsubscribe from the Panel and not
              receive emails or telephone messages from IDRApoll. The notice to
              leave the Panel will be sent by email. Your account will become
              inactive in the Panel database. IDRApoll reserves the right to
              delete inactive panelists from the Panel. This means the
              withdrawal of panelists from the Panel and the complete deletion
              of the relevant points, if the member does not respond to any
              survey for more than twelve (12) months. Please note that if, for
              any reason, you are unable to respond to the polls for a longer
              period, you can notify us by email at info@IDRApoll.com , to avoid
              deletion from the Panel.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>Jurisdiction</Typography>
            <Typography sx={contentDescStyle}>
              These Rules and Conditions are regulated by the legislation of the
              Republic of Albania. From now on, the parties irrevocably agree to
              settle with understanding the disputes that may arise and then to
              submit to the jurisdiction of the court in relation to any claim
              for matters arising out of or in connection with these Terms and
              Conditions, the Policy of Privacy and Use of Cookies. The
              protection of your privacy is very important to us, so if you have
              any complaints or wish to exercise your rights to access, review,
              correct, delete or object to the processing of personal
              information, please contact us through the Contact Panel on
              IDRApoll’s page.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              Cookies & Digital Tracks
            </Typography>
            <Typography sx={contentDescStyle}>
              For statistical purposes, quality control, validation and, most
              importantly, to prevent the sending of notifications for any
              online survey you have completed, we may set and store cookies on
              your computer. A cookie is a small amount of data that is sent to
              your browser by a web server and stored on your computer’s hard
              drive. We also automatically capture information about your IP
              address, operating system, display settings, browser type, Flash
              and Java usage. We purposefully use them to:
            </Typography>
            <Box component="ul">
              <Box component="li">
                Ensure that the survey is submitted in a suitable form for the
                use of your device.
              </Box>
              <Box component="li">
                Assist in providing support for you if you have difficulty
                accessing or completing a survey.
              </Box>
              <Box component="li">
                Anonymous information is also used for research purposes. For
                example, to determine the percentage of panelists using a
                certain type of browser.
              </Box>
            </Box>
            <Typography sx={contentDescStyle}>
              We will not use cookies to collect personally identifiable
              information about you, and we do not associate the information we
              store in cookies with the information you submit on our site
              (e.g., your responses to a survey), but we link it to the
              socio-demographic profile. We use the following cookies:
            </Typography>
            <Box component="ul">
              <Box component="li">
                Necessary cookies. These are cookies that are required for the
                operation of our website. They include, for example, cookies
                that enable you to log into secure areas of our website.
              </Box>
              <Box component="li">
                Analytical/performance cookies. They allow us to recognize and
                count the number of visitors and to see how visitors move around
                our website when they are using it. This helps us to improve the
                way our website works.
              </Box>
              <Box component="li">
                Functionality cookies. These are used to recognize you when you
                return to our website. This enables us to personalize our
                content for you, greet you by name and remember your preferences
              </Box>
            </Box>
            <Typography sx={contentDescStyle}>
              Please note that third parties may also use cookies, over which we
              have no control. [These cookies are likely to be analytics /
              performance cookies or targeted cookies]. If you do not want us to
              use cookies as described above, or if you change your mind after
              approving the use of cookies, you may delete and block cookies on
              this site through your browser. If you use your browser settings
              to block all cookies (including essential cookies), some parts of
              our website may not work.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>Google Analytics</Typography>
            <Typography sx={contentDescStyle}>
              We also use a third-party tracking tool called “Google Analytics”
              that uses cookies to track personally identifiable information
              about visitors to our site, in order to collect data on the use of
              this website, as often as users visit this website, what pages
              they visit when they do so, and what other pages they used before
              coming to this page. This information includes Internet Protocol
              (IP) address, browser type, Internet Service Provider (ISP),
              reference / exit sites, operating system, date / time, and
              click-through data. We use the information we collect from Google
              Analytics for the sole purpose of improving our website, analyzing
              trends, administering the website, tracking user movements around
              the site, and collecting demographic information about the site of
              our users as a whole. We do not combine information collected
              through the use of Google Analytics with any personally
              identifiable information. Although Google Analytics installs a
              permanent cookie in your web browser to identify you as a unique
              user the next time you visit this site, the cookie does not can be
              used by anyone but Google. For more information about Google
              Analytics Security and Privacy Policy, please visit{' '}
              <a href="https://support.google.com/analytics/anser/6004245?hl=en">
                https://support.google.com/analytics/anser/6004245?hl=en .
              </a>
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>Privacy Policy </Typography>
            <Typography sx={contentDescStyle}>
              {' '}
              As stated above, IDRA is strongly committed to protecting and
              respecting your privacy. This policy (along with the Terms and
              Conditions of Use and the Cookies Policy) and any other document
              we refer to in this policy or the Terms and Conditions Our Terms
              of Use, including the ESOMAR Code of Conduct (explained below),
              set out how you will use your personal information and with whom
              it will be shared. Please read carefully. Any changes we may make
              to our future privacy policy will be posted on this site. The
              IDRApoll Panel is strongly committed to protecting the security
              and integrity of all personal data collected or disclosed to us:
              users can be assured that their privacy and the confidentiality of
              their information are our number one priority. This privacy policy
              sets out the collection, use and disclosure of personal and other
              identifiable information from our website in connection with the
              services provided herein. Use of our services is entirely
              voluntary and therefore the collection, processing and
              distribution of your personally identifiable information in
              connection with the Services and this Privacy Policy is at your
              sole discretion. Such voluntary consent can only be revoked by
              deleting the user profile at any time, but strictly without
              retroactive effect. IDRApoll Panel strives to comply with its
              privacy practices across applicable data protection legislation
              and regulations and standard codes of applicable market research
              and opinion societies, including ESOMAR, where IDRA conducts part.
              ESOMAR (www.esomar.org), is a global organization promoting,
              advancing, and promoting market research worldwide. We comply with
              ESOMAR Codes and guidelines for global standards for data search
              and analysis. In addition, IDRA is also certified with the ISO
              2025-2012 standard for market, opinion and social research. In
              accordance with the above, our website has security measures
              (including physical, electronic and managerial safeguards online
              and offline) to protect against the loss, misuse and alteration of
              all information under our control. However, no internet
              transmission is 100% secure and you accept this risk by using our
              website.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              {' '}
              How do we keep your data safe?
            </Typography>
            <Typography sx={contentDescStyle}>
              All your information is stored on secure servers owned or rented
              by us. If you have a username, password or other login details
              that allow you to access certain parts of our site, you should not
              allow any another person to use them and you must treat them as
              confidential. As stated above, if you believe or suspect that
              someone else knows your identification details, you should contact
              us as soon as possible. Unfortunately, the transmission of
              information via the Internet is not completely secure. While we
              will follow our normal procedures and comply with legal
              requirements to protect your information, we cannot guarantee the
              security of your information transmitted on our site and the
              latter is at your own risk. Once we have received your
              information, we will use strict procedures and security features
              to prevent unauthorized access.{' '}
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>
              What information do we collect from you?{' '}
            </Typography>
            <Typography sx={contentDescStyle}>
              We may collect and process the following data from you (“your
              information”):
            </Typography>
            <Box component="ul">
              <Box component="li">
                Contact details including your name, address, email address and
                phone number;
              </Box>
              <Box component="li">
                Information you provide when participating in surveys, panels or
                other forms of research. Participation in these panels,
                activities and surveys is entirely voluntary and some such
                activities may include the provision of sensitive personal data
                including information about your race, political views, trade
                union membership, health and / or criminal convictions;
              </Box>
              <Box component="li">
                The information you provide when filling out your website
                profile;
              </Box>
              <Box component="li">
                Information you provide about the thoughts, attitudes,
                experiences and uses of certain products or services;
              </Box>
              <Box component="li">
                Your bank account details (if you win a prize or are eligible to
                receive an incentive from us).
              </Box>
            </Box>
            <Typography sx={contentDescStyle}>
              We may collect your information through the following methods:
            </Typography>
            <Box component="ul">
              <Box component="li">
                Information you submit online through our site by registering
                with the IDRApoll Panel or by participating in IDRApoll
                activities or surveys.
              </Box>
              <Box component="li">Any correspondence you send to us.</Box>
              <Box component="li">Information you post on our site.</Box>

              <Box component="li">Details of transactions you perform.</Box>
            </Box>
            <Typography sx={contentDescStyle}>
              We are aware that your information may be confidential and we will
              protect the confidentiality of your information in accordance with
              our normal procedures and legal requirements. We will not use it
              for any purpose other than those set out in this policy. We will
              process your information in accordance with the Law on Data
              Protection of Albania No. 9887 dated 10.03.2008, as amended by the
              Law No. 48/2012, date 26.04.2012 on Protection of Personal Data.
              Furthermore, in accordance with Law No. 9918 date 19.5.2008 “On
              Electronic Communications in the Republic of Albania”.
            </Typography>
          </Box>

          <Box>
            <Typography sx={contentTitleStyle}>Contact</Typography>

            <Typography sx={contentDescStyle}>
              IDRA Research and Consulting is a company incorporated and
              registered according to the laws of Albania with the license
              number K61715038P and with offices registered in Rr. “Siri Kodra”,
              Pall. 94/5, Apt. 49 P. O. Box 1730, Tirana, Albania.If you have
              any questions or concerns about terms of use, cookies or privacy
              when using this Site, please contact us at{' '}
              <a href="https://idrapoll.com/info@IDRApoll.com%20">
                info@IDRApoll.com
              </a>
              .
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return <DefaultLayout content={renderMain()} />;
};

export default Policies;
