import * as React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { TypeAnimation } from 'react-type-animation';

import Utils from '@/Utils';
import { ROUTERS } from '@/Constants';

import { Box, Stack, Typography } from '@mui/material';

import { sectionTitleStyle } from './home.style';

const ForClientsSection: React.FC = () => {
  const { t } = useTranslation();
  const currentLanguage = Utils.getSavedLanguage();

  React.useEffect(() => {
    i18next.changeLanguage(currentLanguage);
  }, []);

  const _renderTypeAnimation = () => {
    const text1 =
      currentLanguage === 'en'
        ? `Will my product success?`
        : 'A do të ketë sukses produkti im?';
    const text2 =
      currentLanguage === 'en'
        ? 'How satisfied are my customers?'
        : 'Sa të kënaqur janë konsumatorët me stafin tim?';
    const text3 =
      currentLanguage === 'en'
        ? 'What does the consumer think about my prices?'
        : 'Çfarë mendon konsumatori për çmimet e mia?';
    const text4 =
      currentLanguage === 'en'
        ? 'How satisfied are the consumers with my staff?'
        : 'Sa të kënaqur janë konsumatorët me stafin tim?';
    return (
      <TypeAnimation
        sequence={[
          `${text1}`,
          2000, // Waits 2s
          `${text2}`,
          2000, // Waits 2s
          `${text3}`,
          2000, // Waits 2s
          `${text4}`,
          2000,
        ]}
        wrapper="span"
        cursor={true}
        repeat={Infinity}
        style={{ fontSize: 'inherit', fontFamily: 'inherit' }}
      />
    );
  };

  return (
    <Box id="forClients" component="section">
      <Box className="forclients-container" sx={{ pb: '45px' }}>
        <Stack>
          <Stack
            direction="row"
            sx={{ alignItem: 'center', width: '340px', m: 'auto' }}
          >
            <Box
              sx={{
                flex: '1 1',
                height: '2px',
                background: 'white',
                fontWeight: 700,
                alignSelf: 'center',
              }}
            ></Box>
            <Typography
              className="forclients-title"
              variant="h3"
              sx={{
                ...sectionTitleStyle,
                p: '75px 1rem',
                fontSize: '34px',
                color: '#FFFFFF',
                fontWeight: 500,
              }}
            >
              {t('title.forClients')}
            </Typography>
            <Box
              sx={{
                flex: '1 1',
                height: '2px',
                background: 'white',
                fontWeight: 700,
                alignSelf: 'center',
              }}
            ></Box>
          </Stack>
          <Box
            className="forclients-text"
            sx={{
              width: '80%',
              color: 'white',
              lineHeight: '5vw',
              m: 'auto',
              textAlign: 'center',
              fontSize: '3.8vw',
              fontFamily: 'Roboto, sans-serif',
              '@media screen and  (min-width: 1001px)': {
                fontSize: '2.4vw',
              },
            }}
          >
            {_renderTypeAnimation()}
          </Box>
          <Box
            className="forclients-button"
            pt="1%"
            sx={{ textAlign: 'center', pt: '1%', pb: '3%' }}
          >
            <button
              style={{
                border: 'solid 0.5px #ffffff',
                textAlign: 'center',
                background: 'transparent',
                padding: '12px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => Utils.redirect(ROUTERS.CONTACT)}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  textAlign: 'center',
                  color: 'rgb(255, 255, 255)',
                  fontSize: '25px',
                  textDecoration: 'none',
                  fontWeight: 200,
                  fontFamily: '"Poppins"',
                  // m: '5px 0',
                  transition: '0.1s',
                  '&:after': {
                    display: 'inline-block',
                    paddingLeft: '8px',
                    content: '"\u2709"',
                    transition: 'transform 0.3s ease-out',
                    fontSize: '30px',
                  },
                  '&:hover': {
                    color: '#fd7e14',
                  },
                  '&:hover:after': {
                    fontWeight: 700,
                    color: '#fd7e14',
                    transform: 'translateX(4px)',
                  },
                }}
              >
                {t('button.contactUs')}
              </Stack>
            </button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ForClientsSection;
