import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IDataWhyIdraPoll } from '@/Interfaces/WhyIDRApoll.interface';

const setWhyIdraPollActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_WHY_IDRA_POLL_ACTION_LOADING,
    payload,
  };
};
const getWhyIdraPollLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_WHY_IDRA_POLL_GET_LOADING,
    payload,
  };
};

const clearWhyIdraPoll = () => {
  return {
    type: ACTION_TYPES.CLEAR_WHY_IDRA_POLL,
  };
};

const getWhyIdraPollSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_WHY_IDRA_POLL_SUCCESS,
    payload,
  };
};

const getWhyIdraPollFail = () => {
  return {
    type: ACTION_TYPES.GET_WHY_IDRA_POLL_FAILURE,
  };
};

const getWhyIdraPoll = (type: string) => {
  return async (dispatch: any) => {
    dispatch(getWhyIdraPollLoading(true));
    await API.getWhyIdraPoll(type)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getWhyIdraPollFail());
        else {
          dispatch(getWhyIdraPollSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getWhyIdraPollFail());
      });
  };
};

const updateWhyIdraPollSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_WHY_IDRA_POLL_SUCCESS,
    payload,
  };
};

const updateWhyIdraPollFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_WHY_IDRA_POLL_FAILURE,
  };
};

const updateWhyIdraPoll = (type: string, payload: IDataWhyIdraPoll) => {
  return async (dispatch: any) => {
    dispatch(setWhyIdraPollActionLoading(true));
    await API.updateWhyIdraPoll(type, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateWhyIdraPollFail());
        else {
          dispatch(updateWhyIdraPollSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateWhyIdraPollFail());
      });
  };
};

const createWhyIdraPollSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_WHY_IDRA_POLL_SUCCESS,
    payload,
  };
};

const createWhyIdraPollFail = () => {
  return {
    type: ACTION_TYPES.CREATE_WHY_IDRA_POLL_FAILURE,
  };
};

const createWhyIdraPoll = (payload: IDataWhyIdraPoll) => {
  return async (dispatch: any) => {
    dispatch(setWhyIdraPollActionLoading(true));
    await API.createWhyIdraPoll(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createWhyIdraPollFail());
        else {
          dispatch(createWhyIdraPollSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createWhyIdraPollFail());
      });
  };
};

export default {
  getWhyIdraPoll,
  updateWhyIdraPoll,
  createWhyIdraPoll,
  clearWhyIdraPoll,
};
