import { sendRequest } from '@/Configs';
import { IDataRewards } from '@/Interfaces/Rewards.interface';
import { API } from '@constants';

const { REWARDS } = API;

export const getRewards = async (id: string) => {
  return await sendRequest(`${REWARDS.ROOT}${id}`, 'GET');
};

export const updateRewards = async (id: string, payload: IDataRewards) => {
  return sendRequest(`${REWARDS.ROOT}${id}`, 'PUT', payload);
};
