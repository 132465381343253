import React from 'react';
import _ from 'lodash';
import { Stack } from '@mui/material';

import AppBarDesktop from './AppBarDesktop';

const AppBarComponent: React.FC = () => {
  return (
    <Stack>
      <AppBarDesktop />
    </Stack>
  );
};

export default AppBarComponent;
