import { useEffect, useState } from 'react';
import _ from 'lodash';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  IChangePassword,
  IMsgUserData,
  IUserData,
} from '@/Interfaces/SystemUser.interface';
import { CommonColors } from '@/Themes';
import { useTypedDispatch } from '@/store';
import SystemUserAction from '@/Actions/SystemUser.action';

const { changePasswordSystemUser } = SystemUserAction;

interface IPropsSystemUser {
  open: boolean;
  onClose(value?: string): void;
  data: IUserData;
}

const DEFAULT_SYSTEM_USER: IChangePassword = {
  email: '',
  oldPassword: '',
  newPassword: '',
  name: '',
};

const UpdateSystemUser: React.FC<IPropsSystemUser> = ({
  open,
  onClose,
  data,
}) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewPassword] = useState(false);
  const [dataUser, setDataUser] =
    useState<IChangePassword>(DEFAULT_SYSTEM_USER);
  const [msg, setMsg] = useState<IMsgUserData>(DEFAULT_SYSTEM_USER);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickNewShowPassword = () => setNewPassword(!showNewPassword);

  useEffect(() => {
    if (data) setDataUser({ email: data.email, name: data.name });
  }, [data]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!_.trim(dataUser.newPassword) || !_.trim(dataUser.oldPassword)) {
      if (!_.trim(dataUser.newPassword))
        setMsg({ newPassword: 'New password cannot be blank!' });
      if (!_.trim(dataUser.oldPassword))
        setMsg({ oldPassword: 'Old password cannot be blank!' });
    } else {
      await dispatch(changePasswordSystemUser(dataUser));
      await onClose();
      setDataUser({ ...dataUser, newPassword: '', oldPassword: '' });
    }
  };

  return (
    <Dialog open={open} keepMounted fullWidth>
      <DialogTitle>
        <Typography
          sx={{
            color: CommonColors.mainColor,
            letterSpacing: 5,
            fontSize: 25,
          }}
        >
          {t('title.updateUser')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Box sx={{ border: '1px solid #0000001f', p: 3 }}>
            <Box mb={2}>
              <InputLabel sx={{ fontWeight: '500', mb: 1 }}>
                {t('label.email')}
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                key={`email${dataUser.email}`}
                value={dataUser.email}
                label={t('label.email')}
                required
                disabled={!_.isEmpty(data.email)}
                error={!_.isEmpty(msg.email)}
                helperText={msg.email}
              />
            </Box>
            <Box mb={2}>
              <InputLabel sx={{ fontWeight: '500', mb: 1 }}>
                {t('label.name')}
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={dataUser.name}
                label={t('label.name')}
                required
                disabled={!_.isEmpty(data.name)}
                error={!_.isEmpty(msg.name)}
                helperText={msg.name}
              />
            </Box>
            <Box mb={2}>
              <InputLabel sx={{ fontWeight: '500', mb: 1 }}>
                {t('label.oldPassword')}
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={dataUser.oldPassword || ''}
                label={t('label.oldPassword')}
                type={showPassword ? 'text' : 'password'}
                key={`old${dataUser.email}`}
                required
                error={!_.isEmpty(msg.password)}
                helperText={msg.password}
                onChange={(e) => {
                  setDataUser({ ...dataUser, oldPassword: e.target.value });
                  setMsg({ ...msg, password: '' });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <InputLabel sx={{ fontWeight: '500', mb: 1 }}>
                {t('label.newPassword')}
              </InputLabel>
              <TextField
                fullWidth
                size="small"
                value={dataUser.newPassword || ''}
                label={t('label.newPassword')}
                key={`new${dataUser.email}`}
                type={showNewPassword ? 'text' : 'password'}
                required
                error={!_.isEmpty(msg.password)}
                helperText={msg.password}
                onChange={(e) => {
                  setDataUser({ ...dataUser, newPassword: e.target.value });
                  setMsg({ ...msg, password: '' });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickNewShowPassword}>
                        {!showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <LoadingButton
              variant="contained"
              color="fuzzyWuzzyBrown"
              onClick={() => {
                setDataUser(DEFAULT_SYSTEM_USER);
                onClose();
              }}
            >
              {t('button.back')}
            </LoadingButton>
            <LoadingButton type="submit" variant="contained" color="oceanGreen">
              {t('button.save')}
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateSystemUser;
