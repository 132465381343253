import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { buttonFilter } from './SystemUser.styles';
import { SystemUserTable } from '@/Components/LayoutPart/DataTable';
import { IUserData } from '@/Interfaces/SystemUser.interface';
import CreateSystemUser from './CreateSystemUser';
import UpdateSystemUser from './UpdateSystemUser';
import { RootState, useTypedDispatch } from '@/store';
import SystemUserAction from '@/Actions/SystemUser.action';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';
import { ENUMS } from '@/Constants';
import { Confirm } from '@/Components/Dialogs';
import Utils from '@/Utils';

const { fetchSystemUser, changeStatusSystemUser } = SystemUserAction;

const DEFAULT_PAGINATION: IPaginationFilter = {
  page: 1,
  limit: 10,
  keyword: '',
};

export const DEFAULT_CONFIRM = {
  type: '',
  isOpen: false,
  message: '',
  state: {
    id: '',
    status: '',
    email: '',
  },
};

const SystemUserList = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');
  const [openCreateUser, setOpenCreateUser] = useState<boolean>(false);
  const [openUpdateUser, setOpenUpdateUser] = useState<boolean>(false);
  const [filter, setFilter] = useState<IPaginationFilter>(DEFAULT_PAGINATION);
  const [userData, setUserData] = useState<IUserData>({
    email: '',
    name: '',
  });
  const [confirmDialog, setConfirmDialog] = useState<any>(DEFAULT_CONFIRM);

  const createSystemUserSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.SYSTEM_USER, 'createSystemUserSuccess')
  );

  useEffect(() => {
    const localeStorage = Utils.getSavedLanguage();
    i18next.changeLanguage(localeStorage);
  }, []);

  useEffect(() => {
    if (createSystemUserSuccess) setOpenCreateUser(false);
  }, [createSystemUserSuccess]);

  const handleCloseSystemUserDetail = () => {
    setOpenCreateUser(false);
    setOpenUpdateUser(false);
  };

  const onRowAction = async (
    item: IUserData,
    type: 'edit' | 'delete' | 'change-status'
  ) => {
    if (type === 'edit') {
      setUserData(item);
      setOpenUpdateUser(true);
    }
    if (type === 'change-status') {
      const description = `${t('message.areYouSureYouWantTo')} ${
        item.status === 'active'
          ? `${t('message.inactive')}`
          : `${t('message.activated')}`
      }  ${t('message.thisAccount')}?`;
      setConfirmDialog({
        isOpen: true,
        message: description,
        state: {
          id: item.id,
          status: item.status,
          email: item.email,
        },
      });
    }
  };

  const handleSubmitChangeStatus = (state: {
    id?: string;
    status?: string;
    email?: string;
  }) => {
    const { id, status, email } = state;
    if (id && status === ENUMS.STATUS.ACTIVE)
      dispatch(
        changeStatusSystemUser(filter, { email: email, status: 'deleted' })
      );
    if (id && status === ENUMS.STATUS.DELETE)
      dispatch(
        changeStatusSystemUser(filter, { email: email, status: 'active' })
      );
    setConfirmDialog(DEFAULT_CONFIRM);
  };

  const onCancel = () => {
    setConfirmDialog(DEFAULT_CONFIRM);
    dispatch(fetchSystemUser(filter));
  };

  //Render
  const _renderTopSystemUser = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography
            sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
            variant="h4"
          >
            {t('title.systemUser')}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={'end'}>
          <Button variant="contained" onClick={() => setOpenCreateUser(true)}>
            {t('button.create')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderFilterSystemUser = () => {
    return (
      <Grid container spacing={2} mt={1}>
        <Grid item display={'flex'} xs={3.5}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              mr: 1,
              minWidth: '14vh',
            }}
          >
            {t('label.searchBy')}
          </InputLabel>
          <TextField
            autoComplete="off"
            size="small"
            label={'Email'}
            value={filter.keyword}
            onChange={(e) => setFilter({ ...filter, keyword: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              ...buttonFilter,
              mr: 1,
            }}
            onClick={() => dispatch(fetchSystemUser({ ...filter, page: 1 }))}
          >
            {t('button.filter')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: '1px solid #28384580',
              color: CommonColors.gratou,
              '&:hover': {
                border: '1px solid #1e2a3480',
              },
            }}
            onClick={() => {
              setFilter({ ...filter, keyword: '' });
              dispatch(fetchSystemUser({ page: 1, limit: 10 }));
            }}
          >
            {t('button.reset')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderCreateSyetemUser = () => {
    return (
      <Box>
        <CreateSystemUser
          open={openCreateUser}
          onClose={handleCloseSystemUserDetail}
        />
      </Box>
    );
  };

  const _renderUpdateSystemUser = () => {
    return (
      <Box>
        <UpdateSystemUser
          open={openUpdateUser}
          onClose={handleCloseSystemUserDetail}
          data={userData}
        />
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2}>
        <Grid item xs={12}>
          {_renderTopSystemUser()}
          <Divider />
          {_renderFilterSystemUser()}
          <Grid item mt={2}>
            <SystemUserTable onRowAction={onRowAction} />
          </Grid>
          {_renderCreateSyetemUser()}
          {_renderUpdateSystemUser()}
        </Grid>
        <Confirm
          confirm={confirmDialog}
          onCancel={() => onCancel()}
          callback={() => handleSubmitChangeStatus(confirmDialog.state)}
        />
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default SystemUserList;
