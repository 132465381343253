import { IconButton } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const ScrollTopButton = () => {
  // const [visible, setVisible] = useState(false);
  // window.onscroll = function () {
  //   scrollFunction();
  // };

  // const scrollFunction = () => {
  //   if (document.documentElement.scrollTop > 300) {
  //     setVisible(true);
  //   } else {
  //     setVisible(false);
  //   }
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
  	in place of 'smooth' */
    });
  };

  return (
    <IconButton
      id="onTopBtn"
      onClick={() => scrollToTop()}
      sx={{
        // display: { xs: 'none', md: visible ? 'block' : 'none' },
        zIndex: 9999,
        position: 'fixed',
        bottom: { xs: '40px', sm: '50px' },
        right: { xs: '20px', sm: '40px' },
        borderRadius: '50px',
        color: '#FF8C00',
      }}
    >
      <ArrowCircleUpIcon sx={{ fontSize: { xs: '45px', sm: '4vw' } }} />
    </IconButton>
  );
};

export default ScrollTopButton;
