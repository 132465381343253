import { sendRequest } from '@/Configs';
import { API } from '@/Constants';
import { ICustomersData } from '@/Interfaces/Customers.interface';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';

const { CUSTOMMERS } = API;

export const fetchCustommers = async (payload: IPaginationFilter) => {
  return sendRequest(CUSTOMMERS.ROOT, 'GET', payload);
};

export const getCustommers = async (id: string) => {
  return sendRequest(`${CUSTOMMERS.ROOT}${id}`, 'GET');
};

export const createCustommers = async (payload: ICustomersData) => {
  return sendRequest(`${CUSTOMMERS.ROOT}`, 'POST', payload);
};

export const updateCustommers = async (payload: ICustomersData) => {
  const id = payload.id;
  return sendRequest(`${CUSTOMMERS.ROOT}${id}`, 'PUT', payload);
};

export const deleteCustommers = async (id: string) => {
  return sendRequest(`${CUSTOMMERS.ROOT}${id}`, 'DEL');
};
