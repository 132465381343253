const USER_ROUTER_ROOT = '/';
const ADMIN_ROUTER_ROOT = '/admin';

const BASIC_ROUTERS = {
  FORBIDEN: '/forbiden',
  NOT_FOUND: '*',
  AUTH: '/auth',
};

const USER_ROUTERS = {
  HOME: USER_ROUTER_ROOT,
  CONTACT: `${USER_ROUTER_ROOT}contact`,
  ALL_RESULTS: `${USER_ROUTER_ROOT}all`,
  RESULTS_DETAIL: `${USER_ROUTER_ROOT}all/details`,
  POLICIES: `${USER_ROUTER_ROOT}policies`,
  FAQ: `${USER_ROUTER_ROOT}faq`,
  UNSUBSCRIBE: `${USER_ROUTER_ROOT}unsubscribe`
};

const ADMIN_ROUTERS = {
  ADMIN: '/admin',
  ADMIN_DASHBOARD: `${ADMIN_ROUTER_ROOT}/dashboard`,
  SYSTEM_USER: `${ADMIN_ROUTER_ROOT}/system-users`,
  WHAT_IDRA_POLL: `${ADMIN_ROUTER_ROOT}/what-idra-poll`,
  REWARDS: `${ADMIN_ROUTER_ROOT}/rewards`,
  PRODUCTS_AND_SERVICES: `${ADMIN_ROUTER_ROOT}/Products-and-services`,
  WHY_IDRA_POLL: `${ADMIN_ROUTER_ROOT}/why-idra-poll`,
  STATISTICAL: `${ADMIN_ROUTER_ROOT}/statistical`,
  CUSTOMERS: `${ADMIN_ROUTER_ROOT}/customers`,
  INBOX: `${ADMIN_ROUTER_ROOT}/inbox`,
  CONTACT_MANAGEMENT: `${ADMIN_ROUTER_ROOT}/contact-management`,
  RESULTS: `${ADMIN_ROUTER_ROOT}/results`,
  RESULTS_CREATE: `${ADMIN_ROUTER_ROOT}/results/create`,
  RESULTS_UPDATE: `${ADMIN_ROUTER_ROOT}/results/update/`,
  UPLOAD_GALLERY: `${ADMIN_ROUTER_ROOT}/upload-gallery`,
};

export { BASIC_ROUTERS, USER_ROUTERS, ADMIN_ROUTERS };

export default {
  ...BASIC_ROUTERS,
  ...USER_ROUTERS,
  ...ADMIN_ROUTERS,
};
