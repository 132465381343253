import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { TABLES } from '@/Constants';
import DeleteIcon from '@mui/icons-material/Delete';

import { ResultsActions } from '@/Actions';
import { useTypedDispatch } from '@/store';
import {
  IDataResults,
  IPaginationsResults,
  IResults,
} from '@/Interfaces/Results.interface';
import dayjs from 'dayjs';
import { DEFAULT_CONFIRM } from '@/Constants/ConfirmDialog';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { tableContainerStyles, tableFooterStyles } from './DataTable.styles';
import { PaginationActions } from '@/Components/Common';
import { Confirm } from '@/Components/Dialogs';

const { RESULTS_TABLE_HEADER } = TABLES;
const { fetchResults, publishedResults, landingpageResults } = ResultsActions;

const DEFAULT_PAGINATION: IPaginationsResults = {
  page: 1,
  limit: 10,
  title: '',
  category: '',
  languageCode: '',
};

interface IInbox {
  onRowAction(Project: any, action: 'edit' | 'detail' | 'delete'): void;
  payload: IResults;
  isLoading: boolean;
  filter?: any;
}

const ResultsTable: React.FC<IInbox> = ({
  onRowAction,
  payload,
  isLoading,
  filter,
}) => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [pagination, setPaginations] =
    useState<IPaginationsResults>(DEFAULT_PAGINATION);
  const colSpan = RESULTS_TABLE_HEADER.length + 5;
  const [dataResults, setDataResults] = useState<IDataResults[]>();
  const [confirmDialog, setConfirmDialog] =
    useState<IConfirmStructure>(DEFAULT_CONFIRM);

  useEffect(() => {
    dispatch(fetchResults(pagination));
  }, []);

  useEffect(() => {
    if (payload && filter) {
      setPaginations({
        ...pagination,
        page: filter.page,
        category: filter?.category,
        title: filter?.title,
        languageCode: filter?.languageCode,
      });
      setDataResults(payload.data);
    }
  }, [payload, filter]);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    type?: string
  ) => {
    let page = pagination.page;
    if (type === 'lastPage') page = newPage;
    else {
      if (newPage === 0) page = 1;
      else if (newPage < page) page -= 1;
      else if (newPage >= page) page += 1;
    }
    dispatch(fetchResults({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchResults({ ...pagination, limit: perPage }));
  };

  const handleOnChangeLandingpage = (data: any, type: string) => {
    const description = t('message.areYouSureYouWantToChangeTheLandingPage');

    setConfirmDialog({
      isOpen: true,
      message: description,
      state: {
        id: data.id,
        status: data.isLandingPage,
        type: type,
      },
    });
  };

  const handleOnChangePublished = (data: any, type: string) => {
    const description =
      data.ispublished === true
        ? t('message.areYouSureYouWantToUnpublish')
        : t('message.areYouSureYouWantToPublish');

    setConfirmDialog({
      isOpen: true,
      message: description,
      state: {
        id: data.id,
        status: data.ispublished,
        type: type,
      },
    });
  };

  const handleSubmitChangeStatus = (state: {
    id?: string;
    status?: boolean;
    type?: string;
  }) => {
    const { id, status, type } = state;
    if (id && status === true && type === 'landingPage')
      dispatch(landingpageResults(id, { isLandingPage: false }, pagination));
    else if (id && status === false && type === 'landingPage')
      dispatch(landingpageResults(id, { isLandingPage: true }, pagination));
    if (id && status === true && type === 'published')
      dispatch(publishedResults(id, { isPublished: false }, pagination));
    else if (id && status === false && type === 'published')
      dispatch(publishedResults(id, { isPublished: true }, pagination));
    setConfirmDialog(DEFAULT_CONFIRM);
  };

  const onCancel = () => {
    setConfirmDialog(DEFAULT_CONFIRM);
    dispatch(fetchResults(pagination));
  };

  const _renderTableHeader = () => {
    return (
      <TableRow>
        <TableCell sx={{ maxWidth: 50 }}>{t('table.no')}</TableCell>
        {_.map(RESULTS_TABLE_HEADER, (item: any) => (
          <TableCell
            key={`head-${item.value}`}
            sx={{
              fontWeight: '700',
              minWidth: item.value === 'title' ? 250 : 100,
            }}
            align="center"
          >
            {t(`table.${item?.value}`)}
          </TableCell>
        ))}
        <TableCell>{t('table.language')}</TableCell>
        <TableCell sx={{ maxWidth: 120, textAlign: 'center' }}>
          {t('table.published')}
        </TableCell>
        <TableCell sx={{ maxWidth: 120, textAlign: 'center' }}>
          {t('table.landingPage')}
        </TableCell>
        <TableCell sx={{ maxWidth: 100, textAlign: 'center' }}>
          {t('table.action')}
        </TableCell>
      </TableRow>
    );
  };

  const _renderTableBody = () => {
    if (_.isEmpty(dataResults))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 1}>
            There is no Results to display
          </TableCell>
        </TableRow>
      );
    return _.map(dataResults, (item: any, index: number) => {
      return (
        <TableRow hover key={index}>
          <TableCell>
            {index + 1 + (payload.currentPage - 1) * pagination.limit}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 450,
            }}
          >
            {item.title}
          </TableCell>
          <TableCell>
            {dayjs(item.updatedAt).format('MMMM D, YYYY h:mm A')}
          </TableCell>
          <TableCell>
            {item.languageCode === 'en' ? (
              <Typography>English</Typography>
            ) : item.languageCode === 'sq' ? (
              <Typography>Albania</Typography>
            ) : (
              <Typography>Both</Typography>
            )}
          </TableCell>
          <TableCell align="center">
            <Checkbox
              disabled={item.ispublished && item.isLandingPage}
              checked={item.ispublished}
              onChange={() => handleOnChangePublished(item, 'published')}
              sx={{
                svg: {
                  color:
                    item.ispublished && item.isLandingPage
                      ? '#d3d3d3'
                      : 'inherit',
                },
              }}
            />
          </TableCell>
          <TableCell align="center">
            <Checkbox
              disabled={!item.ispublished}
              checked={item.isLandingPage}
              onChange={() => handleOnChangeLandingpage(item, 'landingPage')}
              sx={{
                svg: {
                  color: !item.ispublished ? '#d3d3d3' : 'inherit',
                },
              }}
            />
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            <Tooltip title={t('tooltip.edit')}>
              <IconButton onClick={() => onRowAction(item, 'edit')}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('tooltip.delete')}>
              <IconButton onClick={() => onRowAction(item, 'delete')}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10]}
            colSpan={colSpan + 6}
            count={payload.totalItems || 0}
            rowsPerPage={payload.itemsPerPage || 0}
            page={payload.currentPage - 1 || 0}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader()}</TableHead>
          <TableBody>
            {!isLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!_.isEmpty(payload) && _renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box>
      {_renderTable()}
      <Confirm
        confirm={confirmDialog}
        onCancel={() => onCancel()}
        callback={() => handleSubmitChangeStatus(confirmDialog.state)}
      />
    </Box>
  );
};

export default ResultsTable;
