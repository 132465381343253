import * as React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import Utils from '@/Utils';
import { LIST, ROUTERS } from '@/Constants';
import mainLogo from '@/Assets/logo_idrapoll.svg';
import mainLogoScroll from '@/Assets/logo.svg';
import { RegisterUserPopup, LoginUserPopup } from '@/Components/Dialogs';

import {
  Box,
  IconButton,
  Button,
  MenuItem,
  Typography,
  List,
  Collapse,
  Link,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { btnStyles } from './AppBar.styles';
import LanguageSwitch from './LanguageSwitch';
import { useLocation } from 'react-router';

const { USER_MENU } = LIST.MENU_LIST;

const AppBarDesktop: React.FC = () => {
  const { t } = useTranslation();
  const pathname = useLocation()?.pathname;

  const [openNavMenu, setOpenNavMenu] = React.useState<boolean>(false);

  const [isShowLoginPopup, setShowLoginPopup] = React.useState<boolean>(false);
  const [isShowRegisterPopup, setShowRegisterPopup] =
    React.useState<boolean>(false);
  const [isShowAppBarDefault, setIsShowAppBarDefault] =
    React.useState<boolean>(true);

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    if (document.documentElement.scrollTop > 50) {
      setIsShowAppBarDefault(false);
    } else {
      setIsShowAppBarDefault(true);
    }
  };

  const handleOpenNavMenu = () => {
    setOpenNavMenu(!openNavMenu);
  };

  React.useEffect(() => {
    const localeStorage = Utils.getSavedLanguage();
    i18next.changeLanguage(localeStorage);
  }, []);

  const handleMenuClick = async (pageLabel: string) => {
    if (pathname !== ROUTERS.HOME) {
      const newPath = `${ROUTERS.HOME}#${pageLabel}`;
      Utils.redirect(newPath);
    }
  };

  const _renderPopupLogin = () => (
    <LoginUserPopup
      open={isShowLoginPopup}
      onClose={() => setShowLoginPopup(false)}
      openRegisterForm={() => {
        setShowLoginPopup(false);
        setShowRegisterPopup(true);
      }}
    />
  );

  const _renderPopupRegister = () => (
    <RegisterUserPopup
      open={isShowRegisterPopup}
      onClose={() => setShowRegisterPopup(false)}
      openLoginForm={() => {
        setShowRegisterPopup(false);
        setShowLoginPopup(true);
      }}
    />
  );

  const _renderAppbarMobile = () => (
    <Box
      className="appBarMobile"
      sx={{
        display: { md: 'none' },
        position: 'relative',
        // backgroundColor: 'rgb(33, 37, 41, 1)',
      }}
    >
      <List disablePadding={true}>
        <Box
          sx={{
            display: { xs: 'flex' },
            justifyContent: 'space-between',
            pl: { xs: '3em', md: '4em', lg: '10em' },
            backgroundColor: 'rgb(33, 37, 41, 1)',
            height: '60px',
            my: 'auto',
          }}
        >
          <Box
            onClick={() => Utils.redirect(ROUTERS.HOME)}
            component="img"
            alt="monitoringmatrix"
            src={mainLogo}
            sx={{
              display: 'flex',
              width: { xs: '25%', sm: '20%', md: '25%' },
              cursor: 'pointer',
            }}
          />

          <Box my="auto">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="warning"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>
        <Collapse
          in={openNavMenu}
          timeout="auto"
          unmountOnExit
          sx={{
            width: '100%',
            height: 'auto',
            flexDirection: 'column',
            backgroundColor: 'rgba(10, 10, 6, 0.8)',
            color: 'white',
            fontSize: '12px',
          }}
        >
          <List
            sx={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: '20px',
              // mx: 'auto',
            }}
          >
            {USER_MENU.map((page: any, index: number) => (
              <Link
                key={index}
                onClick={() => {
                  handleMenuClick(page.label);
                }}
                href={`#${page.label}`}
                sx={{
                  textDecoration: 'none',
                  width: '100%',
                }}
              >
                <MenuItem
                  key={page.label}
                  sx={{
                    width: '100%',
                    fontFamily: 'inherit',
                    p: '15px 1vw',
                    '&::after': {
                      bottom: '0',
                      content: '""',
                      display: 'block',
                      width: '0',
                      height: '2px',
                      left: '30%',
                      position: 'absolute',
                      background: 'rgb(255, 255, 255)',
                      transition: 'width 0.5s ease 0s, left 0.5s ease 0s',
                      boxSizing: 'border-box',
                    },
                    '&:hover': {
                      '&:after': { width: '100%', left: '0' },
                    },
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{
                      color: 'rgb(255, 255, 255)',
                      fontSize: '14px',
                      fontFamily: 'inherit',
                      m: '0 auto',
                      letterSpacing: '0.15em',
                    }}
                  >
                    {t(`menu.${page.label}`)}
                  </Typography>
                </MenuItem>
              </Link>
            ))}
            <Button
              sx={btnStyles}
              variant="outlined"
              onClick={() => setShowLoginPopup(true)}
            >
              {t('button.login')}
            </Button>
            <Button
              sx={btnStyles}
              variant="outlined"
              onClick={() => setShowRegisterPopup(true)}
            >
              {t('button.register')}
            </Button>
            <LanguageSwitch />
          </List>
        </Collapse>
      </List>
    </Box>
  );

  const _renderAppBarDesktop = () => (
    <Box
      className="appBarDesktop"
      sx={{
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'space-between',
        pl: { sm: '3em', md: '4em', lg: '8em' },
        pr: { sm: '3em', md: '2em' },
        position: 'relative',
        backgroundColor: isShowAppBarDefault ? '#FFFFFF' : 'rgb(33, 37, 41, 1)',
        zIndex: '99',
      }}
    >
      <Box
        onClick={() => Utils.redirect(ROUTERS.HOME)}
        component="img"
        alt="monitoringmatrix"
        src={isShowAppBarDefault ? mainLogoScroll : mainLogo}
        sx={{
          width: { xs: '15%', lg: '14%', xl: '13%' },
          cursor: 'pointer',
        }}
      />
      <Box
        component="ul"
        sx={{
          display: 'flex',
          // gap: 2,
          alignItems: 'center',
          my: '0.5em',
          letterSpacing: '0.15em',
        }}
      >
        {USER_MENU.map((page, index) => (
          <Link
            onClick={() => {
              handleMenuClick(page.label);
            }}
            href={`#${page.label}`}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              fontSize: 'inherit',
            }}
          >
            <Box
              key={index}
              component="li"
              sx={{
                position: 'relative',
                listStyleType: 'none',
                color: isShowAppBarDefault ? '#000000' : 'rgb(255, 255, 255)',
                fontSize: { xs: '12px' },
                fontFamily:
                  'system-ui, -apple-system, Segoe UI, Roboto, Oxygen',
                p: '15px 1vw',
                margin: 0,
                '@media screen and (min-width:1000px)': {
                  fontSize: '14px',
                },
                '&::after': {
                  bottom: 0,
                  content: '""',
                  display: 'block',
                  color: 'rgb(255, 255, 255)',
                  width: 0,
                  height: '2px',
                  left: '30%',
                  position: 'absolute',
                  background: 'rgb(255, 255, 255)',
                  transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
                },
                '&:hover': {
                  color: isShowAppBarDefault ? '#5c7d95' : 'none',
                  '&:after': { width: '100%', left: '0' },
                  cursor: 'pointer',
                },
              }}
            >
              {t(`menu.${page.label}`)}
            </Box>
          </Link>
        ))}
        <Box>
          <Button
            sx={{
              ...btnStyles,
              color: isShowAppBarDefault ? '#000000' : '#FFFFFF',
              backgroundImage:
                'linear-gradient(to left, transparent, transparent 50%, #5c7d95  50%, #5c7d95 )',
            }}
            variant="outlined"
            onClick={() => {
              setShowLoginPopup(true);
            }}
          >
            {t('button.login')}
          </Button>
          <Button
            sx={{
              ...btnStyles,
              color: isShowAppBarDefault ? '#000000' : '#FFFFFF',
              backgroundImage:
                'linear-gradient(to left, transparent, transparent 50%, #5c7d95  50%, #5c7d95 )',
            }}
            variant="outlined"
            onClick={() => {
              setShowRegisterPopup(true);
            }}
          >
            {t('button.register')}
          </Button>
        </Box>
        <LanguageSwitch />
      </Box>
    </Box>
  );

  const renderMain = () => (
    <>
      {_renderAppbarMobile()}
      {_renderAppBarDesktop()}
      {_renderPopupLogin()}
      {_renderPopupRegister()}
    </>
  );
  return renderMain();
};
export default AppBarDesktop;
