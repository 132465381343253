import { sendRequest } from '@/Configs';
import { IDataProductAndServices } from '@/Interfaces/ProductAndServices.interface';
import { API } from '@constants';

const { PRODUCT_AND_SERVICES } = API;

export const getProductsAndServices = async (id: string, params: any) => {
  return await sendRequest(`${PRODUCT_AND_SERVICES.ROOT}${id}`, 'GET', params);
};

export const updateProductsAndServices = async (
  id: string,
  payload: IDataProductAndServices
) => {
  return sendRequest(`${PRODUCT_AND_SERVICES.ROOT}${id}`, 'PUT', payload);
};

export const createProductAndService = async (
  payload: IDataProductAndServices
) => {
  return sendRequest(PRODUCT_AND_SERVICES.ROOT, 'POST', payload);
};
