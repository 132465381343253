import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import LoadingButton from '@mui/lab/LoadingButton';
import { IDataRewards } from '@/Interfaces/Rewards.interface';
import { RewardsActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { TextEditor } from '@/Components/Common';
import DefaultLayout from '@/Components/DefaultLayout';
import { LIST } from '@/Constants';
import { CommonColors } from '@/Themes';
import Media from '@/Components/Dialogs/Media';

const { getRewards, updateRewards } = RewardsActions;

interface isMessage {
  title: string;
}

const DEFAULT_DATA_REWARDS = {
  title: '',
  detailDescription: '',
  content: [{ title: '', image: '', description: '' }],
  languageCode: '',
};

const Rewards = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();

  const [currentLanguage, setCurrentLanguage] = React.useState<string>('en');
  const [dataRewards, setDataRewards] =
    React.useState<IDataRewards>(DEFAULT_DATA_REWARDS);
  const [isOpenMedia, setIsOpenMedia] = React.useState<{
    isOpen: boolean;
    type: string;
    key: any;
  }>({ isOpen: false, type: '', key: '' });

  const [isMessage, setIsMessage] = React.useState<isMessage>({
    title: '',
  });

  const rewards: IDataRewards = useSelector((state: RootState) =>
    _.get(state.REWARDS, 'rewards')
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.REWARDS, 'isActionLoading')
  );

  useEffect(() => {
    if (currentLanguage) dispatch(getRewards(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if (rewards) setDataRewards(rewards);
  }, [rewards]);

  const handleOnChange = (contentKey: string, value: string, key: number) => {
    const newForm = _.map(dataRewards.content, (item: any, index: number) => {
      if (index === key) {
        return {
          ...item,
          [contentKey]: value,
        };
      }
      return item;
    });
    setDataRewards({ ...dataRewards, content: newForm });
  };

  const handleAddMore = () => {
    const newAddmore = [
      ...dataRewards?.content,
      { title: '', image: '', description: '' },
    ];
    setDataRewards({ ...dataRewards, content: newAddmore });
  };

  const handRemove = (key: number) => {
    const newForm = _.filter(
      dataRewards?.content,
      (_item: any, index: number) => index !== key
    );
    setDataRewards({ ...dataRewards, content: newForm });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!_.trim(dataRewards.title)) {
      if (!_.trim(dataRewards.title))
        setIsMessage({
          ...isMessage,
          title: `${t('message.canNotLeaveTheTitleBlank')}`,
        });
    } else dispatch(updateRewards(currentLanguage, dataRewards));
  };

  const _renderHeader = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
          variant="h4"
        >
          {t('title.rewards')}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3} display={'flex'}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 80,
            }}
          >
            {t('label.language')}
          </InputLabel>
          <Select
            value={currentLanguage}
            fullWidth
            size="small"
            onChange={(e: any) => {
              setCurrentLanguage(e.target.value);
              setDataRewards({ ...dataRewards, languageCode: e.target.value });
            }}
            sx={{
              minWidth: '80px',
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
              height: '45px',
            }}
          >
            {LIST.DROPDOWN_LIST.OPTIONS_LANGUAGE.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              my: 1,
            }}
          >
            {t('label.title')}
          </InputLabel>
          <TextField
            value={dataRewards.title || ''}
            size="small"
            label={t('label.title')}
            required
            error={!_.isEmpty(isMessage.title)}
            helperText={isMessage.title}
            fullWidth
            onChange={(e: any) => {
              setDataRewards({ ...dataRewards, title: e.target.value });
              setIsMessage({ ...isMessage, title: '' });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              my: 1,
            }}
          >
            {t('label.mainDescription')}
          </InputLabel>
          <TextField
            value={dataRewards.detailDescription}
            label={t('label.mainDescription')}
            multiline
            required
            rows={2}
            fullWidth
            onChange={(e: any) =>
              setDataRewards({
                ...dataRewards,
                detailDescription: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} ml={2}>
          {_renderContent()}
        </Grid>
      </Grid>
    );
  };

  const _renderContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container mt={1}>
            {dataRewards?.content &&
              _.map(dataRewards?.content, (item, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={`row${dataRewards?.content?.length}-${index}`}
                  >
                    <InputLabel
                      sx={{
                        fontWeight: '500',
                        fontSize: 16,
                        mt: 1,
                      }}
                    >
                      {t('label.content')} {index + 1}
                    </InputLabel>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        border: '1px solid',
                        p: 2,
                        mt: 1,
                        position: 'relative',
                      }}
                    >
                      <Button
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                        color="error"
                        onClick={() => handRemove(index)}
                        disabled={dataRewards?.content?.length <= 1}
                      >
                        <CloseIcon />
                      </Button>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          label={t('label.title')}
                          fullWidth
                          value={item?.title || ''}
                          onChange={(e: any) =>
                            handleOnChange('title', e.target.value, index)
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={item?.image || ''}
                          size="small"
                          label={t('label.imageThirdReward')}
                          fullWidth
                          onChange={(e: any) =>
                            handleOnChange('image', e.target.value, index)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setIsOpenMedia({
                                      isOpen: true,
                                      type: 'image',
                                      key: index,
                                    });
                                  }}
                                >
                                  <CloudUploadIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel
                          sx={{
                            fontWeight: '500',
                            fontSize: 16,
                            mb: 1,
                          }}
                        >
                          {t('label.description')}
                        </InputLabel>
                        <TextEditor
                          value={item?.description || ''}
                          onChange={(e: any) =>
                            handleOnChange('description', e, index)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            <Grid item textAlign={'center'} xs={12}>
              <Button onClick={() => handleAddMore()}>
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderFooterRewards = () => {
    return (
      <Grid container>
        <Grid item mt={2} textAlign={'end'} xs={12}>
          <LoadingButton
            variant="contained"
            color="diSerria"
            sx={{ mr: 2 }}
            loading={isActionLoading}
            onClick={() => setDataRewards(DEFAULT_DATA_REWARDS)}
          >
            {t('button.reset')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            color="oceanGreen"
            loading={isActionLoading}
          >
            {t('button.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2}>
        {_renderHeader()}
        <Grid item xs={12} mt={2} component="form" onSubmit={handleSubmit}>
          {_renderBody()}
          {_renderFooterRewards()}
        </Grid>
        {isOpenMedia.isOpen && (
          <Media
            onOpen={{
              status: isOpenMedia.isOpen,
              type: isOpenMedia.type,
              key: isOpenMedia.key,
            }}
            onClose={() => {
              setIsOpenMedia({ isOpen: false, type: '', key: '' });
            }}
            callback={(file: any, type: string, key: any) => {
              if (type) {
                handleOnChange(type, file, key);
                setIsOpenMedia({ isOpen: false, type: '', key: '' });
              }
            }}
          />
        )}
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default Rewards;
