import { SxProps, Theme } from '@mui/system';

export const containerStyle: SxProps<Theme> = {
  width: '100%',
  px: 'var(--bs-gutter-x,.75rem)',
  py: '6%',
  mx: 'auto',
  textAlign: 'center',
  maxWidth: {
    xs: 576,
    sm: 720,
    md: 960,
    lg: 1200,
    xl: 1320,
  },
};

export const sectionTitleStyle: SxProps<Theme> = {
  fontWeight: 700,
  textAlign: 'center',
  fontFamily: '"Poppins", sans-serif',
  '@media screen and (max-width:769px)': {
    fontSize: 26,
  },
  '@media only screen and (min-width:769px)': {
    fontSize: 32,
  },
};
// Hero
export const heroBtnStyle: SxProps<Theme> = {
  mx: 'auto',
  mt: '5%',
  borderRadius: '50px',
  backgroundColor: '#e4804a',
  color: '#FFFFFF',
  fontSize: '20px',
  fontFamily: '"Roboto", sans-serif',
  textTransform: 'none',
  transition: '0.5s',
  width: { xs: '70%', sm: '90%', lg: '60%', xl: '30%' },
  '.hover': {
    backgroundColor: '#21b6ae',
  },
  '&.MuiButtonBase-root:hover': {
    backgroundColor: '#5c7d95',
  },
};
// rewards
export const cardTextStyle: SxProps<Theme> = {
  fontSize: 17,
  lineHeight: '1.3',
  fontWeight: 'bold',
  textAlign: 'center',
  fontFamily: '"Poppins", sans-serif',
};
export const descriptionStyle: SxProps<Theme> = {
  fontSize: 17,
  fontFamily: '"Poppins", sans-serif',
  color: '#212529',
  textAlign: 'justify',
  mt: '20px',
  textAlignLast: 'center',
};
export const flipCardStyle: SxProps<Theme> = {
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
};
export const flipCardFrontText: SxProps<Theme> = {
  pt: 16,
  fontSize: '1.25rem',
  fontFamily: '"Poppins", sans-serif',
};

// Products
export const buttonProductsStyle: SxProps<Theme> = {
  textAlign: 'center',
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: 20,
  fontFamily:
    'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  '&:hover': {
    color: '#f26f26',
  },
};
