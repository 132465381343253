import React from 'react';
import { Navigate } from 'react-router';
import jwt_decode from 'jwt-decode';

import Utils from '@/Utils';
import { ROUTERS } from '@/Constants';

interface ISectionProps {
  children: JSX.Element;
  location: string;
}

const ProtectedRoute: React.FC<ISectionProps> = ({ children }) => {
  const token = Utils.getSavedToken();
  let isValidToken = true;

  if (token) {
    const decodedToken: any = jwt_decode(token);
    const dateNow = new Date();
    if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000))
      isValidToken = false;
  } else isValidToken = false;

  if (!isValidToken) {
    Utils.clearAllSavedData();
    return <Navigate to={`${ROUTERS.ADMIN}`} replace />;
  }

  return children;
};

export default ProtectedRoute;
