import { SxProps, Theme } from '@mui/system';
import { CSSObject, styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { CommonColors } from '@/Themes';
const sidebarWidth = 300;

export const openedMixin = (theme: any): CSSObject => ({
  width: sidebarWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme: any): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: sidebarWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  color: 'white',
  background: CommonColors.bismark,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const sideBarStyles: SxProps<Theme> = {
  overflowY: 'auto',
  overflowX: 'hidden',
  height: `calc(100vh - 100px)`,
};

export const logoStyles: SxProps<Theme> = {
  cursor: 'pointer',
  width: 1,
  height: 1,
};
