import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import { Dropdown } from '../Common';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DialogLayout from './DialogLayout';
import signinImage from '@/Assets/signin_image.svg';

interface ISectionProps {
  open: boolean;
  onClose(): void;
  openRegisterForm(): void;
}
interface ILoginPayload {
  country_id: '1' | '2';
  email: string;
  password: string;
}

const initialPayload: ILoginPayload = {
  country_id: '1',
  email: '',
  password: '',
};

const LoginUserPopup: React.FC<ISectionProps> = ({
  open,
  onClose,
  openRegisterForm,
}) => {
  const { t } = useTranslation('translation');

  const { control, reset } = useForm({
    defaultValues: initialPayload,
  });

  const _renderForm = () => ( 
    <Box
      className="signinForm"
      component="form"
      action="https://user.idrapoll.com/scripts/authentication.php"
      method="POST"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        m: '0 auto',
        justifyContent: 'center',
        width: '60%',
      }}
    >
      <InputLabel sx={{ textAlign: 'center' }}>{t('label.country')}</InputLabel>
      <Controller
        name="country_id"
        control={control}
        render={({ field }) => (
          <Dropdown
            sx={{ mb: '6%' }}
            defaultValue="1"
            value={field.value}
            onChange={(e: any) => field.onChange(e)}
            options={[
              { label: t('label.albania'), value: '1' },
              { label: t('label.kosovo'), value: '2' },
            ]}
          />
        )}
      />
      <InputLabel sx={{ textAlign: 'center' }}>
        {t('label.email')} <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            name="email"
            type="email"
            placeholder={t('placeholder.email')}
            value={field.value}
            onChange={(e: any) => field.onChange(e)}
            size="small"
            sx={{ mb: '6%' }}
            required
          />
        )}
      />
      <InputLabel sx={{ textAlign: 'center' }}>
        {t('label.password')} <span style={{ color: 'red' }}>*</span>
      </InputLabel>
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            name="password"
            type="password"
            placeholder={t('placeholder.password')}
            value={field.value}
            onChange={(e: any) => field.onChange(e)}
            size="small"
            sx={{ mb: '6%' }}
            required
          />
        )}
      />
      <Button
        type="submit"
        variant="outlined"
        sx={{
          maxWidth: '50%',
          mb: '6%',
          mx: 'auto',
          color: 'white',
          backgroundColor: '#F26F26',
          border: '1px solid #F26F26',
          // backgroundColor: 'transparent',
          '&.MuiButtonBase-root:hover': {
            backgroundColor: '#5C7D95',
            border: '1px solid rgba(0, 0, 0, 0.705)',
          },
        }}
      >
        {t('button.login')}
      </Button>
      <Typography textAlign="center">
        {t('label.notAMemberYet')}{' '}
        <span
          onClick={() => {
            openRegisterForm();
            reset();
          }}
          style={{ cursor: 'pointer', color: '#F26F26' }}
        >
          {t('button.register')}
        </span>
      </Typography>
    </Box>
  );

  const _renderContent = () => (
    <Stack
      className="container"
      sx={{ width: { xs: '55vw', md: '400px' }, pb: 1 }}
    >
      <Box sx={{ textAlign: 'right' }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
            reset();
          }}
        >
          <DisabledByDefaultIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{ mb: '7%', width: '100%' }}>
        <Box component="img" src={signinImage} />
      </Box>
      {_renderForm()}
    </Stack>
  );

  const renderMain = () => <>{_renderContent()}</>;

  return (
    <DialogLayout
      title=""
      content={renderMain()}
      open={open}
      onClose={onClose}
    />
  );
};

export default LoginUserPopup;
