import logo from '../Assets/logo.svg';
import albanian from '../Assets/albanian.png';
import english from '../Assets/english.png';
import MoustageBeardOutline from '../Assets/701-moustage-beard-outline.svg';
import womanStyleOutline from '../Assets/720-woman-style-28-outline.svg';
import avatar2 from '../Assets/avatar2.svg';
import b2b from '../Assets/b2b.jpg';
import checkList from '../Assets/Checklist.gif';
import oldBackground from '../Assets/black-background.jpg';
import project from '../Assets/project.svg';
import register from '../Assets/pankarta.jpg';
import webst from '../Assets/webst.svg';
import resultImage from '../Assets/result_image.jfif';
import pdfFile from '../Assets/pdf-2608.png';

const images = {
  logo,
  albanian,
  english,
  resultImage,
  MoustageBeardOutline,
  womanStyleOutline,
  avatar2,
  b2b,
  checkList,
  oldBackground,
  project,
  register,
  webst,
  pdfFile
};

export default images;
