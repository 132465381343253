import React, { useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import {
  Avatar,
  Container,
  Typography,
  Box,
  Grid,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  Card,
  CardContent,
  CardActions,
  CardHeader,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoginIcon from '@mui/icons-material/Login';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import { AuthActions } from '@actions';
import { useTypedDispatch, RootState } from '@/store';
import Utils from '@/Utils';

const { login } = AuthActions;

const SignIn: React.FC = () => {
  // Constructors
  const dispatch = useTypedDispatch();
  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isActionLoading')
  );
  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
    isRemember: false,
  });

  // Events
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(login(loginForm));
  };

  const _renderCopyRight = () => (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.idracompany.com/" target="_blank">
        IDRA
      </Link>{' '}
      {dayjs().year()}
      {'.'}
    </Typography>
  );

  const _renderInputs = () => (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        disabled={isActionLoading}
        onChange={(e) =>
          setLoginForm({ ...loginForm, username: e.target.value })
        }
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        disabled={isActionLoading}
        onChange={(e) =>
          setLoginForm({ ...loginForm, password: e.target.value })
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            value="remember"
            onChange={(e) => {
              setLoginForm({ ...loginForm, isRemember: e.target.checked });
              Utils.saveIsRemember(e.target.checked);
            }}
          />
        }
        disabled={isActionLoading}
        label="Remember me"
      />
      <LoadingButton
        type="submit"
        startIcon={<LoginIcon />}
        fullWidth
        variant="contained"
        sx={{ my: 2 }}
        loading={isActionLoading}
      >
        Sign In
      </LoadingButton>
      <Grid container>
        <Grid item xs>
          {/* <Link href={ROUTERS.FORGOT_PASSWORD} variant="body2">
            Forgot password?
          </Link> */}
        </Grid>
      </Grid>
    </Box>
  );

  const renderMain = () => (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={CommonColors.mainBackground}
    >
      <Grid item>
        <Container maxWidth="xs">
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: CommonColors.fuzzyWuzzyBrown }}
                  aria-label="recipe"
                >
                  <LockOutlinedIcon />
                </Avatar>
              }
              title={<Typography variant="h5">Welcome to Project</Typography>}
              subheader="Sign In"
            />
            <CardContent>{_renderInputs()}</CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              {_renderCopyRight()}
            </CardActions>
          </Card>
        </Container>
      </Grid>
    </Grid>
  );

  return <DefaultLayout content={renderMain()} />;
};

export default SignIn;
