import * as React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Images } from '@/Themes';

import Utils from '@/Utils';
import { RegisterUserPopup } from '@/Components/Dialogs';
import { LoginUserPopup } from '@/Components/Dialogs';
import { Stack, Box, Button, Typography } from '@mui/material';
import { heroBtnStyle } from './home.style';

const HeroContainer: React.FC = () => {
  const { t } = useTranslation();

  const [isShowRegisterPopup, setIsShowRegisterPopup] =
    React.useState<boolean>(false);
  const [isShowLoginPopup, setIsShowLoginPopup] =
    React.useState<boolean>(false);
  const currentLanguage = Utils.getSavedLanguage();

  React.useEffect(() => {
    i18next.changeLanguage(currentLanguage);
  }, []);

  const _renderRegisterPopup = () => (
    <RegisterUserPopup
      open={isShowRegisterPopup}
      onClose={() => setIsShowRegisterPopup(false)}
      openLoginForm={() => {
        setIsShowRegisterPopup(false);
        setIsShowLoginPopup(true);
      }}
    />
  );

  const _renderLoginPopup = () => (
    <LoginUserPopup
      open={isShowLoginPopup}
      onClose={() => {
        setIsShowLoginPopup(false);
      }}
      openRegisterForm={() => {
        setIsShowLoginPopup(false);
        setIsShowRegisterPopup(true);
      }}
    />
  );

  const _renderTypeAnimation = () => {
    const text1 =
      currentLanguage === 'en'
        ? 'We are a community that gives voice to our members.'
        : 'Ne jemi një komunitet që i japim zë anëta.';
    const text2 =
      currentLanguage === 'en'
        ? 'Join us and win rewards for your opinion.'
        : 'Bashkohuni me ne dhe fitoni shpërblime për opinionet tuaja.';

    return (
      <TypeAnimation
        className="textWriting"
        sequence={[
          ` ${text1}`,
          2000, // Waits 1s
          `${text2}`,
          2000, // Waits 2s
        ]}
        wrapper="span"
        cursor={true}
        repeat={Infinity}
        style={{
          display: 'inline-block',
          fontFamily: 'inherit',
          textAlign: 'center',
          color: '#212529',
        }}
      />
    );
  };

  const _renderHeroSection = () => (
    <Box
      component="section"
      className="hero"
      sx={{
        pt: '5%',
        mt: { xs: '20vw', sm: '5vw' },
      }}
    >
      <Stack
        className="section-container"
        sx={{
          flexDirection: 'row',
          width: '100%',
          px: 'var(--bs-gutter-x,.75rem)',
          mx: 'auto',
          maxWidth: {
            xs: '576px',
            sm: '720px',
            md: '960px',
            lg: '1200px',
            xl: '1320px',
          },
          '@media screen and (max-width: 577px)': {
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            mx: 'auto',
          },
        }}
      >
        <Stack
          className="heroRegister"
          direction="column"
          flex={1}
          sx={{
            maxWidth: { xs: '100%', sm: '50%' },
            justifyContent: 'center',
            // '&.index-module_type__E-SaG.textWriting': {
            //   fontSize: { xs: '3vw', sm: '2vw', md: '1.5vw' },
            // },
            // // '.textWriting': {
            // //   fontSize: { xs: '3vw', sm: '2vw', md: '1.5vw' },
            // // },
            '& > .textWriting': {
              fontSize: { xs: '3vw', sm: '2vw', md: '1.5vw' },
            },
          }}
        >
          <Typography
            variant="h2"
            className="heroTitle"
            sx={{
              fontSize: { xs: '6vw', md: '4vw' },
              textAlign: 'center',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 400,
              color: '#212529',
            }}
          >
            {t('title.heroTitle')}
            <span style={{ color: '#E4804A' }}>
              {' '}
              {t('title.subHeroTitle')}!
            </span>
          </Typography>
          {_renderTypeAnimation()}
          <Button
            className="heroButton"
            onClick={() => {
              setIsShowRegisterPopup(true);
            }}
            sx={{
              ...heroBtnStyle,
            }}
          >
            <Typography
              sx={{
                display: 'block',
                margin: 0,
                padding: '6 0',
                fontFamily: 'inherit',
                fontSize: 'inherit',
              }}
            >
              {t('button.register')}
            </Typography>
          </Button>
        </Stack>

        <Box
          className="heroImg"
          sx={{
            display: 'flex',
            paddingRight: 'calc(1.5rem * 0.5)',
            paddingLeft: 'calc(1.5rem * 0.5)',
            maxWidth: { xs: '100%', sm: '50%' },
          }}
        >
          <Box
            component="img"
            flex={1}
            alt="hero image idra poll"
            src={Images.checkList}
            sx={{
              width: { xs: '336px', md: '456px', lg: '546px', xl: '636px' },
            }}
          />
        </Box>
      </Stack>
    </Box>
  );

  const renderMain = () => (
    <>
      {_renderHeroSection()}
      {_renderRegisterPopup()}
      {_renderLoginPopup()}
    </>
  );

  return renderMain();
};
export default HeroContainer;
