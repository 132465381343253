export default {
  //Side menu
  dashboard: 'DASHBOARD',
  systemUser: 'SYSTEM USER',
  about: 'ABOUT',
  logout: 'LOGOUT',
  inbox: 'INBOX',
  contact: 'CONTACT',
  upload: 'UPLOAD',
  whatIsIDRAPoll: 'What is IDRApoll',
  productsAndServices: 'Products and services',
  whyIDRApoll: 'Why IDRApoll',
  statistical: 'Statistical',
  resultsFromIDRApoll: 'Results from IDRApoll',
  customers: 'Customers',

  //Appbar menu
  whatIsIDRApoll: 'What is IDRApoll?',
  rewards: 'Rewards',
  results: 'Results',
  forClients: 'For Clients',
  Unsubscribe: 'Unsubscribe',
};
