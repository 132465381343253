import { SxProps, Theme } from '@mui/system';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { CommonColors } from '@/Themes';

export const appBarStyles: SxProps<Theme> = {
  background: 'white',
  color: CommonColors.shark,
  boxShadow: 1,
};

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const LiStyle: SxProps = {
  display: 'inline-block',
};

export const btnStyles: SxProps<Theme> = {
  mx: '2px',
  border: 'solid 1px',
  background: 'transparent',
  backgroundImage:
    'linear-gradient(to left, transparent, transparent 50%, #ffffff 50%, #ffffff)',
  backgroundPosition: '100% 0',
  backgroundSize: '200% 100%',
  fontFamily: 'Poppins',
  borderRadius: '5px',
  color: 'rgb(255, 255, 255)',
  padding: '8px 15px',
  textAlign: 'center',
  fontSize: '12.5px',
  margin: '8px 2px',
  opacity: '0.7',
  transition: '0.5s',
  textTransform: 'none',
  '&:hover': {
    color: 'black',
    opacity: 5,
    backgroundPosition: '0 0',
    border: '1px solid white',
  },
  '@media screen and (max-width:900px)': {
    my: '5px',
    color: 'white',
    border: '1px solid white',
    // transition: 'all .25s ease-in',
    transition: '0.5s',
    background: 'transparent',
    backgroundImage:
      'linear-gradient(to left, transparent, transparent 50%, #ffffff 50%, #ffffff)',
    backgroundPosition: '100% 0',
    backgroundSize: '200% 100%',
    '&:hover': {
      border: '1px solid white',
      color: '#000000',
      opacity: '5',
      backgroundPosition: '0 0',
    },
  },
};
