import React from 'react';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import {
  Button,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { CustomersTable } from '@/Components/LayoutPart/DataTable';
import CustomersDetail from '@/Components/Dialogs/CustomersDetail';
import { useTranslation } from 'react-i18next';
import { ICustomersData, ICustommers } from '@/Interfaces/Customers.interface';
import { CustommersActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { DEFAULT_CONFIRM } from '@/Constants/ConfirmDialog';
import { Confirm } from '@/Components/Dialogs';

const { fetchCustommers, deleteCustommers } = CustommersActions;

interface IFilter {
  page: number;
  limit: number;
  name: string;
}

const DEFAULT_PAGINATION: IFilter = {
  page: 1,
  limit: 10,
  name: '',
};

const Customers = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [openCreate, setOpenCreate] = React.useState<boolean>(false);
  const [dataDetail, setDataDetail] = React.useState<any>();
  const [filter, setFilter] = React.useState<IFilter>(DEFAULT_PAGINATION);
  const [customersDetail, setCustommerDetail] = React.useState<any>({});
  const [type, setType] = React.useState('create');
  const [confirmDialog, setConfirmDialog] =
    React.useState<IConfirmStructure>(DEFAULT_CONFIRM);

  const payload: ICustommers = useSelector((state: RootState) =>
    _.get(state.CUSTOMMERS, 'custommers')
  );
  const isCreateSuccess: ICustommers = useSelector((state: RootState) =>
    _.get(state.CUSTOMMERS, 'isCreateSuccess')
  );

  React.useEffect(() => {
    setFilter({ ...filter, page: payload.currentPage });
  }, [payload]);

  React.useEffect(() => {
    if (isCreateSuccess) setOpenCreate(false);
  }, [isCreateSuccess]);

  const handleSubmitChangeStatus = (state: { id?: string; type?: string }) => {
    const { id, type } = state;
    if (id && type === 'delete' && dataDetail)
      dispatch(
        deleteCustommers(dataDetail.id, {
          page: customersDetail.currentPage,
          limit: 10,
        })
      );
    setConfirmDialog(DEFAULT_CONFIRM);
  };

  const onRowAction = (data: ICustomersData, action: 'detail' | 'delete') => {
    if (action === 'detail') {
      setCustommerDetail(data);
      setOpenCreate(true);
      setType('detail');
    }
    if (action === 'delete') {
      setDataDetail(data);
      const description = `${t('message.areYouSureYouWantToDeleteCustomer')}`;
      setConfirmDialog({
        isOpen: true,
        message: description,
        state: {
          id: data.id,
          type: action,
        },
      });
    }
  };

  const onCancel = () => {
    setConfirmDialog(DEFAULT_CONFIRM);
    dispatch(fetchCustommers(DEFAULT_PAGINATION));
  };

  const _renderHeader = () => {
    return (
      <Grid container mb={1}>
        <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
          <Typography
            sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
            variant="h4"
          >
            {t('title.customers')}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setOpenCreate(true);
              setType('create');
            }}
          >
            {t('button.createCustomers')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4} display={'flex'}>
          <InputLabel sx={{ minWidth: '14vh', mt: 1 }}>
            {t('label.searchBy')}
          </InputLabel>
          <TextField
            value={filter?.name}
            size="small"
            label="Title"
            fullWidth
            onChange={(e) => setFilter({ ...filter, name: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              mr: 1,
              background: CommonColors.bismark,
              '&:hover': {
                background: '#486270',
              },
            }}
            onClick={() => dispatch(fetchCustommers({ ...filter, page: 1 }))}
          >
            {t('button.filter')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: '1px solid #28384580',
              color: CommonColors.gratou,
              '&:hover': {
                border: '1px solid #1e2a3480',
              },
            }}
            onClick={() => {
              setFilter({ ...filter, name: '' });
              dispatch(fetchCustommers({ page: 1, limit: 10 }));
            }}
          >
            {t('button.reset')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CustomersTable onRowAction={onRowAction} />
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2} spacing={2}>
        <Grid item xs={12}>
          {_renderHeader()}
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {_renderBody()}
        </Grid>
        {openCreate && (
          <CustomersDetail
            pagination={filter}
            type={type}
            open={openCreate}
            onClose={async () => {
              setOpenCreate(false);
              setCustommerDetail({});
              await dispatch(
                fetchCustommers({
                  page: customersDetail.currentPage,
                  limit: 10,
                })
              );
            }}
            data={customersDetail}
          />
        )}
        <Confirm
          confirm={confirmDialog}
          onCancel={() => onCancel()}
          callback={() => handleSubmitChangeStatus(confirmDialog.state)}
        />
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default Customers;
