import { sendRequest } from '@/Configs';
import { API } from '@/Constants';

const { UPLOAD } = API;

export const fetchGalleries = async () => {
  return sendRequest(UPLOAD.ROOT, 'GET');
};

export const getGalleryById = async (id: string) => {
  return sendRequest(`${UPLOAD.ROOT}${id}`, 'GET');
};

export const createGallery = async (payload: FormData) => {
  return sendRequest(`${UPLOAD.ROOT}`, 'FORM_DATA', payload);
};

export const deleteGallery = async (payload: any[]) => {
  return sendRequest(UPLOAD.DELETE, 'POST', payload);
};
