import * as React from 'react';
import i18next from 'i18next';
import { Avatar, MenuItem, Select, Stack } from '@mui/material';
import { getSavedLanguage, saveLanguage } from '@/Utils/Cookie.utils';
import { Images } from '@/Themes';

const options = [
  {
    label: 'English',
    value: 'en',
    startEl: <Avatar src={Images.english} />,
  },
  {
    label: 'Shqip',
    value: 'sq',
    startEl: <Avatar src={Images.albanian} />,
  },
];

const LanguageSwitch: React.FC = () => {
  const [currentLanguage, setCurrentLanguage] = React.useState<string>('sq');

  React.useEffect(() => {
    const localeStorage = getSavedLanguage();
    setCurrentLanguage(localeStorage);
    i18next.changeLanguage(localeStorage);
  }, []);

  const handleChangeLanguage = async (language: 'sq' | 'en') => {
    setCurrentLanguage(language);
    saveLanguage(language);
    i18next.changeLanguage(language);
  };

  return (
    <Stack direction="row">
      <Select
        value={currentLanguage}
        onChange={(e: any) => handleChangeLanguage(e.target.value)}
        sx={{
          minWidth: '80px',
          '	.MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
          height: '45px',
        }}
      >
        {options.map((item, index) => {
          const { startEl } = item;
          let resolveStartEl = null;
          if (startEl)
            resolveStartEl = React.cloneElement(startEl, {
              style: {
                width: '26px',
                height: '26px',
                marginRight: '8px',
              },
            });
          return (
            <MenuItem key={index} value={item.value} sx={{ display: 'flex' }}>
              {resolveStartEl}
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
};

export default LanguageSwitch;
