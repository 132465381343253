import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import ProductsAndServicesAction from '@/Actions/ProductsAndServices.action';
import { IDataProductAndServices } from '@/Interfaces/ProductAndServices.interface';
import { Box, Stack, Typography, Grid, Button } from '@mui/material';
import { buttonProductsStyle, sectionTitleStyle } from './home.style';
import productImage from '@/Assets/analysis-animate.8d0d9dcc.svg';
import { Loading } from '@/Components/Common';

const { getProductAndService, clearProductAndService } =
  ProductsAndServicesAction;

const DEFAULT_DATA: IDataProductAndServices = {
  research: 'market research',
  content: [],
  languageCode: 'en',
};

const ProductsSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const currentLanguage = Utils.getSavedLanguage();
  const payload: IDataProductAndServices = useSelector((state: RootState) =>
    _.get(state.PRODUCT_AND_SERVICES, 'productAndServices')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.PRODUCT_AND_SERVICES, 'isGetLoading')
  );
  const [showData, setShowData] = React.useState<string>('market research');
  const [marketResearchData, setMarketResearchData] =
    React.useState<IDataProductAndServices>(DEFAULT_DATA);
  const [opinionResearchData, setOpinionResearchData] =
    React.useState<IDataProductAndServices>(DEFAULT_DATA);

  React.useEffect(() => {
    dispatch(
      getProductAndService(currentLanguage, { research: 'opinion research' })
    );
    dispatch(
      getProductAndService(currentLanguage, { research: 'market research' })
    );
    return () => {
      dispatch(clearProductAndService());
    };
  }, [currentLanguage]);

  React.useEffect(() => {
    if (!_.isEmpty(payload) && payload.research === 'market research')
      setMarketResearchData(payload);

    if (!_.isEmpty(payload) && payload.research === 'opinion research')
      setOpinionResearchData(payload);
  }, [payload]);

  const _renderMarketContent = () => {
    const render = marketResearchData.content.map(
      (items: any, index: number) => (
        <Grid key={index} item xs={12} sm={6} md={4} display="flex" p="16px">
          <Box sx={{ mr: '4px' }}>
            <Box component="img" src={items.image} width={50} height={50} />
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: 'left',
                fontWeight: 800,
                fontSize: '20px',
                fontFamily:
                  'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                mt: '1%',
                mb: '1rem',
                width: '70%',
              }}
            >
              {items.title}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontFamily:
                  'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                fontSize: '1rem',
                mb: '1rem',
                maxWidth: '100%',
              }}
            >
              {items.description}
            </Typography>
          </Box>
        </Grid>
      )
    );
    return render;
  };

  const _renderOpinionContent = () => {
    const render = opinionResearchData.content.map(
      (items: any, index: number) => (
        <Grid key={index} item xs={12} sm={6} md={4} display="flex" p="16px">
          <Box sx={{ mr: '4px' }}>
            <Box component="img" src={items.image} width={50} height={50} />
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: 'left',
                fontWeight: 800,
                fontSize: '20px',
                fontFamily:
                  'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                mt: '1%',
                mb: '1rem',
                width: '70%',
              }}
            >
              {items.title}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontFamily:
                  'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                fontSize: '1rem',
                mb: '1rem',
              }}
            >
              {items.description}
            </Typography>
          </Box>
        </Grid>
      )
    );
    return render;
  };

  return (
    <Box component="section" className="products">
      <Stack direction="row" className="products-container" sx={{ py: '6%' }}>
        <Box
          flex={1}
          className="products-image"
          sx={{
            '@media screen and (max-width:992px)': {
              display: 'none',
            },
          }}
        >
          <Box
            component="img"
            src={productImage}
            sx={{
              width: '90%',
              height: 'auto',
              mt: { md: '60%', lg: '65%' },
            }}
          />
        </Box>
        <Stack
          flex={1}
          sx={{
            '@media screen and (min-width: 992px)': {
              flex: '0 0 auto',
              width: '66.66666667%',
            },
          }}
        >
          <Typography
            className="products-title"
            variant="h2"
            sx={{
              ...sectionTitleStyle,
              p: '0 10px 0',
              fontSize: 'calc(1.325rem + .9vw) !important',
              color: '#FFFFFF',
              fontWeight: 'bold',
            }}
          >
            {t('title.productsAndServices')}
          </Typography>
          <Stack className="products-content" sx={{ mt: '8%' }}>
            <Box
              className="products-image"
              sx={{
                m: 'auto',
                p: '0 5% 5%',
                width: '90%',
                height: 'auto',
                objectFit: 'cover',
                '@media screen and (min-width:992px)': {
                  display: 'none',
                },
              }}
            >
              <Box
                component="img"
                src={productImage}
                alt="gif"
                sx={{
                  width: '90%',
                  height: 'auto',
                }}
              />
            </Box>
            <Box
              sx={{
                color: '#ffffff',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                className="marketButton"
                onClick={() => setShowData('market research')}
                sx={{ width: '50%' }}
              >
                <Typography
                  sx={{
                    ...buttonProductsStyle,
                    color:
                      showData === 'market research' ? '#f26f26' : '#ffffff',
                    borderBottom:
                      showData === 'market research'
                        ? '3px solid #f26f26'
                        : 'none',
                    pb: '5px',
                  }}
                >
                  {t('title.marketResearch')}
                </Typography>
              </Button>
              <Button
                className="opinionButton"
                onClick={() => setShowData('opinion research')}
                sx={{ width: '50%' }}
              >
                <Typography
                  sx={{
                    ...buttonProductsStyle,
                    color:
                      showData !== 'opinion research' ? '#ffffff' : '#f26f26',
                    borderBottom:
                      showData === 'opinion research'
                        ? '3px solid #f26f26'
                        : 'none',
                    pb: '5px',
                  }}
                >
                  {t('title.opinionResearch')}
                </Typography>
              </Button>
            </Box>
            <hr
              style={{
                width: '100%',
                fontWeight: 'bold',
                color: 'white',
                marginLeft: '10px',
                margin: '3px 0 1rem',
                // border: 0,
                opacity: 0.25,
              }}
            ></hr>

            <Box
              className="list"
              sx={{
                color: '#ffffff',
                pr: '60px',
                pl: '25px',
                mt: '3rem',
                '@media screen and (min-width: 1375px)': {
                  pr: '30px',
                  pl: '25px',
                },
              }}
            >
              <Grid
                className="marketResearch"
                container
                spacing={2}
                display={showData === 'market research' ? 'true' : 'none'}
                width="100%"
              >
                {isLoading ? (
                  <Loading containerSx={{ width: '100%' }} />
                ) : (
                  _renderMarketContent()
                )}
              </Grid>

              <Grid
                className="opinionResearch"
                container
                spacing={2}
                display={showData === 'opinion research' ? 'true' : 'none'}
              >
                {isLoading ? (
                  <Loading containerSx={{ width: '100%' }} />
                ) : (
                  <> {_renderOpinionContent()}</>
                )}
              </Grid>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ProductsSection;
