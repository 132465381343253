import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { tableContainerStyles, tableFooterStyles } from './DataTable.styles';
import _ from 'lodash';
import { TABLES } from '@/Constants';
import { useTranslation } from 'react-i18next';
import { PaginationActions } from '@/Components/Common';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { IDataContact } from '@/Interfaces/Contact.interface';
import { ContactActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';
import { useSelector } from 'react-redux';

const { fetchContact } = ContactActions;

const { CONTACT_TABLE_HEADER } = TABLES;

interface IContact {
  data: IDataContact[];
  onRowAction(items: IDataContact, action: 'detail'): void;
}

const ContactTable: React.FC<IContact> = ({ data, onRowAction }) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');
  const colSpan = CONTACT_TABLE_HEADER.length + 5;
  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.CONTACT, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.CONTACT, 'pagination')
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    type?: string
  ) => {
    let page = pagination.page;
    if (type === 'lastPage') page = newPage;
    else {
      if (newPage === 0) page = 1;
      else if (newPage < page) page -= 1;
      else if (newPage >= page) page += 1;
    }
    dispatch(fetchContact({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchContact({ page: 1, limit: perPage }));
  };

  const _renderTableHeader = () => {
    return (
      <TableRow>
        <TableCell sx={{ maxWidth: 50 }}>{t('table.no')}</TableCell>
        {_.map(CONTACT_TABLE_HEADER, (item) => (
          <TableCell
            key={`head-${item.value}`}
            sx={{ fontWeight: '700', minWidth: 250 }}
          >
            {t(`table.${item?.value}`)}
          </TableCell>
        ))}
        <TableCell sx={{ maxWidth: 100, textAlign: 'center' }}>
          {t('table.action')}
        </TableCell>
      </TableRow>
    );
  };

  const _renderTableBody = () => {
    if (_.isEmpty(data))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 1}>
            There is no contact to display
          </TableCell>
        </TableRow>
      );
    return _.map(data, (item: any, index: number) => {
      return (
        <TableRow hover key={index}>
          <TableCell>
            {index + 1 + (meta.currentPage - 1) * pagination.limit}
          </TableCell>
          <TableCell>{item.email}</TableCell>
          <TableCell>
            <Typography
              sx={{
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                textOverflow: 'ellipsis',
              }}
            >
              {item.content}
            </Typography>
          </TableCell>
          <TableCell sx={{ textAlign: 'center' }}>
            <Tooltip title={t('tooltip.detail')}>
              <IconButton onClick={() => onRowAction(item, 'detail')}>
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10]}
            colSpan={colSpan + 6}
            count={meta?.totalItems || 0}
            rowsPerPage={meta?.itemsPerPage || 0}
            page={meta?.currentPage - 1 || 0}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader()}</TableHead>
          <TableBody>{_renderTableBody()}</TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default ContactTable;
