import { createTheme, PaletteColorOptions } from '@mui/material/styles';
import CommonColor from './CommonColor';

declare module '@mui/material/styles' {
  interface CustomPalette {
    burntSienna: PaletteColorOptions;
    bismark: PaletteColorOptions;
    pickledBluewood: PaletteColorOptions;
    loblolly: PaletteColorOptions;
    oceanGreen: PaletteColorOptions;
    fuzzyWuzzyBrown: PaletteColorOptions;
    diSerria: PaletteColorOptions;
    mystic: PaletteColorOptions;
    astronaut: PaletteColorOptions;
    hippieBlue: PaletteColorOptions;
    perano: PaletteColorOptions;
    lemonGrass: PaletteColorOptions;
    shark: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    burntSienna: true;
    bismark: true;
    pickledBluewood: true;
    loblolly: true;
    oceanGreen: true;
    fuzzyWuzzyBrown: true;
    diSerria: true;
    mystic: true;
    astronaut: true;
    hippieBlue: true;
    perano: true;
    lemonGrass: true;
    shark: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor, contrastText: '#fff' } });

const CoreTheme = createTheme({
  palette: {
    burntSienna: createColor(CommonColor.burntSienna),
    bismark: createColor(CommonColor.bismark),
    pickledBluewood: createColor(CommonColor.pickledBluewood),
    loblolly: createColor(CommonColor.loblolly),
    oceanGreen: createColor(CommonColor.oceanGreen),
    fuzzyWuzzyBrown: createColor(CommonColor.fuzzyWuzzyBrown),
    diSerria: createColor(CommonColor.diSerria),
    mystic: createColor(CommonColor.mystic),
    astronaut: createColor(CommonColor.astronaut),
    hippieBlue: createColor(CommonColor.hippieBlue),
    perano: createColor(CommonColor.perano),
    lemonGrass: createColor(CommonColor.lemonGrass),
    shark: createColor(CommonColor.shark),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            height: 5,
            width: 5,
            backgroundColor: CommonColor.loblolly,
            cursor: 'pointer',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: CommonColor.mainColor,
            outline: 'none',
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2) !important',
          },
          //remove arrow input type number
          'input::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          'input[type=number]': {
            MozAppearance: 'textfield',
          },
          //hidden wordcount
          '.tox-statusbar__wordcount': {
            visibility: 'hidden',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: CommonColor.burntSienna,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            backgroundColor: CommonColor.burntSienna,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: CommonColor.burntSienna,
          },
          '&.MuiCheckbox-indeterminate': {
            color: CommonColor.bismark,
          },
        },
      },
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          '& .MuiStepLabel-root .Mui-completed': {
            color: CommonColor.oceanGreen,
          },
          '& .MuiStepLabel-root .Mui-active': {
            color: CommonColor.mainColor,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          transition: '.4s transform linear, .2s box-shadow linear',
          ':hover': {
            boxShadow: '0px 8px 14px -4px rgba(0,0,0,0.2)',
            transform: 'translate(0px, -3px)',
          },
        },
      },
    },
    // MuiDialog: {
    //   styleOverrides: {
    //     root: {
    //       zIndex: 10000, //header
    //     },
    //   },
    // },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid transparent',
          '&:hover': {
            outline: 'none',
            border: '1px solid black',
          },
        },
      },
    },
  },
});
export default CoreTheme;
