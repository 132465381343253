import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IWhatIDRApollData } from '@/Interfaces/WhatIDRApoll.interface';

// SINGLE ACTIONS

const setAboutActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_ABOUT_US_ACTION_LOADING,
    payload,
  };
};
const getAboutActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_ABOUT_US_GET_LOADING,
    payload,
  };
};

const clearAboutUs = () => {
  return {
    type: ACTION_TYPES.CLEAR_ABOUT_US,
  };
};

const getAboutUsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_ABOUT_US_SUCCESS,
    payload,
  };
};

const getAboutUsFail = () => {
  return {
    type: ACTION_TYPES.GET_ABOUT_US_FAILURE,
  };
};

const getAboutUs = (type: string) => {
  return async (dispatch: any) => {
    dispatch(getAboutActionLoading(true));
    await API.aboutUs(type)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getAboutUsFail());
        else {
          dispatch(getAboutUsSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getAboutUsFail());
      });
  };
};

const updateAboutUsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_ABOUT_US_SUCCESS,
    payload,
  };
};

const updateAboutUsFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_ABOUT_US_FAILURE,
  };
};

const updateAboutUs = (type: string, payload: IWhatIDRApollData) => {
  return async (dispatch: any) => {
    dispatch(setAboutActionLoading(true));
    await API.updateAboutUs(type, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateAboutUsFail());
        else {
          dispatch(updateAboutUsSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateAboutUsFail());
      });
  };
};

export default {
  getAboutUs,
  updateAboutUs,
  clearAboutUs,
};
