import { SxProps, Theme } from '@mui/system';

export const linkCoppyRightStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
  fontSize: 13,
  border: '4px solid ',
  borderRadius: '50%',
  width: 75,
  height: 75,
  mx: 1,
  my: 'auto',
  '&:hover': {
    color: '#e7a350e7',
    border: '4px solid #FF8C00',
  },
};

export const linkinfoItemStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  color: 'white',
  mt: 2,
};

export const socicalMediaItemStyle: SxProps<Theme> = {
  cursor: 'pointer',
  color: 'white',
  fontSize: '25px',
  mx: '1%',
  height: '30px',
};
