import { IFilterSettings } from '@/Interfaces/Menu.interface';

const USER_SORT_FILTERS: IFilterSettings[] = [
  {
    label: 'Email',
    value: 'status',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const GEOJSON_SORT_FILTERS: IFilterSettings[] = [
  {
    label: 'Status',
    value: 'status',
  },
];

export default { USER_SORT_FILTERS, GEOJSON_SORT_FILTERS };
