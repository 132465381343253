import { ACTION_TYPES, ROUTERS } from '@/Constants';
import API from '@/APIs';
import {
  IChangePassword,
  IUpdateUserData,
  IUserData,
} from '@/Interfaces/SystemUser.interface';
import Utils from '@/Utils';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';
import _ from 'lodash';

const clearSystemUser = () => {
  return {
    type: ACTION_TYPES.CLEAR_SYSTEM_USER,
  };
};

const setSystemUserLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_SYSTEM_USER_ACTION_LOADING,
    payload,
  };
};

const fetchSystemUserLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_SYSTEM_USER_FETCH_LOADING,
    payload,
  };
};

const getSystemUserLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_SYSTEM_USER_GET_LOADING,
    payload,
  };
};

const setSystemUserPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_SYSTEM_USER_PAGINATION,
    payload,
  };
};

const setSystemUserMeta = (payload?: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_SYSTEM_USER_META,
    payload,
  };
};

const changeStatusSuccess = (payload: IUpdateUserData) => {
  return {
    type: ACTION_TYPES.CHANGE_STATUS_USER_SUCCESS,
    payload,
  };
};

const changeStatusFail = () => {
  return {
    type: ACTION_TYPES.CHANGE_STATUS_USER_FAILURE,
  };
};

const changeStatusSystemUser = (filer: IPaginationFilter, payload: any) => {
  return async (dispatch: any) => {
    dispatch(setSystemUserLoading(true));
    await API.changeStatusSystemUser(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(changeStatusFail());
        else {
          await dispatch(fetchSystemUser(filer));
          dispatch(changeStatusSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(changeStatusFail());
      });
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_SYSTEM_USER_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_SYSTEM_USER_FAILURE,
  };
};

const fetchSystemUser = (payload: IPaginationFilter) => {
  return async (dispatch: any) => {
    dispatch(fetchSystemUserLoading(true));
    await API.fetchSystemUser(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items = _.get(result, 'items');
          const meta: any = _.get(result, 'meta');
          dispatch(fetchSuccess(items));
          dispatch(setSystemUserMeta(meta));
          dispatch(setSystemUserPagination(payload));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const createSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_SYSTEM_USER_SUCCESS,
    payload,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_SYSTEM_USER_FAILURE,
  };
};

const createSystemUser = (payload: IUserData) => {
  return async (dispatch: any) => {
    dispatch(setSystemUserLoading(true));
    await API.createSystemUser(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else {
          dispatch(createSuccess(result));
          dispatch(fetchSystemUser({ page: 1, limit: 10 }));
          Utils.redirect(ROUTERS.SYSTEM_USER);
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const getByIdSuccess = (payload: IUserData) => {
  return {
    type: ACTION_TYPES.GET_SYSTEM_USER_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_SYSTEM_USER_BY_ID_FAILURE,
  };
};

const getSyestemUsersById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getSystemUserLoading(true));
    await API.getSyestemUsersById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const updateSuccess = (payload: IUpdateUserData) => {
  return {
    type: ACTION_TYPES.UPDATE_SYSTEM_USER_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_SYSTEM_USER_FAILURE,
  };
};

const changePasswordSystemUser = (payload: IChangePassword) => {
  return async (dispatch: any) => {
    dispatch(setSystemUserLoading(true));
    await API.changePasswordSystemUser(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

export default {
  createSystemUser,
  fetchSystemUser,
  getSyestemUsersById,
  changePasswordSystemUser,
  changeStatusSystemUser,
  clearSystemUser,
};
