import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const initialState = {
  ...DEFAULT_LOADING_STATES,
  requestHasError: false,
  requestIsSuccess: false,
  isRefreshTokenSuccess: false,
  forgotPasswordEmail: '',
  isActionLoading: false,
  isGetLoading: false,
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_AUTH_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: true,
      };
    }
    case ACTION_TYPES.SET_AUTH_GET_LOADING: {
      return {
        ...state,
        isGetLoading: true,
      };
    }

    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: true,
        isRefreshTokenSuccess: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: false,
      };
    case ACTION_TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isRefreshTokenSuccess: true,
      };
    case ACTION_TYPES.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isRefreshTokenSuccess: false,
      };
    case ACTION_TYPES.REQUEST_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        forgotPasswordEmail: payload,
        isActionLoading: false,
      };
    case ACTION_TYPES.REQUEST_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        forgotPasswordEmail: '',
        isActionLoading: false,
      };
    case ACTION_TYPES.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        requestHasError: false,
        requestIsSuccess: true,
        isActionLoading: false,
      };
    case ACTION_TYPES.VERIFY_ACCOUNT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
