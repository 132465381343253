import React from 'react';
import DefaultLayout from '@/Components/DefaultLayout';
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import {
  heroFAQStyle,
  questionsTextStyle,
  askedTextStyle,
} from '../FAQ/faq.style';
import { heroTitleStyle } from '../Policies/policies.style';

const FAQ: React.FC = () => {
  const { t } = useTranslation('translation');
  const [openIDRApollBasics, setOpenIDRApollBasics] =
    React.useState<boolean>(false);
  const [openMembership, setOpenMembership] = React.useState<boolean>(false);
  const [openRewards, setOpenRewards] = React.useState<boolean>(false);
  const [openPrivacy, setOpenPrivacy] = React.useState<boolean>(false);
  const [openHelp, setOpenHelp] = React.useState<boolean>(false);

  const LIST_FAQ = [
    {
      isOpen: openIDRApollBasics,
      title: 'IDRApoll Basics',
      questions1: 'How can I gain access to the surveys?',
      asked1:
        'All panelists will receive poll invitations by email or phone from IDRAPOLL with links that lead to surveys. There is no obligation for panelists to respond to surveys. Each poll should only respond once.',
      questions2:
        'Do I need to make a purchase in order to qualify for surveys?',
      asked2:
        'No, everyone is free to apply for membership in the Panel and then fill in the surveys without having to purchase something.',
      questions3: 'Why should I join IDRAPoll?',
      asked3:
        'By joining IDRAPoll, you will have the opportunity to influence consumers and social decision makers with your thoughts and opinions. Also, you can earn points for completing surveys, which will later be translated into rewards.',
      questions4: 'Is there any cost to join IDRAPoll?',
      asked4: 'No, IDRAPoll membership is entirely free.',
      questions5: 'What is IDRAPoll?',
      asked5:
        'IDRAPOLL is not simply an online study panel. We are a COMMUNITY that gives our members a VOICE. We recruit from various surveys, media portals and social networks. We offer our members the opportunity to participate in testing today and to evaluate tomorrow’s services and products. Your voice represents the voice of thousands!',
    },
    {
      isOpen: openMembership,
      title: 'Membership',
      questions1: 'What happens if I want to cancle my IDRAPoll membership?',
      asked1: 'You can request to be removed from the Panel at any time.',
      questions2: 'Can I have more than one Membership?',
      asked2:
        'Only one Panel Membership Profile is allowed for each Member and only one IDRA Account is allowed for each Member. Any Member found to have a Multiple Panel Membership Profile and / or secondary Panel Membership profiles will have their accounts deleted.',
      questions3: 'How to join IDRAPoll?',
      asked3:
        'You can join directly from our website! Just fill in the basic information required on the home page and click on Create My Account.',
      questions4: 'What are the membership criteria?',
      asked4:
        'To become a member of the IDRAPOLL Group, you must be a resident of Albania or Kosovo and be 16 years of age or older.',
    },
    {
      isOpen: openRewards,
      title: 'Rewards',
      questions1: 'How much money can I make by completing surveys?',
      asked1:
        'You will earn reward points which can be converted into gifts or monetary rewards. Points will increase quickly if you complete a certain number of surveys.',
      questions2: 'What kind of rewards can I earn?',
      asked2:
        'Panel members are rewarded for the polls they receive, through a points system. Each time you complete a survey ahead of time, you will receive points or another reward upon completion. The type and size of the award or prize draw may vary according to study difficulty, length, or other similar elements.',
      questions3: 'How to join IDRAPoll?',
      asked3:
        'You can join directly from our website! Just fill in the basic information required on the home page and click on Create My Account.',
      questions4: 'What are the membership criteria?',
      asked4:
        'To become a member of the IDRAPOLL Group, you must be a resident of Albania or Kosovo and be 16 years of age or older.',
    },
    {
      isOpen: openPrivacy,
      title: 'Privacy',
      questions1: 'Can I refuse to answer some personal questions?',
      asked1:
        'You have no obligation to answer specific personal questions, and such questions will always include a “I prefer not to answer” option.',
      questions2: 'Are my answers kept confidential?',
      asked2:
        'Your individual responses to the polls will remain confidential and will only be used in groups with other confidential answers. Your answers will be nicknamed by IDRAPOLL for security reasons and will only be shared with our customers on an anonymous basis, unless you have given your consent to be identified.',
      questions3: 'Is there a risk of misuse of my personal information?',
      asked3:
        'We are very strict and careful about the sensitive information of our panel members. Their details are kept in a secure database and are not linked to any of the opinions or polls they receive. All your information is stored on secure servers that are owned or rented by us.',
    },
    {
      isOpen: openHelp,
      title: 'Help',
      questions1: 'How can I recover my password?',
      asked1:
        'You can click here to enter your email address. The system will then send you a confirmation message to change your password. Please follow the instructions that will be sent to you in that message.',
      questions2: 'How can I change my password?',
      asked2:
        'Your individual responses to the polls will remain confidential and will only be used in groups with other confidential answers. Your answers will be nicknamed by IDRAPOLL for security reasons and will only be shared with our customers on an anonymous basis, unless you have given your consent to be identified.',
      questions3:
        'How can I change any other personal information I have entered since the registration phase?',
      asked3:
        'Personal data cannot be changed by the Panelists themselves. If there are errors or changes in your personal information, you can send an email to admin@idrapoll.com. Also, updating personal information will be regularly requested automatically by the system.',
      questions4: 'How can I change my contact information?',
      asked4:
        'Once you have entered the IDRAPOLL panel at user.idrapoll.com, click on the “My Profile” link. There you can change your mobile operator, phone number and secondary email address.',
    },
  ];

  const handleClick = (key: string) => {
    switch (key) {
      case 'IDRApoll Basics':
        setOpenIDRApollBasics(!openIDRApollBasics);
        break;
      case 'Membership':
        setOpenMembership(!openMembership);
        break;
      case 'Rewards':
        setOpenRewards(!openRewards);
        break;
      case 'Privacy':
        setOpenPrivacy(!openPrivacy);
        break;
      case 'Help':
        setOpenHelp(!openHelp);
        break;
      default:
        break;
    }
  };

  const renderMain = () => (
    <Box
      className="container"
      sx={{
        mx: 'auto',
        mt: { md: '5%' },
        '& .css-10hburv-MuiTypography-root': {
          fontFamily: 'inherit',
        },
      }}
    >
      <Box component="section" className="hero-faq" sx={heroFAQStyle}>
        <Box sx={heroTitleStyle}>
          <Typography
            variant="h1"
            sx={{ fontSize: 'inherit', fontFamily: 'inherit' }}
          >
            {t('title.FAQ')}
          </Typography>
        </Box>
      </Box>

      <Box
        component="section"
        className="content-faq"
        sx={{
          py: '50px',
          mx: 'auto',
          // display: 'flex',
          justifyContent: 'left',
          width: '70%',
        }}
      >
        {LIST_FAQ.map((items, index) => (
          <List key={index}>
            <ListItemButton
              onClick={() => handleClick(items.title)}
              sx={{
                boxShadow: '0px 15px 10px -22px #111',
                borderBottom: '1px solid gainsboro',
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  fontVariant: 'h4',
                  p: '7px 20px',
                  fontSize: '20px',
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                {items.title}
              </Typography>
              {items.isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={items.isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemText
                  sx={questionsTextStyle}
                  primary={items.questions1}
                />

                <ListItemText sx={askedTextStyle}>{items.asked1}</ListItemText>
                <ListItemText
                  sx={questionsTextStyle}
                  primary={items.questions2}
                />
                <ListItemText sx={askedTextStyle} primary={items?.asked2} />
                <ListItemText
                  sx={questionsTextStyle}
                  primary={items?.questions3}
                />
                <ListItemText sx={askedTextStyle} primary={items?.asked3} />
                {items.questions4 && (
                  <>
                    <ListItemText
                      sx={questionsTextStyle}
                      primary={items?.questions4}
                    />
                    <ListItemText sx={askedTextStyle} primary={items?.asked4} />
                  </>
                )}
                {items.questions5 && (
                  <>
                    <ListItemText
                      sx={questionsTextStyle}
                      primary={items.questions5}
                    />
                    <ListItemText sx={askedTextStyle} primary={items?.asked5} />
                  </>
                )}
              </List>
            </Collapse>
          </List>
        ))}
      </Box>
    </Box>
  );
  return <DefaultLayout content={renderMain()} />;
};

export default FAQ;
