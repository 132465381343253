export default {
  submit: 'Submit',
  cancel: 'Cancel ',
  clear: 'Clear',
  save: 'Kurseni',
  reset: 'Reset',
  ok: 'Ok',
  close: 'Close',
  back: 'Mbrapa',
  loadMore: 'Load more',
  changePassword: 'Change Password',
  userRegistrationForm: 'User Resgistration Form',
  upload: 'Upload',
  export: 'Export',
  viewAll: 'View All',
  users: 'Users',
  login: 'Hyr',
  register: 'Regjistrohu',
  registerNow: 'Regjistrohu tani',
  filter: 'Filter',
  create: 'krijojnë',
  contactUs: 'Na kontakto',
  createCustomers: 'Krijo klientë',
  send: 'Dërgo',
  signIn: 'Hyr',

};
