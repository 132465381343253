import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import Flippy, { FrontSide, BackSide } from 'react-flippy';

import Utils from '@/Utils';
import RewardsAction from '@/Actions/Rewards.action';
import { RootState, useTypedDispatch } from '@/store';
import { IDataRewards } from '@/Interfaces/Rewards.interface';
import { Box, Stack, Typography } from '@mui/material';
import { containerStyle, descriptionStyle } from './home.style';
import { flipCardStyle, sectionTitleStyle } from './home.style';
import { Loading } from '@/Components/Common';

const { getRewards, clearRewards } = RewardsAction;

const RewardsSection: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const rewards: IDataRewards = useSelector((state: RootState) =>
    _.get(state.REWARDS, 'rewards')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.REWARDS, 'isGetLoading')
  );
  const currentLanguage = Utils.getSavedLanguage();

  const _renderRewardsContent = () => (
    <Stack sx={{ flexDirection: { xs: 'column', md: 'row' }, mx: 'auto' }}>
      {!_.isEmpty(rewards) &&
        rewards.content.map((items, index) => (
          <Box
            key={index}
            sx={{
              width: { xs: '400px', md: '100%' },
              height: { xs: '400px', md: '100%' },
              margin: '50px 15px',
              '@media (min-width: 1600px)': {
                width: 380,
                height: 380,
              },
              '@media (max-width: 1600px)': {
                width: 400,
                height: 400,
              },
              '@media (max-width: 1435px)': {
                width: 370,
                height: 370,
              },
              '@media (max-width: 1265px)': {
                width: 340,
                height: 340,
              },
              '@media (max-width: 1200px)': {
                width: 300,
                height: 300,
              },
              '@media (max-width: 1043px)': {
                width: 270,
                height: 270,
              },
              '@media (max-width: 900px)': {
                width: 400,
                height: 400,
              },
              '@media (max-width: 500px)': {
                width: 350,
                height: 350,
              },
              '@media (max-width: 400px)': {
                width: 250,
                height: 250,
              },
              '@media (max-width: 300px)': {
                width: 220,
                height: 220,
              },
            }}
          >
            <Flippy
              key={index}
              flex={1}
              className="flipCard"
              flipOnHover={true}
              flipDirection="horizontal"
              style={flipCardStyle}
            >
              <FrontSide
                className="flipCard-front"
                style={{
                  textAlign: 'center',
                  padding: '0',
                }}
              >
                <Box
                  className="flipCard-front-img"
                  component="img"
                  src={items.image}
                  sx={{ width: '100%' }}
                ></Box>
                <Typography
                  className="flipCard-front-text"
                  sx={{
                    paddingTop: '16px',
                    fontSize: '1.25rem',
                    fontFamily: '"Poppins", sans-serif',
                  }}
                >
                  {items.title}
                </Typography>
              </FrontSide>
              <BackSide
                className="flipCard-back"
                style={{
                  backgroundColor: '#5b7d97',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '1rem',
                }}
              >
                <Box
                  className="flipCard-back-text"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    position: 'relative',
                    my: '0',
                    width: '100%',
                    height: '100%',
                    color: 'white',
                    fontWeight: '400',
                    fontSize: {
                      xs: '20px',
                      sm: '15px',
                      md: '16px',
                      lg: '20px',
                    },
                    '.imagesOnBackPannel': {
                      m: '0 auto',
                      // alignSelf: 'end',
                      // justifySelf: 'flex-end',
                    },
                    '.imagesOnBackPannel img': {
                      p: '5px',
                      width: { xs: '60px', md: '40px', lg: '60px' },
                    },
                  }}
                  component="p"
                  dangerouslySetInnerHTML={Utils.createMarkup(
                    items.description
                  )}
                />
              </BackSide>
            </Flippy>
          </Box>
        ))}
    </Stack>
  );

  React.useEffect(() => {
    dispatch(getRewards(currentLanguage));
    return () => {
      dispatch(clearRewards());
    };
  }, [currentLanguage]);

  return (
    <Box id="rewards" component="section" sx={{ background: '#f9f9f9' }}>
      <Box className="section-container" sx={containerStyle}>
        <Stack>
          <Typography className="title" variant="h4" sx={sectionTitleStyle}>
            {t('title.rewards')}
          </Typography>
          <Box>
            <Typography variant="subtitle1" sx={descriptionStyle}>
              {rewards?.detailDescription}
            </Typography>
          </Box>
          {isLoading ? <Loading /> : _renderRewardsContent()}
        </Stack>
      </Box>
    </Box>
  );
};

export default RewardsSection;
