import React from 'react';
import { Box, Grid } from '@mui/material';
import SideBarComponent from '../LayoutPart/Sidebar';
import NavBar from '../LayoutPart/NavBar';

interface ISectionProps {
  content: JSX.Element;
}

const AdminLayout: React.FC<ISectionProps> = (props: ISectionProps) => {
  const { content } = props;

  return (
    <Grid container width={1} height="100%" flexWrap="nowrap">
      <SideBarComponent />
      <Grid item xs={12}>
        <NavBar />
        <Box p={2} mt={8}>
          {content}
        </Box>
      </Grid>
      {/* <Widgets.Notification />
      <Widgets.Alert /> */}
    </Grid>
  );
};

export default AdminLayout;
