import { Box, Container, Typography } from '@mui/material';

import NoData from '@/Assets/NoDataGif/NoData.gif';

interface INoDataWereFound {
  height?: number;
  imageHeight?: number;
  text?: string;
}

const NoDataWereFound: React.FC<INoDataWereFound> = ({
  height,
  imageHeight = 300,
  text = 'No data were found',
}: INoDataWereFound) => {
  return (
    <Container
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={{ height: height || 500 }}>
        <Box
          sx={{
            height: imageHeight,
            maxWidth: 1,
          }}
          component="img"
          src={NoData}
          alt="loading..."
        />
        <Typography textAlign={'center'} variant="subtitle2">
          {text}
        </Typography>
      </Box>
    </Container>
  );
};

export default NoDataWereFound;
