import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  dataWhyIdrapoll: {},
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_WHY_IDRA_POLL: {
      return { ...state, dataWhyIdrapoll: {} };
    }
    case ACTION_TYPES.SET_WHY_IDRA_POLL_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.SET_WHY_IDRA_POLL_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.GET_WHY_IDRA_POLL_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        dataWhyIdrapoll: payload,
      };
    case ACTION_TYPES.GET_WHY_IDRA_POLL_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.UPDATE_WHY_IDRA_POLL_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        dataWhyIdrapoll: payload,
      };
    case ACTION_TYPES.UPDATE_WHY_IDRA_POLL_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.CREATE_WHY_IDRA_POLL_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        dataWhyIdrapoll: payload,
      };
    case ACTION_TYPES.CREATE_WHY_IDRA_POLL_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
