import {
  ACTION_TYPES,
  DEFAULT_LOADING_STATES,
  DEFAULT_PAGINATION,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  createSystemUserSuccess: false,
  pagination: DEFAULT_PAGINATION,
  meta: null,
  users: [],
  userDetail: {},
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_SYSTEM_USER: {
      return {
        ...state,
        pagination: DEFAULT_PAGINATION,
        meta: null,
        users: [],
        userDetail: {},
      };
    }
    case ACTION_TYPES.SET_SYSTEM_USER_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_SYSTEM_USER_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_SYSTEM_USER_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.SET_SYSTEM_USER_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_SYSTEM_USER_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.FETCH_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        users: payload,
      };
    case ACTION_TYPES.FETCH_SYSTEM_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
      };
    case ACTION_TYPES.GET_SYSTEM_USER_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        userDetail: payload,
      };
    case ACTION_TYPES.GET_SYSTEM_USER_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        createSystemUserSuccess: true,
      };
    case ACTION_TYPES.CREATE_SYSTEM_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        createSystemUserSuccess: false,
      };
    case ACTION_TYPES.UPDATE_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.UPDATE_SYSTEM_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.CHANGE_STATUS_USER_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.CHANGE_STATUS_USER_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
