import React, { useEffect, useState } from 'react';

import DefaultLayout from '@/Components/DefaultLayout';
import { ContactTable } from '@/Components/LayoutPart/DataTable';
import { CommonColors } from '@/Themes';
import {
  Button,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import ContactDetail from '@/Components/Dialogs/ContactDetail';
import { useTranslation } from 'react-i18next';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { ContactActions } from '@/Actions';
import { IDataContact, IFilterContact } from '@/Interfaces/Contact.interface';

const { fetchContact } = ContactActions;

const DEFAULT_FILTER = {
  page: 1,
  limit: 10,
  email: '',
};

const Contact = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [filter, setFilter] = useState<IFilterContact>(DEFAULT_FILTER);
  const [contactDetail, setContactDetail] = useState<IDataContact>();
  const [openContactDetail, setOpenInboxDetail] =
    React.useState<boolean>(false);

  const contact: IDataContact[] = useSelector((state: RootState) =>
    _.get(state.CONTACT, 'contact')
  );

  useEffect(() => {
    dispatch(fetchContact(filter));
  }, []);

  const onRowAction = (data: IDataContact, action: 'detail') => {
    if (action === 'detail') {
      setOpenInboxDetail(true);
      setContactDetail(data);
    }
  };

  const _renderTop = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography
            sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
            variant="h4"
          >
            {t('title.contact')}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4} display={'flex'}>
          <InputLabel sx={{ minWidth: '14vh', mt: 1 }}>
            {t('label.searchBy')}
          </InputLabel>
          <TextField
            value={filter.email}
            size="small"
            label="Email"
            fullWidth
            onChange={(e) => setFilter({ ...filter, email: e.target.value })}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              mr: 1,
              background: CommonColors.bismark,
              '&:hover': {
                background: '#486270',
              },
            }}
            onClick={() => dispatch(fetchContact(filter))}
          >
            {t('button.filter')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: '1px solid #28384580',
              color: CommonColors.gratou,
              '&:hover': {
                border: '1px solid #1e2a3480',
              },
            }}
            onClick={() => {
              setFilter(DEFAULT_FILTER);
              dispatch(fetchContact(DEFAULT_FILTER));
            }}
          >
            {t('button.reset')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ContactTable data={contact} onRowAction={onRowAction} />
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          {_renderTop()}
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {_renderBody()}
        </Grid>
        {openContactDetail && (
          <ContactDetail
            open={openContactDetail}
            onClose={async () => {
              setOpenInboxDetail(false);
              setContactDetail({});
              await dispatch(
                fetchContact({
                  filter,
                })
              );
            }}
            data={contactDetail}
          />
        )}
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default Contact;
