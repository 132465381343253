enum ROLE {
  ADMIN = 'admin',
  ADMIN_BASHKIE = 'admin bashkie',
  USER = 'user',
  USER_BASHKIE = 'user bashkie',
  USER_ADF = 'user adf',
  SUPER_ADMIN = 'super admin',
}

export default ROLE;
