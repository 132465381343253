import React, { useState } from 'react';
import _ from 'lodash';
import {
  Grid,
  Box,
  Card,
  List,
  ListItemButton,
  Collapse,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';

import mainLogo from '@/Assets/logo.svg';

import {
  sideBarStyles,
  Drawer,
  DrawerHeader,
  logoStyles,
} from './SideBar.styles';
import { LIST, ROUTERS } from '@/Constants';
import Utils from '@/Utils';
import { useLocation } from 'react-router-dom';
import { ISideBar } from '@/Interfaces/Menu.interface';
import { CommonColors } from '@/Themes';
import { CommonStyles } from '@/Components/Common';
import { useTranslation } from 'react-i18next';
import { AuthActions } from '@/Actions';
import { useTypedDispatch } from '@/store';

const { logout } = AuthActions;

const { ADMIN_MENU } = LIST.MENU_LIST;

const SideBarComponent: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');
  const path = useLocation().pathname;

  const [openItems, setOpenItems] = useState<string[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  //Event
  const handleOpenItems = (value: string) => {
    let currentItems = [...openItems];
    if (_.includes(openItems, value))
      currentItems = _.filter(currentItems, (el) => el !== value);
    else currentItems.push(value);
    setOpenItems(currentItems);
  };

  const handleCollapse = () => {
    if (!isCollapsed) {
      setOpenItems([]);
    }
    setIsCollapsed(!isCollapsed);
  };

  //Render
  const _renderSubOptions = (items: ISideBar[], key: number) => {
    return _.map(items, (item) => {
      const selected = item.value === path || _.includes(path, item.value);
      return (
        <ListItemButton
          sx={{ pl: 10, ...CommonStyles.hoverBorderEffect }}
          key={`${key}${item.label}`}
          selected={selected}
          onClick={() => Utils.redirect(item.value)}
        >
          <ListItemText primary={t(`menu.${item.label}`)} />
        </ListItemButton>
      );
    });
  };

  const _renderParentItems = () => {
    const menu = ADMIN_MENU;
    return _.map(menu, (item, index) => {
      const { subOptions, label, value, icon } = item;
      const activeOnSub = _.find(
        subOptions || [],
        (el) => el.value === path || _.includes(path, el.value)
      );
      const selected = value === path || Boolean(activeOnSub);
      const isOpenSub = _.includes(openItems, value);
      return (
        <Box key={index}>
          <ListItemButton
            key={index}
            selected={value === path}
            onClick={() => {
              if (isCollapsed) setIsCollapsed(false);
              else if (subOptions) {
                handleOpenItems(value);
              } else {
                if (value === ROUTERS.AUTH) {
                  dispatch(logout());
                } else Utils.redirect(value);
              }
            }}
            sx={{
              ...CommonStyles.hoverBorderEffect,
              justifyContent: !isCollapsed ? 'flex-end' : 'center',
              '.MuiListItemIcon-root': {
                minWidth: !isCollapsed ? '56px' : 'auto',
              },
            }}
          >
            <ListItemIcon sx={{ color: 'white', minWidth: 45 }}>
              {icon}
            </ListItemIcon>
            {!isCollapsed && <ListItemText sx={{textTransform: 'uppercase'}} primary={t(`menu.${label}`)} />}
            {subOptions &&
              !isCollapsed &&
              (isOpenSub ? <ExpandMore /> : <ChevronRightIcon />)}
          </ListItemButton>
          {subOptions && (
            <Collapse
              in={(isOpenSub || selected) && !isCollapsed}
              timeout="auto"
              unmountOnExit
              key={label}
            >
              <List component="div">
                {_renderSubOptions(subOptions, index)}
              </List>
            </Collapse>
          )}
          <Divider />
        </Box>
      );
    });
  };
  const _renderSidebar = () => {
    return (
      <List sx={{ width: 1, pb: 0 }} component="nav">
        {_renderParentItems()}
      </List>
    );
  };

  return (
    <Drawer
      variant="permanent"
      open={!isCollapsed}
      PaperProps={{
        sx: { background: CommonColors.mainBackground, color: 'white' },
      }}
    >
      <DrawerHeader>
        <Card
          sx={{
            width: 1,
            background: CommonColors.mainBackground,
            color: 'white',
            position: 'relative',
          }}
        >
          <Box
            onClick={() => Utils.redirect(ROUTERS.HOME)}
            component="img"
            alt="monitoringmatrix"
            src={mainLogo}
            sx={{ ...logoStyles, position: 'absolute' }}
          />
          {!isCollapsed && (
            <IconButton
              sx={{ color: 'white', position: 'absolute', top: 0, right: 0 }}
              onClick={() => handleCollapse()}
            >
              <MenuOpenIcon />
            </IconButton>
          )}
        </Card>
      </DrawerHeader>
      <DrawerHeader>
        <Grid container direction="column" sx={sideBarStyles}>
          <Grid item>{_renderSidebar()}</Grid>
        </Grid>
      </DrawerHeader>
    </Drawer>
  );
};

export default SideBarComponent;
