export default {
  //Common

  //Select Box
  choose: 'Choose',
  noOption: 'Option is empty!',

  //Dashboard
  dashboard: 'Dashboard',

  //User
  user: 'User',
  userName: 'Username',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  fullName: 'Full Name',
  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  showPassword: 'Show Password',
  password: 'Password',
  uploadImage: 'Upload Image',
  reason: 'Reason',
  title: 'Title',
  category: 'Category',
  language: 'Language',
  tags: 'Tags',
  meta: 'Meta',
  carouselImageDescription: 'Carousel Image Description',
  description: 'Description',
  statistical: 'Statistical',
  number: 'Number',
  titleGraphAge: 'Title Graph Age',
  titleGraphGender: 'Title Graph Gender',
  titleGraphDistribution: 'Title Graph Distribution',
  titleGraphEducation: 'Title Graph Education',
  titleGraphEmployment: 'Title Graph Employment',
  editor: 'Editor',
  linkImage: 'Image Link / Icon tag',
  descriptionForSecondGif: 'Description For Second Gif',
  descriptionForThirdGif: 'Description For Third Gif',
  imageFirstGift: 'Image link first gift',
  imageSecondGift: 'Image link second gift',
  imageThirdGift: 'Image link third gift',
  mainDescription: 'Main Description',
  firstReward: 'First Reward',
  titleFirstReward: 'Title first reward',
  imageFirstReward: 'Image first reward',
  descriptionFirstReward: 'Description first reward',
  secondReward: 'Second Reward',
  titleSecondReward: 'Title Second Reward',
  imageSecondReward: ' Image Second Reward',
  descriptionSecondReward: 'Description second Reward',
  thirdReward: 'Third reward',
  titleThirdReward: 'Title third reward',
  imageThirdReward: 'Image third reward',
  descriptionThirdReward: 'Description third reward',
  research: 'Research',
  detailDescription: 'Detail description',
  descriptionForFirstGif: 'Description for first gif',
  descriptionThirdGift: 'Description third gift',
  name: 'Name',
  titleGraph: 'Title graph',

  textContactPage: 'For all enquiries, please email us using the form below',
  haveAnAccount: 'Have an account?',
  notAMemberYet: 'Not a member yet?',
  content: 'Content',
  url: 'Url',
  contentGrahpAge: 'Content grahp age',
  graphAge: 'Graph age',
  grahpGender: 'Grahp gender',
  titleGrahpGender: 'Title grahp gender',
  contentGrahpGender: 'Content grahp gender ',
  grahpDistribution: 'Grahp distribution',
  titleGrahpDistribution: 'Title grahp distribution',
  contentGrahpDistribution: 'Content grahpDistribution',
  grahpEducation: 'Grahp education',
  titleGrahpEducation: 'Title grahp education',
  grahpEmployment: 'Grahp employment',
  titleGrahpEmployment: 'Title grahp employment',
  contentGrahpEmployment: 'Content grahp employment',
  unsubscribeText:
    'Please take a moment to tell us why you no longer wish to hear from us',
  unsubscribeOption1: 'I get a lot of emails from IDRApoll and that bothers me',
  unsubscribeOption2: 'I do not prefer to fill out online surveys',
  unsubscribeOption3: 'I do not like rewards and gifts',
  unsubscribeOption4:
    'I do not think the content of the surveys is appropriate',
  unsubscribeOption5: 'Other',
  IAgreeToThe: 'I agree to the',
  TermsAndConditions: 'Terms and conditions',
  wouldYouLike:
    'Would you like us to send you an email from time to time for the latest news with IDRApoll?',
  viewAll: 'View All',
  searchBy: 'Search by',
  country: 'Country',
  albania: 'Albania',
  Albania: 'Albania',
  kosovo: 'Kosovo',
  Kosovo: 'Kosovo',
};
