import { ACTION_TYPES, ROUTERS } from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import _ from 'lodash';
import {
  IDataCreateResults,
  IDataUpdateResults,
  IPaginationsResults,
} from '@/Interfaces/Results.interface';

const clearResults = () => {
  return {
    type: ACTION_TYPES.CLEAR_RESULTS,
  };
};

const setResultsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_RESULTS_ACTION_LOADING,
    payload,
  };
};

const fetchResultsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_RESULTS_FETCH_LOADING,
    payload,
  };
};

const getResultsLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_RESULTS_GET_LOADING,
    payload,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_RESULTS_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_RESULTS_FAILURE,
  };
};

const fetchResults = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(fetchResultsLoading(true));
    await API.fetchResults(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          dispatch(fetchSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};


const fetchResultsHomepage = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(fetchResultsLoading(true));
    await API.fetchResultsHomepage(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          dispatch(fetchSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};


const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_RESULTS_BY_ID_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_RESULTS_BY_ID_FAILURE,
  };
};

const getResultsById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getResultsLoading(true));
    await API.getResultsById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else {
          dispatch(getSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_RESULTS_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_RESULTS_FAILURE,
  };
};

const createResults = (payload: IDataCreateResults) => {
  return async (dispatch: any) => {
    dispatch(setResultsLoading(true));
    await API.createResults(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else dispatch(createSuccess());
        await Utils.redirect(ROUTERS.RESULTS);
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const updateSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_RESULTS_SUCCESS,
    payload,
  };
};

const updateFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_RESULTS_FAILURE,
  };
};

const updateResults = (id: string, payload: IDataUpdateResults) => {
  return async (dispatch: any) => {
    dispatch(setResultsLoading(true));
    await API.updateResults(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateFail());
        else {
          dispatch(updateSuccess(result));
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateFail());
      });
  };
};

const publishedSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.PUBLISHT_RESULTS_SUCCESS,
    payload,
  };
};

const publishedFail = () => {
  return {
    type: ACTION_TYPES.PUBLISHT_RESULTS_FAILURE,
  };
};

const publishedResults = (
  id: string,
  action: { isPublished: boolean },
  pagination: IPaginationsResults
) => {
  return async (dispatch: any) => {
    dispatch(setResultsLoading(true));
    await API.publishedResults(id, action)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(publishedFail());
        else dispatch(publishedSuccess(result));
        await dispatch(fetchResults(pagination));
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(publishedFail());
      });
  };
};

const landingpageSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.LANDINGPAGE_RESULTS_SUCCESS,
    payload,
  };
};

const landingpageFail = () => {
  return {
    type: ACTION_TYPES.LANDINGPAGE_RESULTS_FAILURE,
  };
};

const landingpageResults = (
  id: string,
  action: { isLandingPage: boolean },
  pagination: IPaginationsResults
) => {
  return async (dispatch: any) => {
    dispatch(setResultsLoading(true));
    await API.landingpageResults(id, action)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(landingpageFail());
        else dispatch(landingpageSuccess(result));
        await dispatch(fetchResults(pagination));
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(landingpageFail());
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_RESULTS_SUCCESS,
  };
};

const deleteFail = () => {
  return {
    type: ACTION_TYPES.DELETE_RESULTS_FAILURE,
  };
};

const deleteResults = (id: string, pagination: IPaginationsResults) => {
  return async (dispatch: any) => {
    dispatch(setResultsLoading(true));
    await API.deleteResults(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(deleteFail());
        else dispatch(deleteSuccess());
        await dispatch(fetchResults(pagination));
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deleteFail());
      });
  };
};

export default {
  fetchResults,
  getResultsById,
  createResults,
  updateResults,
  publishedResults,
  landingpageResults,
  clearResults,
  deleteResults,
  fetchResultsHomepage
};
