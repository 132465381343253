import { ROUTERS } from '@constants';
import { UserContainer } from '@containers';

const UserRouter = {
  path: ROUTERS.HOME,
  children: [
    {
      path: ROUTERS.HOME,
      element: <UserContainer.Home />,
    },
    {
      path: ROUTERS.AUTH,
      element: <UserContainer.Authentication />,
    },
    {
      path: ROUTERS.CONTACT,
      element: <UserContainer.Contact />,
    },
    {
      path: ROUTERS.ALL_RESULTS,
      element: <UserContainer.AllResults />,
    },
    {
      path: ROUTERS.RESULTS_DETAIL,
      element: <UserContainer.ResultDetails />,
    },
    {
      path: ROUTERS.POLICIES,
      element: <UserContainer.Policies />,
    },
    {
      path: ROUTERS.FAQ,
      element: <UserContainer.FAQ />,
    },
    {
      path: ROUTERS.UNSUBSCRIBE,
      element: <UserContainer.Unsubscribe />,
    },
  ],
};

const ForbidenRouter = {
  path: '*',
  element: <UserContainer.NotFound />,
};

export default UserRouter;
export { ForbidenRouter };
