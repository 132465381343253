import * as React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import AboutUsAction from '@/Actions/AboutUs.action';
import { IWhatIDRApollData } from '@/Interfaces/WhatIDRApoll.interface';
import { Stack, Typography, Box } from '@mui/material';
import { cardTextStyle } from './home.style';
import { sectionTitleStyle } from './home.style';
import { Loading } from '@/Components/Common';

const { getAboutUs, clearAboutUs } = AboutUsAction;

const WhatIsIdraPoll: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();
  const payload: IWhatIDRApollData = useSelector((state: RootState) =>
    _.get(state.ABOUT_US, 'aboutUs')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.ABOUT_US, 'isGetLoading')
  );
  const [dataIdrapoll, setDataIdrapoll] = React.useState<IWhatIDRApollData>();
  const currentLanguage = Utils.getSavedLanguage();

  const WhatIDRApoll_LIST = [
    {
      desc: payload?.descriptionFirstGift,
      src: payload?.imageFirstGift,
    },
    {
      desc: payload?.descriptionSecondGift,
      src: payload?.imageSecondGift,
    },
    {
      desc: payload?.descriptionThirdGift,
      src: payload?.imageThirdGift,
    },
  ];

  React.useEffect(() => {
    dispatch(getAboutUs(currentLanguage));
    return () => {
      dispatch(clearAboutUs());
    };
  }, [currentLanguage]);

  React.useEffect(() => {
    if (!_.isEmpty(payload)) setDataIdrapoll(payload);
  }, [payload]);

  const _renderAboutUsContent = () => (
    <>
      <Box
        className="subtitle"
        sx={{
          fontSize: '17px',
          fontFamily: '"Poppins", sans-serif',
          textAlign: 'center',
          py: '20px',
        }}
      >
        <Box
          sx={{
            textDecoration: 'none !important',
            '& span a': {
              textDecoration: 'none',
            },
          }}
          component="div"
          dangerouslySetInnerHTML={Utils.createMarkup(
            dataIdrapoll?.detailDescription
          )}
        />
      </Box>
      <Stack sx={{ flexDirection: { xs: 'coloumn', md: 'row' } }}>
        {WhatIDRApoll_LIST.map((items, index) => (
          <Stack
            key={index}
            flex={1}
            // direction="column"
            className="myCard"
            sx={{ px: '35px' }}
          >
            <Box
              component="img"
              src={items.src}
              sx={{ width: '50%', m: 'auto' }}
            />
            <Typography
              className="cardText"
              variant="subtitle1"
              sx={cardTextStyle}
            >
              {items.desc}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );

  return (
    <Box id="whatIsIDRApoll" component="section">
      <Box
        className="section-container"
        sx={{
          width: '100%',
          px: 'var(--bs-gutter-x,.75rem)',
          py: '6%',
          mx: 'auto',
          maxWidth: {
            xs: '576px',
            sm: '720px',
            md: '960px',
            lg: '1200px',
            xl: '1320px',
          },
        }}
      >
        <Typography className="title" variant="h4" sx={sectionTitleStyle}>
          {t('title.whatIsIDRApoll')}
        </Typography>
        {isLoading ? <Loading /> : _renderAboutUsContent()}
      </Box>
    </Box>
  );
};

export default WhatIsIdraPoll;
