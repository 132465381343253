import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import Swal from 'sweetalert2';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';

const currentLanguage = Utils.getSavedLanguage();
// SINGLE ACTIONS

const clearContact = () => {
  return {
    type: ACTION_TYPES.CLEAR_CONTACT,
  };
};

const setContactActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_CONTACT_ACTION_LOADING,
    payload,
  };
};

const getcontactActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_CONTACT_GET_LOADING,
    payload,
  };
};

const fetchContactActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_CONTACT_FETCH_LOADING,
    payload,
  };
};

const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_CONTACT_BY_ID_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_CONTACT_BY_ID_FAILURE,
  };
};

const getContact = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getcontactActionLoading(true));
    await API.getContact(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else {
          dispatch(getSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_CONTACT_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_CONTACT_FAILURE,
  };
};

const setContactPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_CONTACT_PAGINATION,
    payload,
  };
};

const setContactMeta = (payload?: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_CONTACT_META,
    payload,
  };
};

const fetchContact = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(fetchContactActionLoading(true));
    await API.fetchContact(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items: any = _.get(result, 'data');
          const pagination: any = {
            page: _.get(result, 'currentPage'),
            limit: _.get(result, 'itemsPerPage'),
          };
          const meta: any = {
            currentPage: _.get(result, 'currentPage'),
            itemCount: items.length,
            itemsPerPage: _.get(result, 'itemsPerPage'),
            totalItems: _.get(result, 'totalItems'),
            totalPages: _.get(result, 'totalPage'),
          };
          dispatch(fetchSuccess(items));
          dispatch(setContactPagination(pagination));
          dispatch(setContactMeta(meta));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const sendContactSuccess = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SEND_CONTACT_SUCCESS,
    payload,
  };
};

const sendContactFailure = () => {
  return {
    type: ACTION_TYPES.SEND_CONTACT_FAILURE,
  };
};

const sendContact = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setContactActionLoading(true));
    await API.SendContact(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(sendContactFailure());
        else {
          const title = currentLanguage === 'en' ? 'CONTACT' : 'KONTAKT';
          const message =
            currentLanguage === 'en'
              ? 'Thank you for your time with us. We have successfully processed your contact request.'
              : 'Faleminderit për kohën tuaj me ne. Ne kemi përpunuar me sukses kërkesën tuaj për kontakt.';
          dispatch(sendContactSuccess(true));
          Swal.fire({
            title: title,
            text: message,
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((result) => {
            if (result.isConfirmed) {
              Utils.redirect(ROUTERS.HOME);
            } else {
              Utils.redirect(ROUTERS.HOME);
            }
          });
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(sendContactFailure());
      });
  };
};

export default {
  getContact,
  fetchContact,
  clearContact,
  sendContact,
};
