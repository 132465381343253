export default {
  submit: 'Submit',
  cancel: 'Cancel ',
  clear: 'Clear',
  save: 'Save',
  reset: 'Reset',
  ok: 'Ok',
  close: 'Close',
  back: 'Back',
  loadMore: 'Load more',
  changePassword: 'Change Password',
  userRegistrationForm: 'User Resgistration Form',
  upload: 'Upload',
  export: 'Export',
  viewAll: 'View All',
  previousPage: 'Previous Page',
  users: 'Users',
  login: 'Login',
  register: 'Register',
  registerNow: 'Register now',
  filter: 'Filter',
  create: 'Create',
  contactUs: 'Contact us',
  createCustomers: 'Create customers',
  send: 'Send',
  signIn: 'Sign in',
};
