export default {
  email: 'Email',
  emailContact: 'Email *',
  fullName: 'Full name *',
  fullNameRegister: 'Full name',
  password: 'Password',
  confirmPassword: 'Confirm password',
  search: 'SEARCH...',
  message: 'Message *',
};
