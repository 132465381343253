import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

export const mainStyles: SxProps<Theme> = {
  position: 'relative',
  // background: 'rgba(225,225,225, 0.3)',
  color: CommonColors.shark,
  height: '100%',
};

export const landingStyles: SxProps<Theme> = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  overflow: 'auto',
};
