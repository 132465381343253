import { SxProps, Theme } from '@mui/system';
import faqImage from '@/Assets/faqq.jpg';

export const heroFAQStyle: SxProps<Theme> = {
  background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${faqImage}') no-repeat`,
  backgroundSize: 'cover',
  backgroundPosition: '35% 70%',
  position: 'relative',
  width: '100%',
  height: '45vh',
};

export const questionsTextStyle: SxProps<Theme> = {
  width: '100%',
  p: '7px 10px',
  mb: '1rem',
  backgroundColor: 'rgb(248, 245, 245)',
  borderLeft: '5px solid #f26f26',
  fontFamily:
    'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  display: 'inline-flex',
  alignItems: 'center',
  '&:before': {
    content: '"Q."',
    color: '#f26f26',
    fontSize: '22px',
    display: 'inline-block',
    mr: '5px',
  },
};

export const askedTextStyle: SxProps<Theme> = {
  width: '100%',
  p: '7px 10px',
  mb: '1rem',
  backgroundColor: 'rgba(173, 216, 230, 0.164)',
  borderLeft: '5px solid #5c7d95',
  fontFamily:
    'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  display: 'inline-flex',
  alignItems: 'center',
  '&:before': {
    content: '"A."',
    color: '#5c7d95',
    fontSize: '22px',
    mr: '5px',
    // display: 'inline-flex',
  },
};
