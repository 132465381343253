import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IDataRewards } from '@/Interfaces/Rewards.interface';

// SINGLE ACTIONS

const setRewardsActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REWARDS_ACTION_LOADING,
    payload,
  };
};
const getRewardsActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_REWARDS_GET_LOADING,
    payload,
  };
};

const clearRewards = () => {
  return {
    type: ACTION_TYPES.CLEAR_REWARDS,
  };
};

const getRewardsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_REWARDS_SUCCESS,
    payload,
  };
};

const getRewardsFail = () => {
  return {
    type: ACTION_TYPES.GET_REWARDS_FAILURE,
  };
};

const getRewards = (type: string) => {
  return async (dispatch: any) => {
    dispatch(getRewardsActionLoading(true));
    await API.getRewards(type)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getRewardsFail());
        else {
          dispatch(getRewardsSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getRewardsFail());
      });
  };
};

const updateRewardsSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_REWARDS_SUCCESS,
    payload,
  };
};

const updateRewardsFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_REWARDS_FAILURE,
  };
};

const updateRewards = (type: string, payload: IDataRewards) => {
  return async (dispatch: any) => {
    dispatch(setRewardsActionLoading(true));
    await API.updateRewards(type, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateRewardsFail());
        else {
          dispatch(updateRewardsSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateRewardsFail());
      });
  };
};

export default {
  getRewards,
  updateRewards,
  clearRewards,
};
