import { sendRequest } from '@/Configs';
import { IWhatIDRApollData } from '@/Interfaces/WhatIDRApoll.interface';
import { API } from '@constants';

const { ABOUT_US } = API;

export const aboutUs = async (id: string) => {
  return await sendRequest(`${ABOUT_US.ROOT}${id}`, 'GET');
};

export const updateAboutUs = async (id: string, payload: IWhatIDRApollData) => {
  return sendRequest(`${ABOUT_US.ROOT}${id}`, 'PUT', payload);
};
