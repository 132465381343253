export default {
  areYouSureYouWantToDelelteResult:
    'Jeni i sigurt që dëshironi të fshini rezultatin?',
  areYouSureYouWantToDeleteCustomer:
    'Jeni i sigurt që dëshironi të fshini klientin?',
  areYouSureYouWantTo: 'Jeni i sigurt që dëshironi ta',
  thisAccount: 'këtë llogari',
  inactive: 'çaktivizoni',
  activated: 'aktivizuar',
  dragDropSomeFilesHereOrClickToSelectFiles:
    'Zvarrit dhe lësho disa skedarë këtu ose kliko për të zgjedhur skedarë',
  dropTheFilesHere: 'Hidhini skedarët këtu',
  allowFileTypes: 'Lejo llojet e skedarëve',
  areYouSureYouWantToDelelteImage:
    'Jeni të sigurt që dëshironi të fshini imazhin e rezultatit',
  canNotLeaveTheTitleBlank: 'Nuk mund ta lini titullin bosh!',
  imageDescriptionCannotBeEmpty: 'ImageDescription nuk mund të jetë bosh!',
  imageUrlCannotBeEmpty: 'ImageUrl nuk mund të jetë bosh!',
  contentCannotBeEmpty: 'Përmbajtja nuk mund të jetë bosh!',
  canNotLeaveTheNameBlank: 'Nuk mund ta lë emrin bosh!',
  canNotLeaveTheUrlBlank: 'URL-ja nuk mund të lihet bosh!',
  canNotLeaveTheLogoBlank: 'Logoja nuk mund të lihet bosh!',
  nameIsRequired: 'kërkohet emri',
  unsubscribeSuccessFully:
    'Faleminderit për kohën tuaj me ne. Ne kemi përpunuar me sukses kërkesën tuaj për çregjistrim.',
  sendContactSuccessFully:
    'Faleminderit për kohën tuaj me ne. Ne kemi përpunuar me sukses kërkesën tuaj për kontakt.',
  pleaseEnterAValidFullName:
    '*Ju lutemi shkruani një emër të plotë të vlefshëm.',
  yourPasswordMustBeAtLeast8Characters:
    '*Fjalëkalimi juaj duhet të jetë së paku 8 karaktere i gjatë',
  thesePasswordDontMatch: "*These password don't match.",
  areYouSureYouWantToChangeTheLandingPage:
    'Jeni i sigurt që dëshironi të ndryshoni faqen e uljes?',
  areYouSureYouWantToPublish:
    'Je i sigurt që dëshiron ta ndryshosh publikimin?',
  areYouSureYouWantToUnpublish:
    'Je i sigurt që dëshiron të ndryshosh anulimin e publikimit?',
  thereIsNoResultsToDisplay: 'Nuk ka asnjë rezultat për të shfaqur',
};
