export default {
  //Common

  //Select Box
  choose: 'Choose',
  noOption: 'Option is empty!',

  //Dashboard
  dashboard: 'Dashboard',

  //User
  user: 'User',
  userName: 'Username',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  fullName: 'Emri i plotë',
  confirmPassword: 'Konfirmoni fjalëkalimin',
  showPassword: 'Show Password',
  password: 'Fjalëkalimin',
  uploadImage: 'Ngarko imazh',
  reason: 'Arsyeja',
  title: 'Titull',
  category: 'Kategoria',
  language: 'Gjuhe',
  tags: 'Etiketat',
  meta: 'Meta',
  carouselImageDescription: 'Përshkrimi i imazhit të karuselit',
  description: 'Përshkrim',
  shortDescription: 'Përshkrim i shkurtër',
  descriptionForFirstGif: 'Përshkrimi për Gif-in e parë',
  detailedDescription: 'Pershkrim i detajuar',
  mainDescription: 'Përshkrimi kryesor',
  titleForphoto: 'Titulli për foton',
  descriptionForPhoto: 'Përshkrimi për foton',
  research: 'Hulumtimi',
  statistical: 'Statistikore',
  number: 'Numri',
  titleGraphAge: 'Grafiku i titullit Mosha',
  titleGraphGender: 'Grafiku i titullit Gjinia',
  titleGraphDistribution: 'Title Graph Distribution',
  titleGraphEducation: 'Shpërndarja e grafikut të titullit',
  titleGraphEmployment: 'Grafiku i titullit Punësimi',
  editor: 'Redaktor',
  linkImage: 'Lidhja e imazhit / etiketa e ikonës',
  descriptionForSecondGif: 'Përshkrimi Për Gif-in e Dytë',
  descriptionForThirdGif: 'Përshkrimi Për Gifin e Tretë',
  imageFirstGift: 'Lidhja e imazhit Dhurata e parë',
  imageSecondGift: 'Lidhja e imazhit Dhurata e dytë',
  imageThirdGift: 'Lidhja e imazhit dhuratë e tretë',
  textContactPage: 'Na kontaktoni duke vendosur të dhënat tuaja',
  haveAnAccount: 'Keni një llogari?',
  firstReward: 'Shpërblimi i parë',
  titleFirstReward: 'Titulli Shpërblimi i parë',
  imageFirstReward: 'Imazhi Shpërblimi i parë',
  descriptionFirstReward: 'Përshkrim Shpërblimi i parë',
  secondReward: 'Shpërblimi i dytë',
  titleSecondReward: 'Titulli Shpërblimi i dytë',
  imageSecondReward: 'Shpërblimi i dytë i imazhit',
  descriptionSecondReward: 'Përshkrimi Shpërblimi i dytë',
  thirdReward: 'Shpërblimi i tretë',
  titleThirdReward: 'Shpërblimi i tretë i titullit',
  imageThirdReward: 'Shpërblimi i tretë i imazhit',
  descriptionThirdReward: 'Përshkrim shpërblimi i tretë',
  detailDescription: 'Përshkrimi i detajuar',
  descriptionThirdGift: 'Përshkrim dhurata e tretë',
  name: 'Emri',
  titleGraph: 'Grafiku i titullit',
  content: 'Përmbajtjen',
  url: 'Url',
  graphAge: 'Mosha e grafikut',
  grahpGender: 'Grafikoni gjininë',
  grahpDistribution: 'Shpërndarja e grafikut',
  grahpEducation: 'Edukimi grafik',
  grahpEmployment: 'Grafiku i punësimit',
  searchBy: 'Kërko sipas',
  newPassword: 'Fjalëkalim i ri',
  oldPassword: 'Fjalëkalimi i vjetër',

  //Event
  event: 'Event',
  events: 'Events',
  eventManagement: 'Event Management',
  eventList: 'Event List',
  addEvent: 'Add New Event',
  modifyEvent: 'Modify Event',
  eventDetails: 'Event Details',
  eventCalendar: 'Event Calendar',
  eventDate: 'Event Date',
  eventName: 'Event Name',
  eventTime: 'Event Time',
  eventSummary: 'Event Summary',
  eventReport: 'Event Report',
  eventReportStatistics: 'Event Report Statistics',
  addNewEvent: 'Add New Event',
  restrictedAccessEvent: 'Restricted access event',
  //Event Report
  reportStatistics: 'Report Statistics',
  reportEventStatistics: 'Report Event Statistics',
  restrictedAccess: 'Restricted access',
  registeredUsers: 'Registered Users',
  registeredUsersManagement: 'Registered Users Management',
  registrationRequests: 'Registration Requests',
  registrationRequestsManagement: 'Registration Request Management',
  approvedUsers: 'Approved Users',
  restrictedUsers: 'Restricted Users',
  attendees: 'Attendees',
  coHost: 'Co-host',
  nameCoHost: 'Name Co-host',
  emailCoHost: 'Email Co-host',
  assignCoHost: 'Assign Co-host',
  coHostOrSpeaker: 'Co-host/Speaker',
  //Venue
  venue: 'Venue',

  // Event Type
  eventType: 'Event Type',

  //Event Admin
  eventAdmin: 'Event Admin',
  eventAdmins: 'Event Admins',
  eventAdminManagement: 'Event Admin Management',
  eventAdminList: 'Event Admin List',
  addEventAdmin: 'Add New Event Admin',
  modifyEventAdmin: 'Modify Event Admin',
  eventAdminDetails: 'Event Admin Details',
  eventAdminName: 'Event Admin Name',
  adminName: 'Admin Name',
  enterAdminName: 'Enter Admin Name',

  //Sponsor
  sponsorAdd: 'add new',
  orderSponsor: 'Order sponsor',
  sponsor: 'Sponsor',
  sponsorTitle: 'SPONSOR',
  sponsorList: 'Sponsor Management',
  sponsorCreate: 'Add New Sponsor',
  sponsorModify: 'Modify Sponsor',
  sponsorOrder: 'Order Sponsor',
  sponsorUnorder: 'Unorder Sponsor',
  sponsorName: 'Sponsor Name',
  sponsorWeb: 'Sponsor Web',
  sponsorDescription: 'Description',
  sponsorLogo: 'Logo',

  //Booth
  booth: 'Booth',
  boothList: 'Booth List',
  logoOrBanner: 'Logo/Banner',
  eventUser: 'Event User',
  addBooth: 'Add New Booth',
  modifyBooth: 'Modify Booth',
  boothInfo: 'Booth Information',

  //Pitching Event User
  pitchingEventUser: 'Pitching Event User',
  pitchingEventUserList: 'Pitching Event User List',
  pitchingEventUserDetails: 'Pitching Event User Details',
  pitchingEventUserManagement: 'Pitching Event User Management',
  coFounders: 'Co-Founders',
  personalDetails: 'Personal Details',
  downloadDocumentAvailable:
    'Document available for the audience to download (optional)',
  videoAvailable: 'Presentation Video available for the audience (optional)',
  noVideoWasUploaded: 'No video was uploaded',
  questionSolveProblem: 'What problem does it solve?',
  descOfBusiness: 'Description of the business',
  headquarters: 'Headquarters (city, country)',
  startupName: 'Startup Name',
  industry: 'Industry',
  industryOrStudent: 'Industry/Student',
  confirmIsStudent: 'Yes (Degree/field of study)',
  confirmIsIndustry: 'No (Industry/company(optional))',
  hearFrom: 'Hear from',
  notAMemberYet: 'Nuk keni një llogari?',
  unsubscribeText:
    'Ju lutem merrni kohën tuaj për të na dhënë arsyen pse doni të largoheni nga Idrapoll',
  unsubscribeOption1: 'Marr shumë emaile nga IDRApoll dhe kjo më bezdis',
  unsubscribeOption2: 'Nuk preferoj të plotësoj sondazhe në internet',
  unsubscribeOption3: 'Nuk më pëlqejnë shpërblimet dhe dhuratat',
  unsubscribeOption4:
    'Nuk mendoj që përmbajtja e anketimeve është e përshtatshme',
  unsubscribeOption5: 'Tjetër',
};
