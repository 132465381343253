const port = import.meta.env.VITE_APP_PORT || 3000;

export default {
  SAVED_SECURE_TOKEN: `@TMP${port}:secure_token`,
  SAVED_SECURE_REFRESH_TOKEN: `@TMP${port}:secure_refresh_token`,
  CURRENT_LANGUAGE: `@TMP${port}:current_language`,
  SAVED_USER_DATA: `@TMP${port}:udata`,
  SAVED_FULL_NAME: `@TMP${port}:full_name`,
  SAVED_REMEMBER: `@TMP${port}:remember`,
  ROLE_KEY: `@TMP${port}:role_key`,
  LOCALE_KEY: `@TMP${port}:locale`,
  SAVED_LANGUAGE: `@TMP${port}:language`,
};
