import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import _ from 'lodash';
import { IFormStructure } from '@/Interfaces/Auth.interface';
import { LoadingButton } from '@mui/lab';
import mainLogo from '@/Assets/logo.svg';
import Utils from '@/Utils';
import { ROUTERS } from '@/Constants';
import { RootState, useTypedDispatch } from '@/store';
import { AuthActions } from '@/Actions';
import { useSelector } from 'react-redux';
import Widgets from '@/Components/Widgets';

const { login } = AuthActions;

const DEFAULT_PAYLOAD = {
  username: '',
  password: '',
};

const AdminAccountProfile = () => {
  const dispatch = useTypedDispatch();
  const [loginForm, setLoginForm] = React.useState({
    username: '',
    password: '',
  });
  const [payload, setPayload] = React.useState<IFormStructure>(DEFAULT_PAYLOAD);
  const [msg, setMsg] = React.useState<IFormStructure>(DEFAULT_PAYLOAD);
  const [showPassword, setShowPassword] = React.useState(false);
  const [allowRender, setAllowRender] = React.useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const isLoading = useSelector((state: RootState) =>
    _.get(state.AUTH, 'isActionLoading')
  );

  useEffect(() => {
    const token = Utils.getSavedToken();
    const refreshToken = Utils.getSavedRefreshToken();
    if (token && refreshToken) Utils.redirect(ROUTERS.ADMIN_DASHBOARD);
    else setAllowRender(true);
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { isValid, invalidMsg } = validatePayload();
    if (!isValid) setMsg(invalidMsg);
    else {
      dispatch(login(loginForm));
    }
  };

  const handleChange = (key: string, value: string) => {
    const isExistMsg = _.get(msg, key);
    if (isExistMsg) {
      setMsg({
        ...msg,
        [key]: '',
      });
    }
    const newValue = {
      [key]: value,
    };
    setLoginForm({ ...loginForm, [key]: value });
    setPayload({ ...payload, ...newValue });
  };

  const validatePayload = () => {
    const requiredPayload: {
      key: string;
      label: string;
      value: any;
      type: string;
    }[] = [
      {
        key: 'username',
        label: 'username',
        value: payload.username,
        type: 'username',
      },
      {
        key: 'password',
        label: 'Password',
        value: payload.password,
        type: 'password',
      },
    ];

    const result = Utils.detectValidPayload(requiredPayload);

    return result;
  };

  const _renderInputs = () => (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        error={!_.isEmpty(msg.username)}
        margin="normal"
        fullWidth
        key="email"
        helperText={msg.username}
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(e: any) => handleChange('username', e.target.value)}
        required
      />
      <TextField
        error={!_.isEmpty(msg.password)}
        margin="normal"
        fullWidth
        name="password"
        helperText={msg.password}
        label="Password"
        type={showPassword ? 'text' : 'password'}
        key="password"
        autoComplete="current-password"
        onChange={(e) => handleChange('password', e.target.value)}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword}>
                {!showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LoadingButton
        type="submit"
        color="burntSienna"
        fullWidth
        variant="contained"
        loading={isLoading}
        sx={{ mt: 3, mb: 2 }}
      >
        Login
      </LoadingButton>
    </Box>
  );
  const renderMain = () => {
    if (!allowRender) return <></>;
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100vh' }}
      >
        <Grid item>
          <Grid container>
            <Grid item xs={12} textAlign={'center'}>
              <Box
                component="img"
                alt="monitoringmatrix"
                src={mainLogo}
                sx={{ width: 0.5 }}
              />
            </Grid>
          </Grid>
          <Container maxWidth="xs">
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              {_renderInputs()}
            </Grid>
          </Container>
        </Grid>
        <Widgets.Alert />
        {/* <Widgets.Notification /> */}
      </Grid>
    );
  };

  return <Box>{renderMain()}</Box>;
};

export default AdminAccountProfile;
