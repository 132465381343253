export default {
  noDataWereFound: 'No data were found',
  rowPerPage: 'Rows per page',

  no: 'Nr',
  email: 'Email',
  role: 'Roli',
  action: 'Veprim',
  name: 'Emri',
  logo: 'Logo',
  reason: 'Arsyeja',
  content: 'Përmbajtja',
  title: 'Titull',
  date: 'Date',
  landingPage: 'Faqja e uljes',
  published: 'Publikuar',
  country: 'Shteti',
};
