import * as React from 'react';
import { Box, Fade, Modal, Typography, Backdrop } from '@mui/material';

const style = {
  overflowX: 'hidden',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  position: 'fixed',
  top: '50vh',
  left: '50vw',
  maxHeight: '95vh',
  maxWidth: '90vw',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '5px',
  // pb: 3,
  px: 3,
  '@media screen and (max-width: 1000px)': {
    maxWidth: '60vw',
  },
};

interface IProps {
  open: boolean;
  onClose(): void;
  title: string;
  content?: JSX.Element | null;
  children?: JSX.Element | null;
}

const DialogLayout: React.FC<IProps> = ({
  open,
  onClose,
  title,
  content,
  children,
}) => {
  const handleClose = (
    _event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'escapeKeyDown') onClose();
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      // slots={{ backdrop: Backdrop }} // Deprecated in new version
      components={{ Backdrop }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography mb="15px" fontSize="20px" fontWeight="500">
            {title}
          </Typography>
          {content || children}
        </Box>
      </Fade>
    </Modal>
  );
};

export default DialogLayout;
