import * as AuthApi from './Authentication.api';
import * as SystemUser from './SystemUser.api';
import * as AboutUs from './AboutUs.api';
import * as Rewards from './Rewards.api';
import * as ProductAndServices from './ProductsAndServices.api';
import * as WhyIDRApoll from './WhyIdraPoll.api';
import * as Upload from './Upload.api';
import * as Custommers from './Custommers.api';
import * as Results from './Results.api';
import * as Statistical from './Statistical.api';
import * as Contact from './Contact.api';
import * as Inbox from './Inbox.api';

export default {
  ...AuthApi,
  ...SystemUser,
  ...AboutUs,
  ...Rewards,
  ...ProductAndServices,
  ...WhyIDRApoll,
  ...Upload,
  ...Custommers,
  ...Results,
  ...Statistical,
  ...Contact,
  ...Inbox,
};
