import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import { labelInput, buttonImage } from './WhatIDRApoll.styles';
import {
  IMessageWhatIDRApoll,
  IWhatIDRApollData,
} from '@/Interfaces/WhatIDRApoll.interface';
import { LIST } from '@constants';
import { RootState, useTypedDispatch } from '@/store';
import AboutUsAction from '@/Actions/AboutUs.action';
import { useSelector } from 'react-redux';
import { TextEditor } from '@/Components/Common';
import Media from '@/Components/Dialogs/Media';

const { updateAboutUs, getAboutUs } = AboutUsAction;

const DEFAULT_DATA_ABOUT_US = {
  title: '',
  // shortDescription: '',
  detailDescription: '',
  imageFirstGift: '',
  descriptionFirstGift: '',
  imageSecondGift: '',
  descriptionSecondGift: '',
  imageThirdGift: '',
  descriptionThirdGift: '',
};

const WhatIDRAPoll = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [isOpenMedia, setIsOpenMedia] = useState<boolean>(false);
  const [typeImage, setTypeImage] = useState<string>();
  const [currentLanguage, setCurrentLanguage] = React.useState<string>('en');
  const [dataIDRApoll, setDataIDRApoll] = React.useState<IWhatIDRApollData>(
    DEFAULT_DATA_ABOUT_US
  );

  const [isMessage, setIsMessage] = React.useState<IMessageWhatIDRApoll>({
    title: '',
  });

  const dataAboutUs: IWhatIDRApollData = useSelector((state: RootState) =>
    _.get(state.ABOUT_US, 'aboutUs')
  );

  const isGetLoading: boolean = useSelector((state: RootState) =>
    _.get(state.ABOUT_US, 'isGetLoading')
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.ABOUT_US, 'isActionLoading')
  );

  useEffect(() => {
    if (currentLanguage) dispatch(getAboutUs(currentLanguage));
    return () => {
      setDataIDRApoll(DEFAULT_DATA_ABOUT_US);
    };
  }, [currentLanguage]);

  useEffect(() => {
    if (!_.isEmpty(dataAboutUs)) setDataIDRApoll(dataAboutUs);
  }, [dataAboutUs]);

  const handleChangeLanguage = (e: string) => {
    setCurrentLanguage(e);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!_.trim(dataIDRApoll.title)) {
      if (!_.trim(dataIDRApoll.title))
        setIsMessage({
          ...isMessage,
          title: `${t('message.canNotLeaveTheTitleBlank')}`,
        });
    } else dispatch(updateAboutUs(currentLanguage, dataIDRApoll));
  };

  const _renderHeader = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
          variant="h4"
        >
          {t('title.whatIsIDRApoll')}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  const _renderUploadImage = () => {
    return (
      <Grid container sx={{ ...buttonImage }} spacing={2}>
        <Grid item xs={8}>
          <TextField
            value={dataIDRApoll.descriptionFirstGift || ''}
            label={t('label.descriptionForFirstGif')}
            multiline
            rows={2}
            fullWidth
            onChange={(e) =>
              setDataIDRApoll({
                ...dataIDRApoll,
                descriptionFirstGift: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            label={t('label.imageFirstGift')}
            fullWidth
            value={dataIDRApoll.imageFirstGift || ''}
            onChange={(e) =>
              setDataIDRApoll({
                ...dataIDRApoll,
                imageFirstGift: e.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsOpenMedia(true);
                      setTypeImage('imageFirstGift');
                    }}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            label={t('label.descriptionForSecondGif')}
            value={dataIDRApoll.descriptionSecondGift || ''}
            multiline
            rows={2}
            fullWidth
            onChange={(e) =>
              setDataIDRApoll({
                ...dataIDRApoll,
                descriptionSecondGift: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            label={t('label.imageSecondGift')}
            fullWidth
            value={dataIDRApoll.imageSecondGift || ''}
            onChange={(e) =>
              setDataIDRApoll({
                ...dataIDRApoll,
                imageSecondGift: e.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsOpenMedia(true);
                      setTypeImage('imageSecondGift');
                    }}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            value={dataIDRApoll.descriptionThirdGift || ''}
            label="Detailed description"
            multiline
            rows={2}
            fullWidth
            onChange={(e) =>
              setDataIDRApoll({
                ...dataIDRApoll,
                descriptionThirdGift: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            label={t('label.imageThirdGift')}
            fullWidth
            value={dataIDRApoll.imageThirdGift || ''}
            onChange={(e) =>
              setDataIDRApoll({
                ...dataIDRApoll,
                imageThirdGift: e.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setIsOpenMedia(true);
                      setTypeImage('imageThirdGift');
                    }}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const _renderFooterWhatIDRApoll = () => {
    return (
      <Grid container>
        <Grid item mt={2} textAlign={'end'} xs={12}>
          <LoadingButton
            variant="contained"
            color="diSerria"
            sx={{ mr: 2 }}
            loading={isGetLoading || isActionLoading}
            onClick={() => setDataIDRApoll(DEFAULT_DATA_ABOUT_US)}
          >
            {t('button.reset')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            color="oceanGreen"
            loading={isGetLoading || isActionLoading}
          >
            {t('button.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3} display={'flex'}>
          <InputLabel
            sx={{
              ...labelInput,
              minWidth: 80,
            }}
          >
            {t('label.language')}
          </InputLabel>
          <Select
            value={currentLanguage}
            fullWidth
            size="small"
            onChange={(e: any) => handleChangeLanguage(e.target.value)}
            sx={{
              minWidth: '80px',
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
              height: '45px',
            }}
          >
            {LIST.DROPDOWN_LIST.OPTIONS_LANGUAGE.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel
                sx={{
                  ...labelInput,
                }}
              >
                {t('label.title')}
              </InputLabel>
              <TextField
                value={dataIDRApoll.title || ''}
                size="small"
                label={t('label.title')}
                required
                fullWidth
                error={!_.isEmpty(isMessage.title)}
                helperText={isMessage.title}
                onChange={(e) => {
                  setDataIDRApoll({ ...dataIDRApoll, title: e.target.value });
                  setIsMessage({ ...isMessage, title: '' });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                sx={{
                  ...labelInput,
                }}
              >
                {t('label.detailDescription')}
              </InputLabel>
              <TextEditor
                value={dataIDRApoll?.detailDescription || ''}
                onChange={(e: string) => {
                  setDataIDRApoll({
                    ...dataIDRApoll,
                    detailDescription: e,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} ml={2} mt={2}>
          {_renderUploadImage()}
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2}>
        {_renderHeader()}
        <Grid item xs={12} mt={2} component="form" onSubmit={handleSubmit}>
          {_renderBody()}
          {_renderFooterWhatIDRApoll()}
        </Grid>
        {isOpenMedia && (
          <Media
            onOpen={{ status: isOpenMedia, type: typeImage }}
            onClose={() => {
              setIsOpenMedia(false);
            }}
            callback={(file: any, type: string) => {
              if (type) {
                if (type === 'imageFirstGift') {
                  setDataIDRApoll({
                    ...dataIDRApoll,
                    imageFirstGift: file,
                  });
                  setIsOpenMedia(false);
                }
                if (type === 'imageSecondGift') {
                  setDataIDRApoll({
                    ...dataIDRApoll,
                    imageSecondGift: file,
                  });
                  setIsOpenMedia(false);
                }
                if (type === 'imageThirdGift') {
                  setDataIDRApoll({
                    ...dataIDRApoll,
                    imageThirdGift: file,
                  });
                  setIsOpenMedia(false);
                }
              }
            }}
          />
        )}
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default WhatIDRAPoll;
