import React, { useEffect } from 'react';
import _ from 'lodash';

import { Box, Divider, Grid, Typography } from '@mui/material';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import DefaultLayout from '@/Components/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { CommonColors } from '@/Themes';
import { StatisticalActions, ResultsActions } from '@/Actions';
import { IDataCreateStatistical } from '@/Interfaces/Statistical.interface';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import { IResults } from '@/Interfaces/Results.interface';
const { fetchStatistical } = StatisticalActions;
const { fetchResults } = ResultsActions;

const DEFAULT_DATA_STATISTICAL = {
  languageCode: 'en',
  statistics: [
    { title: '', number: '' },
    { title: '', number: '' },
    { title: '', number: '' },
  ],
  titleGrahpAge: '',
  detailGrahpAge: [{ title: '', number: '' }],
  titleGrahpGender: '',
  detailGrahpGender: [{ title: '', number: '' }],
  titleGrahpDistribution: '',
  detailGrahpDistribution: [{ title: '', number: '' }],
  titleGrahpEducation: '',
  detailGrahpEducation: [{ title: '', number: '' }],
  titleGrahpEmployment: '',
  detailGrahpEmployment: [{ title: '', number: '' }],
};

const Dashboard: React.FC = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [statistical, setStatistical] = React.useState<IDataCreateStatistical>(
    DEFAULT_DATA_STATISTICAL
  );
  const payload: IDataCreateStatistical = useSelector((state: RootState) =>
    _.get(state.STATISTICAL, 'statistical')
  );

  const payloadResult: IResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'results')
  );

  useEffect(() => {
    dispatch(fetchStatistical(statistical.languageCode));
  }, [statistical.languageCode]);

  
  useEffect(() => {
    dispatch(fetchResults({limit: 1000, page: 1}));
  }, []);

  useEffect(() => {
    if (!_.isEmpty(payload)) setStatistical(payload);
  }, [payload]);

  const _renderHeader = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
          variant="h4"
        >
          {t('title.dashboard')}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  const renderStatistics = () => {
    const render = _.map(statistical.statistics, (item: any, index: number) => {
      return (
        <Grid item xs={5} sx={{ border: '1px solid #C0C0C0', p: 3 }} key={index}>
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>+{item.number} {item.title}</Typography>
          <Box display={'flex'} justifyContent={'end'} mt={2}>
            {index == 0 && (
              <ContactPageIcon sx={{ fontSize: 45 }} color="primary" />
            )}
            {index == 1 && (
              <PersonIcon sx={{ fontSize: 45 }} color="primary" />
            )}
            {index == 2 && (
              <AccountTreeIcon sx={{ fontSize: 45 }} color="primary" />
            )}

          </Box>
        </Grid>
      )
    });
    return render;
  }


  const renderResults = () => {
    return (
      <Grid item xs={5} sx={{ border: '1px solid #C0C0C0', p: 3 }}>
        <Typography sx={{ fontSize: 20, fontWeight: 500 }}>+{payloadResult?.data?.length} Results</Typography>
        <Box display={'flex'} justifyContent={'end'} mt={2}>
              <FileCopyIcon sx={{ fontSize: 45 }} color="primary" />

        </Box>
      </Grid>
    )
  }

  const _renderBody = () => {
    return (
      <Grid container display={'flex'} justifyContent={'center'} mt={8}>
        <Grid item xs={8} sx={{ border: '1px solid', px: 16, py: 8 }}>
          <Grid container display={'flex'} justifyContent={'center'}>
            {renderStatistics()}
            {renderResults()}
            {/* <Grid item xs={5} sx={{ border: '1px solid #C0C0C0', p: 3 }}>
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                +5000 Panelistë
              </Typography>
              <Box display={'flex'} justifyContent={'end'} mt={2}>
                <ContactPageIcon sx={{ fontSize: 45 }} color="primary" />
              </Box>
            </Grid>
            <Grid item xs={5} sx={{ border: '1px solid #C0C0C0', p: 3 }}>
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                +70 Rezultate
              </Typography>
              <Box display={'flex'} justifyContent={'end'} mt={2}>
                <FileCopyIcon sx={{ fontSize: 45 }} color="primary" />
              </Box>
            </Grid>
            <Grid item xs={5} sx={{ border: '1px solid #C0C0C0', p: 3 }}>
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                +50 Klientë
              </Typography>
              <Box display={'flex'} justifyContent={'end'} mt={2}>
                <PersonIcon sx={{ fontSize: 45 }} color="primary" />
              </Box>
            </Grid>
            <Grid item xs={5} sx={{ border: '1px solid #C0C0C0', p: 3 }}>
              <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                +100 Projekte
              </Typography>
              <Box display={'flex'} justifyContent={'end'} mt={2}>
                <AccountTreeIcon sx={{ fontSize: 45 }} color="primary" />
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container pb={2}>
        <Grid item xs={12}>
          {_renderHeader()}
        </Grid>
        <Grid item xs={12}>
          {_renderBody()}
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default Dashboard;
