import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';
import {
  IDataCreateResults,
  IDataUpdateResults,
} from '@/Interfaces/Results.interface';

const { RESULT } = API;

export const createResults = async (payload: IDataCreateResults) => {
  return await sendRequest(RESULT.ROOT, 'POST', payload);
};

export const fetchResults = async (payload: IPaginationFilter) => {
  return sendRequest(RESULT.ROOT, 'GET', payload);
};

export const fetchResultsHomepage = async (payload: IPaginationFilter) => {
  return sendRequest(`${RESULT.ROOT}homepage`, 'GET', payload);
};

export const getResultsById = async (id: string) => {
  return sendRequest(`${RESULT.ROOT}${id}`, 'GET');
};

export const updateResults = async (
  id: string,
  payload: IDataUpdateResults
) => {
  return sendRequest(`${RESULT.ROOT}${id}`, 'PUT', payload);
};

export const publishedResults = async (id: string, payload: any) => {
  return sendRequest(`${RESULT.ROOT}${id}${RESULT.PUBLISH}`, 'PUT', payload);
};

export const landingpageResults = async (id: string, payload: any) => {
  return sendRequest(
    `${RESULT.ROOT}${id}${RESULT.LANDINGPAGE}`,
    'PUT',
    payload
  );
};

export const deleteResults = async (id: string) => {
  return sendRequest(`${RESULT.ROOT}${id}`, 'DEL');
};
