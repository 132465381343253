export default {
  areYouSureYouWantToDelelteResult: 'Are you sure you want to delelte result?',
  areYouSureYouWantToDeleteCustomer:
    'Are you sure you want to delete customer?',
  areYouSureYouWantTo: 'Are you sure you want to',
  thisAccount: 'this account',
  inactive: 'inactive',
  activated: 'activated',
  dragDropSomeFilesHereOrClickToSelectFiles:
    'Drag and drop some files here, or click to select files',
  dropTheFilesHere: 'Drop the files here',
  allowFileTypes: 'Allow file types',
  areYouSureYouWantToDelelteImage:
    'Are you sure you want to delelte result Image',
  canNotLeaveTheTitleBlank: 'Can not leave the title blank!',
  imageDescriptionCannotBeEmpty: 'ImageDescription cannot be empty!',
  imageUrlCannotBeEmpty: 'ImageUrl cannot be empty!',
  contentCannotBeEmpty: 'Content cannot be empty!',
  canNotLeaveTheNameBlank: 'Can not leave the name blank!',
  canNotLeaveTheUrlBlank: 'Can not leave the url blank!',
  canNotLeaveTheLogoBlank: 'Can not leave the logo blank!',
  nameIsRequired: 'Name is required',
  unsubscribeSuccessFully:
    'Thank you for your time with us. We have successfully processed your unsubscribe request.',
  sendContactSuccessFully:
    'Thank you for your time with us. We have successfully processed your contact request.',
  pleaseEnterAValidFullName: '*Please enter a valid full name.',
  yourPasswordMustBeAtLeast8Characters:
    '*Your password must be at least 8 characters.',
  thesePasswordDontMatch: "*These password don't match.",
  areYouSureYouWantToChangeTheLandingPage:
    'Are you sure you want to change the landing page?',
  areYouSureYouWantToPublish: 'Are you sure you want to publish',
  areYouSureYouWantToUnpublish: 'Are you sure you want to unpublish',
  thereIsNoResultsToDisplay: 'There is no results to display',
};
