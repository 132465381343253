import { SxProps, Theme } from '@mui/system';

export const labelInput: SxProps<Theme> = {
  fontWeight: '500',
  fontSize: 16,
  my: 1,
  mr: 1,
};

export const buttonImage: SxProps<Theme> = {
  border: '1px solid ',
  p: 2,
};
