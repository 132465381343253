import React, { useEffect } from 'react';

import DefaultLayout from '@/Components/DefaultLayout';
import { CommonColors } from '@/Themes';
import _ from 'lodash';

import {
  Button,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { LIST } from '@/Constants';
import { IDataCreateStatistical } from '@/Interfaces/Statistical.interface';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useTypedDispatch } from '@/store';
import { StatisticalActions } from '@/Actions';

const { fetchStatistical, createStatistical, updateStatistical } =
  StatisticalActions;

const DEFAULT_DATA_STATISTICAL = {
  languageCode: 'en',
  statistics: [
    { title: '', number: '' },
    { title: '', number: '' },
    { title: '', number: '' },
  ],
  titleGrahpAge: '',
  detailGrahpAge: [{ title: '', number: '' }],
  titleGrahpGender: '',
  detailGrahpGender: [{ title: '', number: '' }],
  titleGrahpDistribution: '',
  detailGrahpDistribution: [{ title: '', number: '' }],
  titleGrahpEducation: '',
  detailGrahpEducation: [{ title: '', number: '' }],
  titleGrahpEmployment: '',
  detailGrahpEmployment: [{ title: '', number: '' }],
};

const Statistical = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();

  const [statistical, setStatistical] = React.useState<IDataCreateStatistical>(
    DEFAULT_DATA_STATISTICAL
  );
  const [currentLanguage, setCurrentLanguage] = React.useState<string>('en');
  const payload: IDataCreateStatistical = useSelector((state: RootState) =>
    _.get(state.STATISTICAL, 'statistical')
  );

  useEffect(() => {
    dispatch(fetchStatistical(currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if (!_.isEmpty(payload)) {
      setStatistical(payload);
    } else setStatistical(DEFAULT_DATA_STATISTICAL);
  }, [payload]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!_.isEmpty(payload)) {
      dispatch(
        updateStatistical({ ...statistical, languageCode: currentLanguage })
      );
    } else
      dispatch(
        createStatistical({ ...statistical, languageCode: currentLanguage })
      );
  };

  const handRemove = (key: number, type: string) => {
    if (type === 'detailGrahpAge') {
      const newForm = _.filter(
        statistical.detailGrahpAge,
        (_item: any, index: number) => index !== key
      );
      setStatistical({ ...statistical, detailGrahpAge: newForm });
    }
    if (type === 'detailGrahpGender') {
      const newForm = _.filter(
        statistical.detailGrahpGender,
        (_item: any, index: number) => index !== key
      );
      setStatistical({ ...statistical, detailGrahpGender: newForm });
    }
    if (type === 'detailGrahpDistribution') {
      const newForm = _.filter(
        statistical.detailGrahpDistribution,
        (_item: any, index: number) => index !== key
      );
      setStatistical({ ...statistical, detailGrahpDistribution: newForm });
    }
    if (type === 'detailGrahpEducation') {
      const newForm = _.filter(
        statistical.detailGrahpEducation,
        (_item: any, index: number) => index !== key
      );
      setStatistical({ ...statistical, detailGrahpEducation: newForm });
    }

    if (type === 'detailGrahpEmployment') {
      const newForm = _.filter(
        statistical.detailGrahpEmployment,
        (_item: any, index: number) => index !== key
      );
      setStatistical({ ...statistical, detailGrahpEmployment: newForm });
    }
  };

  const handleAddMoreContent = (type: string) => {
    if (type === 'detailGrahpAge') {
      const newAddmore = [
        ...statistical.detailGrahpAge,
        { title: '', number: '' },
      ];
      setStatistical({ ...statistical, detailGrahpAge: newAddmore });
    }
    if (type === 'detailGrahpDistribution') {
      const newAddmore = [
        ...statistical.detailGrahpDistribution,
        { title: '', number: '' },
      ];
      setStatistical({ ...statistical, detailGrahpDistribution: newAddmore });
    }
    if (type === 'detailGrahpGender') {
      const newAddmore = [
        ...statistical.detailGrahpGender,
        { title: '', number: '' },
      ];
      setStatistical({ ...statistical, detailGrahpGender: newAddmore });
    }
    if (type === 'detailGrahpEducation') {
      const newAddmore = [
        ...statistical.detailGrahpEducation,
        { title: '', number: '' },
      ];
      setStatistical({ ...statistical, detailGrahpEducation: newAddmore });
    }
    if (type === 'detailGrahpEmployment') {
      const newAddmore = [
        ...statistical.detailGrahpEmployment,
        { title: '', number: '' },
      ];
      setStatistical({ ...statistical, detailGrahpEmployment: newAddmore });
    }
  };

  const handleOnChange = (
    contentKey: string,
    value: string,
    key: number,
    type: string
  ) => {
    if (type === 'statistical') {
      const newForm = _.map(statistical.statistics, (item, index) => {
        if (index === key) {
          return {
            ...item,
            [contentKey]: value,
          };
        }
        return item;
      });
      setStatistical({ ...statistical, statistics: newForm });
    }
    if (type === 'grahpGender') {
      const newForm = _.map(
        statistical.detailGrahpGender,
        (item: any, index: number) => {
          if (index === key) {
            return {
              ...item,
              [contentKey]: value,
            };
          }
          return item;
        }
      );
      setStatistical({ ...statistical, detailGrahpGender: newForm });
    }
    if (type === 'grahpAge') {
      const newForm = _.map(
        statistical.detailGrahpAge,
        (item: any, index: number) => {
          if (index === key) {
            return {
              ...item,
              [contentKey]: value,
            };
          }
          return item;
        }
      );
      setStatistical({ ...statistical, detailGrahpAge: newForm });
    }
    if (type === 'grahpDistribution') {
      const newForm = _.map(
        statistical.detailGrahpDistribution,
        (item: any, index: number) => {
          if (index === key) {
            return {
              ...item,
              [contentKey]: value,
            };
          }
          return item;
        }
      );
      setStatistical({ ...statistical, detailGrahpDistribution: newForm });
    }
    if (type === 'grahpEducation') {
      const newForm = _.map(
        statistical.detailGrahpEducation,
        (item: any, index: number) => {
          if (index === key) {
            return {
              ...item,
              [contentKey]: value,
            };
          }
          return item;
        }
      );
      setStatistical({ ...statistical, detailGrahpEducation: newForm });
    }
    if (type === 'grahpEmployment') {
      const newForm = _.map(
        statistical.detailGrahpEmployment,
        (item: any, index: number) => {
          if (index === key) {
            return {
              ...item,
              [contentKey]: value,
            };
          }
          return item;
        }
      );
      setStatistical({ ...statistical, detailGrahpEmployment: newForm });
    }
  };

  const _renderHeader = () => {
    return (
      <Grid item xs={12}>
        <Typography
          sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
          variant="h4"
        >
          {t('title.statistical')}
        </Typography>
        <Divider />
      </Grid>
    );
  };

  const _renderStatistical = () => {
    return (
      <Grid container spacing={4}>
        {_.map(statistical.statistics, (item: any, index: number) => {
          return (
            <Grid
              item
              xs={6}
              key={`row${statistical.statistics.length}-${index}`}
            >
              <Grid container spacing={2}>
                <InputLabel
                  sx={{ fontWeight: '500', fontSize: 16, mt: 1, mb: 1 }}
                >
                  {t('label.statistical')} {index + 1}
                </InputLabel>
                <Grid item xs={12}>
                  <Grid
                    container
                    sx={{
                      border: '1px solid',
                      p: 2,
                    }}
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <TextField
                        value={item.title}
                        size="small"
                        label={t('label.title')}
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'title',
                            e.target.value,
                            index,
                            'statistical'
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t('label.number')}
                        type="number"
                        value={item.number}
                        onChange={(e) =>
                          handleOnChange(
                            'number',
                            e.target.value,
                            index,
                            'statistical'
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const _rendeGraphAge = () => {
    const graphAge = statistical.detailGrahpAge;
    return (
      <Grid container>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              mr: 1,
            }}
          >
            {t('label.graphAge')}
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ border: '1px solid', p: 4, mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t('label.title')}
                fullWidth
                value={statistical.titleGrahpAge}
                onChange={(e) =>
                  setStatistical({
                    ...statistical,
                    titleGrahpAge: e.target.value,
                  })
                }
              />
            </Grid>
            {_.map(graphAge, (item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={`row${statistical.detailGrahpAge.length}-${index}`}
                >
                  <InputLabel
                    sx={{
                      fontWeight: '500',
                      fontSize: 16,
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    {t('label.content')} {index + 1}
                  </InputLabel>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      border: '1px solid',
                      p: 4,
                      mt: 1,
                      position: 'relative',
                    }}
                  >
                    <Button
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      color="error"
                      onClick={() => handRemove(index, 'detailGrahpAge')}
                      disabled={statistical.detailGrahpAge.length <= 1}
                    >
                      <CloseIcon />
                    </Button>
                    <Grid item xs={10}>
                      <TextField
                        value={item.title}
                        size="small"
                        label={t('label.title')}
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'title',
                            e.target.value,
                            index,
                            'grahpAge'
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={item.number}
                        size="small"
                        label={'Number'}
                        type="number"
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'number',
                            e.target.value,
                            index,
                            'grahpAge'
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item textAlign={'center'} xs={12}>
              <Button onClick={() => handleAddMoreContent('detailGrahpAge')}>
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} my={4}>
          <Divider
            variant="inset"
            sx={{
              width: 0.8,
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const _detailGrahpGender = () => {
    const detailGrahpGender = statistical.detailGrahpGender;
    return (
      <Grid container>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              mr: 1,
            }}
          >
            {t('label.grahpGender')}
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ border: '1px solid', p: 4, mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                value={statistical.titleGrahpGender}
                size="small"
                label={t('label.title')}
                fullWidth
                onChange={(e) =>
                  setStatistical({
                    ...statistical,
                    titleGrahpGender: e.target.value,
                  })
                }
              />
            </Grid>
            {_.map(detailGrahpGender, (item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={`row${statistical.detailGrahpGender.length}-${index}`}
                >
                  <InputLabel
                    sx={{
                      fontWeight: '500',
                      fontSize: 16,
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    {t('label.content')} {index + 1}
                  </InputLabel>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      border: '1px solid',
                      p: 4,
                      mt: 1,
                      position: 'relative',
                    }}
                  >
                    <Button
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      color="error"
                      onClick={() => handRemove(index, 'detailGrahpGender')}
                      disabled={statistical.detailGrahpGender.length <= 1}
                    >
                      <CloseIcon />
                    </Button>
                    <Grid item xs={10}>
                      <TextField
                        value={item.title}
                        size="small"
                        label={t('label.title')}
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'title',
                            e.target.value,
                            index,
                            'grahpGender'
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={item.number}
                        size="small"
                        label={'Number'}
                        type="number"
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'number',
                            e.target.value,
                            index,
                            'grahpGender'
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item textAlign={'center'} xs={12}>
              <Button onClick={() => handleAddMoreContent('detailGrahpGender')}>
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} my={4}>
          <Divider
            variant="inset"
            sx={{
              width: 0.8,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const _detailGrahpDistribution = () => {
    const detailGrahpDistribution = statistical.detailGrahpDistribution;
    return (
      <Grid container>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              mr: 1,
            }}
          >
            {t('label.grahpDistribution')}
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ border: '1px solid', p: 4, mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                value={statistical.titleGrahpDistribution}
                size="small"
                label={t('label.title')}
                fullWidth
                onChange={(e) =>
                  setStatistical({
                    ...statistical,
                    titleGrahpDistribution: e.target.value,
                  })
                }
              />
            </Grid>
            {_.map(detailGrahpDistribution, (item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={`row${statistical.detailGrahpDistribution.length}-${index}`}
                >
                  <InputLabel
                    sx={{
                      fontWeight: '500',
                      fontSize: 16,
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    {t('label.content')} {index + 1}
                  </InputLabel>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      border: '1px solid',
                      p: 4,
                      mt: 1,
                      position: 'relative',
                    }}
                  >
                    <Button
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      color="error"
                      onClick={() =>
                        handRemove(index, 'detailGrahpDistribution')
                      }
                      disabled={statistical.detailGrahpDistribution.length <= 1}
                    >
                      <CloseIcon />
                    </Button>
                    <Grid item xs={10}>
                      <TextField
                        value={item.title}
                        size="small"
                        label={t('label.title')}
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'title',
                            e.target.value,
                            index,
                            'grahpDistribution'
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={item.number}
                        size="small"
                        label={'Number'}
                        type="number"
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'number',
                            e.target.value,
                            index,
                            'grahpDistribution'
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item textAlign={'center'} xs={12}>
              <Button
                onClick={() => handleAddMoreContent('detailGrahpDistribution')}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} my={4}>
          <Divider
            variant="inset"
            sx={{
              width: 0.8,
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const _detailGrahpEducation = () => {
    const detailGrahpEducation = statistical.detailGrahpEducation;
    return (
      <Grid container>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              mr: 1,
            }}
          >
            {t('label.grahpEducation')}
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ border: '1px solid', p: 4, mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t('label.title')}
                fullWidth
                value={statistical.titleGrahpEducation}
                onChange={(e) =>
                  setStatistical({
                    ...statistical,
                    titleGrahpEducation: e.target.value,
                  })
                }
              />
            </Grid>
            {_.map(detailGrahpEducation, (item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={`row${statistical.detailGrahpEducation.length}-${index}`}
                >
                  <InputLabel
                    sx={{
                      fontWeight: '500',
                      fontSize: 16,
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    {t('label.content')} {index + 1}
                  </InputLabel>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      border: '1px solid',
                      p: 4,
                      mt: 1,
                      position: 'relative',
                    }}
                  >
                    <Button
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      color="error"
                      onClick={() => handRemove(index, 'detailGrahpEducation')}
                      disabled={statistical.detailGrahpEducation.length <= 1}
                    >
                      <CloseIcon />
                    </Button>
                    <Grid item xs={10}>
                      <TextField
                        value={item.title}
                        size="small"
                        label={t('label.title')}
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'title',
                            e.target.value,
                            index,
                            'grahpEducation'
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={item.number}
                        size="small"
                        label={'Number'}
                        type="number"
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'number',
                            e.target.value,
                            index,
                            'grahpEducation'
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item textAlign={'center'} xs={12}>
              <Button
                onClick={() => handleAddMoreContent('detailGrahpEducation')}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} my={4}>
          <Divider
            variant="inset"
            sx={{
              width: 0.8,
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const _detailGrahpEmployment = () => {
    const detailGrahpEmployment = statistical.detailGrahpEmployment;
    return (
      <Grid container>
        <Grid item xs={12}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              mr: 1,
            }}
          >
            {t('label.grahpEmployment')}
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ border: '1px solid', p: 4, mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                size="small"
                label={t('label.title')}
                fullWidth
                value={statistical.titleGrahpEmployment}
                onChange={(e) =>
                  setStatistical({
                    ...statistical,
                    titleGrahpEmployment: e.target.value,
                  })
                }
              />
            </Grid>
            {_.map(detailGrahpEmployment, (item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={`row${statistical.detailGrahpEmployment.length}-${index}`}
                >
                  <InputLabel
                    sx={{
                      fontWeight: '500',
                      fontSize: 16,
                      mt: 1,
                      mr: 1,
                    }}
                  >
                    {t('label.content')} {index + 1}
                  </InputLabel>
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      border: '1px solid',
                      p: 4,
                      mt: 1,
                      position: 'relative',
                    }}
                  >
                    <Button
                      sx={{ position: 'absolute', top: 0, right: 0 }}
                      color="error"
                      onClick={() => handRemove(index, 'detailGrahpEmployment')}
                      disabled={statistical.detailGrahpEmployment.length <= 1}
                    >
                      <CloseIcon />
                    </Button>
                    <Grid item xs={10}>
                      <TextField
                        value={item.title}
                        size="small"
                        label={t('label.title')}
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'title',
                            e.target.value,
                            index,
                            'grahpEmployment'
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={item.number}
                        size="small"
                        label={'Number'}
                        type="number"
                        fullWidth
                        onChange={(e) =>
                          handleOnChange(
                            'number',
                            e.target.value,
                            index,
                            'grahpEmployment'
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item textAlign={'center'} xs={12}>
              <Button
                onClick={() => handleAddMoreContent('detailGrahpEmployment')}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderTitleGraph = () => {
    return (
      <Grid container>
        <InputLabel sx={{ fontWeight: '500', fontSize: 16, mt: 1 }}>
          {t('label.content')}
        </InputLabel>
        <Grid item xs={12}>
          <Divider />
          <Grid container spacing={2} mt={2}>
            {_rendeGraphAge()}
            {_detailGrahpGender()}
            {_detailGrahpDistribution()}
            {_detailGrahpEducation()}
            {_detailGrahpEmployment()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3} display={'flex'}>
          <InputLabel
            sx={{
              fontWeight: '500',
              fontSize: 16,
              mt: 1,
              minWidth: 80,
            }}
          >
            {t('label.language')}
          </InputLabel>
          <Select
            value={currentLanguage}
            fullWidth
            size="small"
            onChange={(e: any) => {
              setStatistical({ ...statistical, languageCode: e.target.value });
              setCurrentLanguage(e.target.value);
            }}
            sx={{
              minWidth: '80px',
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
              height: '45px',
            }}
          >
            {LIST.DROPDOWN_LIST.OPTIONS_LANGUAGE.map((item, index) => {
              return (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12}>
          {_renderStatistical()}
        </Grid>
        <Grid item xs={12}>
          {_renderTitleGraph()}
        </Grid>
      </Grid>
    );
  };

  const _renderFooter = () => {
    return (
      <Grid item mt={2} textAlign={'end'} xs={12}>
        <LoadingButton
          variant="contained"
          color="diSerria"
          sx={{ mr: 2 }}
          onClick={() => setStatistical(DEFAULT_DATA_STATISTICAL)}
        >
          {t('button.reset')}
        </LoadingButton>
        <LoadingButton type="submit" variant="contained" color="oceanGreen">
          {t('button.save')}
        </LoadingButton>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container p={2}>
        {_renderHeader()}
        <Grid item xs={12} mt={2} component="form" onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              {_renderBody()}
            </Grid>
            <Grid item xs={12}>
              {_renderFooter()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default Statistical;
