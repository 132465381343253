import { ACTION_TYPES } from '@/Constants';
import API from '@/APIs';
import Utils from '@/Utils';
import _ from 'lodash';

const clearUpload = () => {
  return {
    type: ACTION_TYPES.CLEAR_UPLOAD,
  };
};

const setUploadLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_UPLOAD_ACTION_LOADING,
    payload,
  };
};

const fetchUploadLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_UPLOAD_FETCH_LOADING,
    payload,
  };
};

const getUploadLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_UPLOAD_GET_LOADING,
    payload,
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_UPLOAD_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_UPLOAD_FAILURE,
  };
};

const fetchGalleries = () => {
  return async (dispatch: any) => {
    dispatch(fetchUploadLoading(true));
    await API.fetchGalleries()
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else dispatch(fetchSuccess(result));
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const getByIdSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_UPLOAD_BY_ID_SUCCESS,
    payload,
  };
};

const getByIdFail = () => {
  return {
    type: ACTION_TYPES.GET_UPLOAD_BY_ID_FAILURE,
  };
};

const getGalleryById = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getUploadLoading(true));
    await API.getGalleryById(id)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getByIdFail());
        else {
          dispatch(getByIdSuccess(result));
        }
      })
      .catch(async (error: { type: string; message: string | string[] }) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getByIdFail());
      });
  };
};

const createSuccess = () => {
  return {
    type: ACTION_TYPES.CREATE_UPLOAD_SUCCESS,
  };
};

const createFail = () => {
  return {
    type: ACTION_TYPES.CREATE_UPLOAD_FAILURE,
  };
};

const createGallery = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setUploadLoading(true));
    await API.createGallery(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createFail());
        else dispatch(createSuccess());
        await dispatch(fetchGalleries());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createFail());
      });
  };
};

const deleteSuccess = () => {
  return {
    type: ACTION_TYPES.DELETE_UPLOAD_SUCCESS,
  };
};

const deleteFail = () => {
  return {
    type: ACTION_TYPES.DELETE_UPLOAD_FAILURE,
  };
};

const deleteGallery = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setUploadLoading(true));
    await API.deleteGallery(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(deleteFail());
        else dispatch(deleteSuccess());
        await dispatch(fetchGalleries());
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(deleteFail());
      });
  };
};

export default {
  fetchGalleries,
  getGalleryById,
  createGallery,
  deleteGallery,
  clearUpload,
};
