import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { CommonColors } from '@/Themes';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState, useTypedDispatch } from '@/store';
import { Upload } from '@/Actions';
import Popup from '../LayoutPart/Popup';

const { fetchGalleries } = Upload;

interface ISectionProps {
  onOpen: { status: boolean; file?: any | null; type?: string; key?: any };
  onClose(): void;
  action?: string;
  callback?(
    type?: string,
    file?: any,
    key?: any,
    size?: {
      width: string | number;
      height: string | number;
    }
  ): void;
}

const Media: React.FC<ISectionProps> = ({
  onOpen,
  onClose,
  callback,
  action,
}) => {
  const dispatch = useTypedDispatch();

  const [isOpenResize, setIsOpenResize] = useState<boolean>(false);
  const [details, setDetails] = useState<any[] | null>(null);
  const [fileImage, setFileImage] = useState<any | null>(null);
  const [isOpenUpload, setIsOpenUpload] = useState<boolean>(false);
  const [size, setSize] = useState<{
    width: string | number;
    height: string | number;
  }>({
    //ratio 16/9
    width: 168,
    height: 105,
  });
  const selectedFileRef = useRef<any>(null);

  const galleries = useSelector((state: RootState) =>
    _.get(state.UPLOAD, 'galleries')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.UPLOAD, 'isFetchLoading')
  );

  useEffect(() => {
    dispatch(fetchGalleries());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(galleries)) setDetails(galleries);
  }, [galleries]);

  const handleChecked = (file: any) => {
    if (fileImage === file) {
      setFileImage(null);
    } else setFileImage(file);
  };

  const handleOpenResize = () => {
    if (action === 'EDITOR') {
      setIsOpenResize(true);
    } else if (callback && fileImage) {
      callback(fileImage, onOpen.type, onOpen?.key);
      setIsOpenResize(false);
    }
  };

  const _renderVituralImage = useCallback(
    () =>
      fileImage && (
        <Box
          id="virtual-selected-image"
          ref={selectedFileRef}
          onLoad={() =>
            selectedFileRef.current &&
            setSize({
              width: selectedFileRef.current.naturalWidth,
              height: selectedFileRef.current.naturalHeight,
            })
          }
          component="img"
          src={fileImage}
          sx={{ width: 0, height: 0 }}
        />
      ),
    [fileImage]
  );

  const _renderSizeEditor = () => {
    return (
      <Dialog
        open={isOpenResize}
        onClose={() => setIsOpenResize(false)}
        sx={{ zIndex: 10001 }}
      >
        <DialogTitle textAlign={'center'} sx={{ fontWeight: 700 }}>
          Adjust image size
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Width"
                type="number"
                fullWidth
                variant="standard"
                value={size.width}
                onChange={(e) => setSize({ ...size, width: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Height"
                type="number"
                fullWidth
                variant="standard"
                value={size.height}
                onChange={(e) => setSize({ ...size, height: e.target.value })}
              />
            </Grid>
            {_renderVituralImage()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenResize(false)}
            color="fuzzyWuzzyBrown"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!_.isEmpty(size) && callback && fileImage) {
                callback(fileImage, size);
                setIsOpenResize(false);
                // setFilter(DEFAULT_GALLERY_PAGINATION);
                // dispatch(fetchGalleries(DEFAULT_GALLERY_PAGINATION));
              }
            }}
            color="oceanGreen"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const _renderTopSection = () => {
    return (
      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end', mr: 4 }}>
        {fileImage && (
          <Button
            onClick={() => handleOpenResize()}
            color="oceanGreen"
            variant="contained"
          >
            Save
          </Button>
        )}
        <Button
          variant="contained"
          size="large"
          onClick={() => setIsOpenUpload(true)}
        >
          Upload Image
        </Button>
      </Box>
    );
  };

  const _renderGallery = () => {
    if (_.isEmpty(details) && _.isEmpty(galleries)) return <>no data</>;
    return (
      <Box sx={{ minHeight: 700 }}>
        <ImageList
          sx={{ width: '100%', minHeight: 1 }}
          cols={4}
          gap={20}
          variant="standard"
        >
          {_.map(details, (item: any) => {
            return (
              <ImageListItem
                key={item.id}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <ImageListItemBar
                  sx={{ transition: 'background 1s' }}
                  actionIcon={
                    <Stack flexDirection="row">
                      <Tooltip title={`${_.startCase('delete')} this image`}>
                        <Checkbox
                          onClick={() => handleChecked(item.filepath)}
                          checked={fileImage === item?.filepath}
                        />
                      </Tooltip>
                    </Stack>
                  }
                />
                <img
                  src={item.filepath}
                  loading="lazy"
                  style={{ width: '100%', height: '150px', objectFit: 'cover' }}
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Dialog
          fullScreen
          open={onOpen.status}
          onClose={onClose}
          sx={{ zIndex: 10000 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}
              >
                <Typography variant="h4" sx={{ color: CommonColors.mainColor }}>
                  Please choose an image
                </Typography>
                <Box>
                  <Button
                    sx={{ ml: 2 }}
                    color="fuzzyWuzzyBrown"
                    variant="contained"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {_renderTopSection()}
            </Grid>
            <Grid item xs={12} textAlign={'center'}>
              {!isFetchLoading ? _renderGallery() : <CircularProgress />}
            </Grid>
          </Grid>
        </Dialog>
      </Grid>

      <Dialog
        open={isOpenResize}
        onClose={() => setIsOpenResize(false)}
        sx={{ zIndex: 10001 }}
      >
        <DialogTitle textAlign={'center'} sx={{ fontWeight: 700 }}>
          Adjust image size
        </DialogTitle>
      </Dialog>
      <Popup.UploadMedia
        type="EDITOR"
        sx={{ zIndex: 10001 }}
        open={isOpenUpload}
        onClose={() => setIsOpenUpload(false)}
      />
      {_renderSizeEditor()}
    </Grid>
  );
};

export default Media;
