import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Typography, Button, Backdrop, Modal, Fade } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SxProps } from '@mui/system';

import { UploadFile } from '@/Components/Common';
import { RootState, useTypedDispatch } from '@/store';
import { Upload } from '@/Actions';
import { useSelector } from 'react-redux';

const { createGallery, fetchGalleries } = Upload;

type FILE_KEY_TYPES = 'file';

interface ISectionProps {
  type?: 'MANAGEMENT' | 'EDITOR';
  sx?: SxProps;
  open: boolean;
  onClose(isFetch?: boolean): void;
}

const UploadMedia: React.FC<ISectionProps> = ({ open, onClose, sx }) => {
  const dispatch = useTypedDispatch();

  const [files, setFiles] = useState<any[]>([]);

  const isActionLoading = useSelector((state: RootState) =>
    _.get(state.UPLOAD, 'isActionLoading')
  );
  const isUploadSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.UPLOAD, 'isUploadSuccess')
  );

  useEffect(() => {
    if (isUploadSuccess) {
      dispatch(fetchGalleries());
      setFiles([]);
      onClose();
    }
  }, [isUploadSuccess]);

  const handleFilesChange = (key: FILE_KEY_TYPES, items: File[]) => {
    if (key === 'file') setFiles(items);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    files.forEach((file: any) => formData.append('files', file));
    if (formData.getAll('files').length > 0) dispatch(createGallery(formData));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={sx}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 513,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            zIndex: 99,
          }}
        >
          <Typography mb="15px" fontSize="20px" fontWeight="500">
            Upload Images
          </Typography>
          <UploadFile
            label=""
            isMultiple
            accept="IMAGE"
            type="IMAGE"
            files={files}
            onFileChange={(items: File[]) => handleFilesChange('file', items)}
            disabled={isActionLoading}
          />
          <Box
            component="div"
            sx={{ display: 'flex', marginTop: 2, float: 'right' }}
          >
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={() => {
                setFiles([]);
                onClose();
              }}
              color="fuzzyWuzzyBrown"
              disabled={isActionLoading}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={_.size(files) === 0}
              variant="contained"
              onClick={handleSubmit}
              color="oceanGreen"
              loading={isActionLoading}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UploadMedia;
