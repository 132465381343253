import React from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';

import { mainStyles } from './DefaultLayout.styles';

import Widgets from '../Widgets';
import UserLayout from './UserLayout';
import AdminLayout from './AdminLayout';
type PORTAL_TYPE = 'USER' | 'ADMIN';
interface SectionProps {
  content: JSX.Element;
  portalFor?: PORTAL_TYPE;
}

const DefaultLayout: React.FC<SectionProps> = (props: SectionProps) => {
  const { content, portalFor = 'USER' } = props;

  const renderPotal = () => {
    switch (portalFor) {
      case 'ADMIN':
        return <AdminLayout content={content} />;
      default:
        return <UserLayout content={content} />;
    }
  };

  return (
    <Box sx={mainStyles}>
      {renderPotal()}
      <Widgets.Notification />
      <Widgets.Alert />
    </Box>
  );
};

export default DefaultLayout;
