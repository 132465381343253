import * as React from 'react';
import i18next from 'i18next';
import { getSavedLanguage, saveLanguage } from '@/Utils/Cookie.utils';
import { TextField, MenuItem, Box } from '@mui/material';

type TLocale = 'en' | 'sq';

const LanguageSwitch: React.FC = () => {
  const [currentLanguage, setCurrentLanguage] = React.useState<TLocale>('en');
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    const localeStorage = getSavedLanguage();
    setCurrentLanguage(localeStorage);
    i18next.changeLanguage(localeStorage);
  }, []);

  const handleChangeLanguage = async (language: TLocale) => {
    setCurrentLanguage(language);
    saveLanguage(language);
    i18next.changeLanguage(language);
    window.location.reload();
  };

  return (
    <TextField
      className="languageSelector"
      select
      value={currentLanguage}
      size="small"
      sx={{
        '.css-0': {
          display: 'none',
        },
        '.MuiSvgIcon-root': {
          color: { xs: 'white' },
        },
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      }}
      SelectProps={{
        onOpen() {
          setMenuIsOpen(true);
        },
        onClose() {
          setMenuIsOpen(false);
        },
      }}
      onChange={(e) => handleChangeLanguage(e.target.value as TLocale)}
    >
      <MenuItem
        className="languageItem"
        value="sq"
        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        disabled={currentLanguage === 'sq' ? true : false}
      >
        {menuIsOpen ? (
          <>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/al.png`}
              srcSet={`https://flagcdn.com/w40/al.png 2x`}
              alt="image"
            />
            <Box component="span">Shqip</Box>
          </>
        ) : (
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/al.png`}
            srcSet={`https://flagcdn.com/w40/al.png 2x`}
            alt="image"
          />
        )}
      </MenuItem>
      <MenuItem
        className="languageItem"
        value="en"
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
        disabled={currentLanguage === 'en' ? true : false}
      >
        {menuIsOpen ? (
          <>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/gb.png`}
              srcSet={`https://flagcdn.com/w40/gb.png 2x`}
              alt=""
            />
            <Box component="span">English</Box>
          </>
        ) : (
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/gb.png`}
            srcSet={`https://flagcdn.com/w40/gb.png 2x`}
            alt=""
          />
        )}
      </MenuItem>
    </TextField>
  );
};

export default LanguageSwitch;
