export default {
  email: 'Email',
  emailContact: 'Email *',
  fullName: 'Vendosni emri i plotë *',
  fullNameRegister: 'Vendosni emri i plotë',
  password: 'Fjalëkalimi',
  confirmPassword: 'Konfirmoni fjalëkalimin',
  search: 'KËRKO...',
  message: 'Shkruani mesazhin tuaj *',
};
