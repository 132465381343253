import { AdminContainer } from '@/Containers';
import { ROUTERS } from '@constants';
import ProtectedRoute from './ProtectedRouters';

const AdminRouter = {
  path: ROUTERS.HOME,
  children: [
    {
      path: ROUTERS.ADMIN,
      element: <AdminContainer.Authentication />,
    },
    {
      path: ROUTERS.ADMIN_DASHBOARD,
      element: (
        <ProtectedRoute location={ROUTERS.ADMIN_DASHBOARD}>
          <AdminContainer.Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.SYSTEM_USER,
      element: (
        <ProtectedRoute location={ROUTERS.SYSTEM_USER}>
          <AdminContainer.SystemUserList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.WHAT_IDRA_POLL,
      element: (
        <ProtectedRoute location={ROUTERS.WHAT_IDRA_POLL}>
          <AdminContainer.WhatIDRAPoll />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.REWARDS,
      element: (
        <ProtectedRoute location={ROUTERS.REWARDS}>
          <AdminContainer.Rewards />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.PRODUCTS_AND_SERVICES,
      element: (
        <ProtectedRoute location={ROUTERS.PRODUCTS_AND_SERVICES}>
          <AdminContainer.ProductsAndServices />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.WHY_IDRA_POLL,
      element: (
        <ProtectedRoute location={ROUTERS.WHY_IDRA_POLL}>
          <AdminContainer.WhyIDRApoll />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.STATISTICAL,
      element: (
        <ProtectedRoute location={ROUTERS.STATISTICAL}>
          <AdminContainer.Statistical />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CUSTOMERS,
      element: (
        <ProtectedRoute location={ROUTERS.CUSTOMERS}>
          <AdminContainer.Customers />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.INBOX,
      element: (
        <ProtectedRoute location={ROUTERS.INBOX}>
          <AdminContainer.InboxManagement />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.CONTACT_MANAGEMENT,
      element: (
        <ProtectedRoute location={ROUTERS.CONTACT_MANAGEMENT}>
          <AdminContainer.Contact />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.RESULTS,
      element: (
        <ProtectedRoute location={ROUTERS.RESULTS}>
          <AdminContainer.ResultsList />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.RESULTS_CREATE,
      element: (
        <ProtectedRoute location={ROUTERS.RESULTS_CREATE}>
          <AdminContainer.ResultsCreate />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.RESULTS_UPDATE,
      element: (
        <ProtectedRoute location={ROUTERS.RESULTS_UPDATE}>
          <AdminContainer.ResultsUpdate />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTERS.UPLOAD_GALLERY,
      element: (
        <ProtectedRoute location={ROUTERS.UPLOAD_GALLERY}>
          <AdminContainer.UploadGallery />
        </ProtectedRoute>
      ),
    },
  ],
};

const ForbidenRouter = {
  path: '*',
  // element: <AdminContainer.NotFound />,
};

export default AdminRouter;
export { ForbidenRouter };
