import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  aboutUs: {},
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_ABOUT_US: {
      return {
        ...state,
        aboutUs: {},
      };
    }
    case ACTION_TYPES.SET_ABOUT_US_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.SET_ABOUT_US_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.GET_ABOUT_US_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        aboutUs: payload,
      };
    case ACTION_TYPES.GET_ABOUT_US_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.UPDATE_ABOUT_US_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        aboutUs: payload,
      };
    case ACTION_TYPES.UPDATE_ABOUT_US_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
