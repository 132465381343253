export default {
  dashboard: 'DASHBOARD',
  profile: 'PROFILE',
  events: 'EVENTS',
  eventsList: 'EVENTS LIST',
  eventReport: 'EVENT REPORT',
  reportStatistics: 'REPORT STATISTICS',

  createUser: 'Create User',
  createCustomers: 'Create Customers',
  email: 'Email',
  contactDetail: 'Contact Detail',
  inboxDetail: 'Inbox Detail',

  whatIsIDRApoll: 'What Is IDRApoll ?',
  rewards: 'Rewards',
  earnRewardsForYourOpinion: 'EARN REWARDS FOR YOUR OPINION',
  resultsFromIDRApoll: 'Results from IDRApoll',
  forClients: 'For Clients',
  productsAndServices: 'Products and Services',
  whyIDRApoll: 'Why IDRApoll?',
  statistical: 'Statistical',
  createResults: 'Create Results',
  updateResults: 'Update Results',
  customers: 'Customers',
  unsubscribeInbox: 'Unsubscribe Inbox',
  content: 'Content',
  marketResearch: 'Market Research',
  opinionResearch: 'Opinion Research',
  trustedBy: 'Trusted by',
  contact: 'Contact',
  results: 'Results',
  register: 'Register',
  FAQ: 'Frequently Asked Questions',
  termsAndConditions: 'Terms and conditions',
  upload: 'Upload',
  updateUser: 'Update User',
  unsubscribe: 'Unsubscribe',
  updateCustomer: 'Update Customer',
  systemUser: 'System User',
  heroTitle: 'Earn rewards for your',
  subHeroTitle: 'opinion',
  areYouSure: 'Are you sure?',
  lastNews: 'Last news',
};
