import { IFilterSettings } from '@/Interfaces/Menu.interface';
import ENUMS from '../Enums';

const STATUS_DROPDOWN: IFilterSettings[] = [
  {
    label: 'Active',
    value: ENUMS.STATUS.ACTIVE,
  },

  {
    label: 'Inactive',
    value: ENUMS.STATUS.INACTIVE,
  },
];

const OPTIONS_LANGUAGE = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Shqip',
    value: 'sq',
  },
];

const OPTIONS_LANGUAGE_RESULT = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Shqip',
    value: 'sq',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

const OPTIONS_CATEGORY = [
  { label: 'Politics', value: 'politics' },
  { label: 'Economic', value: 'economic' },
  { label: 'Science', value: 'science' },
];

export default { STATUS_DROPDOWN, OPTIONS_LANGUAGE, OPTIONS_CATEGORY, OPTIONS_LANGUAGE_RESULT };
