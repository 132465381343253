export default {
  areYouSureYouWantToDelelteResult:
    'Jeni i sigurt që dëshironi të fshini rezultatin?',
  areYouSureYouWantToDeleteCustomer:
    'Jeni i sigurt që dëshironi të fshini klientin?',
  areYouSureYouWantTo: 'Jeni i sigurt që dëshironi ta',
  thisAccount: 'këtë llogari',
  inactive: 'çaktivizoni',
  activated: 'aktivizuar',
  dragDropSomeFilesHereOrClickToSelectFiles:
    'Zvarrit dhe lësho disa skedarë këtu ose kliko për të zgjedhur skedarë',
  dropTheFilesHere: 'Hidhini skedarët këtu',
  allowFileTypes: 'Lejo llojet e skedarëve',
  areYouSureYouWantToDelelteImage:
    'Jeni të sigurt që dëshironi të fshini imazhin e rezultatit',
  canNotLeaveTheTitleBlank: 'Nuk mund ta lini titullin bosh!',
  imageDescriptionCannotBeEmpty: 'ImageDescription nuk mund të jetë bosh!',
  imageUrlCannotBeEmpty: 'ImageUrl nuk mund të jetë bosh!',
  contentCannotBeEmpty: 'Përmbajtja nuk mund të jetë bosh!',
};
