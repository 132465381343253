import Cookies from 'universal-cookie';
import { COOKIE_KEYS } from '@constants';

const cookies = new Cookies();

const saveIsRemember = (isRemember: boolean) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_REMEMBER, JSON.stringify(isRemember));
};

const getSaveIsRemember = () => {
  const isRemember = localStorage.getItem(COOKIE_KEYS.SAVED_REMEMBER);
  if (isRemember) return JSON.parse(isRemember);

  return false;
};

const saveToken = (token: string) => {
  cookies.set(COOKIE_KEYS.SAVED_SECURE_TOKEN, token, { path: '/' });
};

const getSavedToken = () => {
  const token = cookies.get(COOKIE_KEYS.SAVED_SECURE_TOKEN);
  if (token) return token;
};

const saveRefreshToken = (token: string) => {
  cookies.set(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN, token, {
    maxAge: 60 * 60 * 6,
    path: '/',
  });
};

const getSavedRefreshToken = () => {
  return cookies.get(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN);
};

const saveFullName = (name: string) => {
  cookies.set(COOKIE_KEYS.SAVED_FULL_NAME, name);
};

const getFullName = () => {
  return cookies.get(COOKIE_KEYS.SAVED_FULL_NAME);
};

const getSavedUserData = () => {
  const userData = localStorage.getItem(COOKIE_KEYS.SAVED_USER_DATA);
  if (userData && userData !== 'undefined') return JSON.parse(userData);
  return null;
};

const saveUserData = (userData: any) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_USER_DATA, JSON.stringify(userData));
};

const saveUserRole = (role: string) => {
  cookies.set(COOKIE_KEYS.ROLE_KEY, role);
};

const getUserRole = () => {
  return cookies.get(COOKIE_KEYS.ROLE_KEY);
};

const clearAllSavedData = () => {
  cookies.remove(COOKIE_KEYS.SAVED_SECURE_TOKEN, { path: '/' });
  cookies.remove(COOKIE_KEYS.SAVED_SECURE_REFRESH_TOKEN, { path: '/' });
  localStorage.removeItem(COOKIE_KEYS.SAVED_USER_DATA);
};

const saveLanguage = (lang: string) => {
  localStorage.setItem(COOKIE_KEYS.SAVED_LANGUAGE, JSON.stringify(lang));
};

const getSavedLanguage = () => {
  const lang = localStorage.getItem(COOKIE_KEYS.SAVED_LANGUAGE);
  if (lang) return JSON.parse(lang);
  return 'en';
};

export {
  saveToken,
  getSavedToken,
  saveRefreshToken,
  getSavedRefreshToken,
  saveFullName,
  getFullName,
  clearAllSavedData,
  saveUserData,
  getSavedUserData,
  saveUserRole,
  getUserRole,
  saveIsRemember,
  getSaveIsRemember,
  saveLanguage,
  getSavedLanguage,
};
