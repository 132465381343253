import { SxProps, Theme } from '@mui/system';
import policiesImage from '@/Assets/termss.jpg';

export const heroPoliciesStyle: SxProps<Theme> = {
  background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${policiesImage}') no-repeat`,
  backgroundSize: 'cover',
  backgroundPosition: '35% 70%',
  position: 'relative',
  width: '100%',
  height: '45vh',
};
export const heroTitleStyle: SxProps<Theme> = {
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  p: '15px',
  mx: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '80%',
  color: 'white',
  textAlign: 'center',
  fontSize: '35px',
  fontFamily: '"Poppins", sans-serif',
  '@media screen and (max-width: 767px)': {
    fontSize: '22px',
    p: '5px',
  },
};
export const contentTitleStyle: SxProps<Theme> = {
  fontVariant: 'h2',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '23px',
  color: 'rgb(179, 95, 18)',
  mt: '30px',
};

export const contentDescStyle: SxProps<Theme> = {
  fontFamily: 'inherit',
  mt: '20px',
};
