import { ACTION_TYPES, DEFAULT_LOADING_STATES } from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  isCreateSuccess: false,
  custommers: [],
  custommersDetail: {},
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_CUSTOMMERS: {
      return {
        ...state,
        custommers: [],
        custommersDetail: {},
        isCreateSuccess: false,
      };
    }

    case ACTION_TYPES.SET_CUSTOMMERS_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_CUSTOMMERS_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_CUSTOMMERS_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.FETCH_CUSTOMMERS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        custommers: payload,
      };
    case ACTION_TYPES.FETCH_CUSTOMMERS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.GET_CUSTOMMERS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        custommersDetail: payload,
      };
    case ACTION_TYPES.GET_CUSTOMMERS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_CUSTOMMERS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        isCreateSuccess: true,
        custommersDetail: payload,
      };
    case ACTION_TYPES.CREATE_CUSTOMMERS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.UPDATE_CUSTOMMERS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        custommersDetail: payload,
      };
    case ACTION_TYPES.UPDATE_CUSTOMMERS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.DELETE_CUSTOMMERS_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        custommersDetail: payload,
      };
    case ACTION_TYPES.DELETE_CUSTOMMERS_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    default:
      return state;
  }
};
