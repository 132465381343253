import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface ILoadingProps {
  sizeIcon?: number;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';

  containerSx?: object;
}
const Loading: React.FC<ILoadingProps> = ({
  sizeIcon = 50,
  containerSx,
  color,
}) => {
  return (
    <Box sx={{ textAlign: 'center', mt: 5, ...containerSx }}>
      <CircularProgress size={sizeIcon} color={color} />
    </Box>
  );
};

export default Loading;
