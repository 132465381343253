import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { ROUTERS } from '@/Constants';
import DefaultLayout from '@/Components/DefaultLayout';
import ResultsAction from '@/Actions/Results.action';

import { Box, Typography, Stack } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { resultItems } from './results.style';
import {
  IDataCreateResults,
  IDataResults,
  IResults,
} from '@/Interfaces/Results.interface';
import backgroundImage from '@/Assets/all_resultss.jpg';
import { Loading } from '@/Components/Common';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 3,
  isPublished: true,
};

const DEFAULT_DATA_RESULTS = {
  languageCode: 'en',
  title: '',
  meta: '',
  tag: '',
  category: 'politics',
  imageDescription: '',
  imageUrl: '',
  content: '',
};

const { getResultsById, fetchResultsHomepage, clearResults } = ResultsAction;

const ResultDetails: React.FC = () => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');

  const [searchParams] = useSearchParams();
  const resultId = searchParams.get('id') || '';

  const [dataResults, setDataResults] =
    React.useState<IDataCreateResults>(DEFAULT_DATA_RESULTS);
  const resultsDetail: IDataResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'resultsDetail')
  );
  const lastResultsList: IResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'results')
  );
  const isGetLoading = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'isGetLoading')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'isFetchLoading')
  );

  const resolveCreateDate = dayjs(dataResults.publishedDate).format(
    'DD/MM/YYYY'
  );

  React.useEffect(() => {
    if (resultId) {
      dispatch(getResultsById(resultId));
    } else {
      Utils.redirect(ROUTERS.ALL_RESULTS);
    }
  }, [resultId]);

  React.useEffect(() => {
    const languageCode = Utils.getSavedLanguage();
    dispatch(fetchResultsHomepage({ ...DEFAULT_PAGINATION, languageCode }));
    return () => {
      dispatch(clearResults());
    };
  }, []);

  React.useEffect(() => {
    if (resultsDetail) setDataResults(resultsDetail);
  }, [resultsDetail]);

  const _renderResultDetail = () => (
    <Stack
      className="content"
      sx={{
        px: 'calc(1.5rem * 0.5)',
        mb: '1rem',
        width: { xs: '100%', md: '60%' },
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          fontSize: '1.75rem',
          mb: '0.5rem',
        }}
      >
        {dataResults.title}
      </Typography>
      <Stack direction="row">
        <CalendarMonthIcon />
        <Typography sx={{ fontFamily: 'inherit' }}>
          {resolveCreateDate}
        </Typography>
      </Stack>
      <hr
        style={{
          width: '100%',
          height: '1px',
          margin: '1rem 0',
        }}
      ></hr>
      <Box
        sx={{
          fontFamily: 'inherit',
          color: '#212529',
          img: {
            width: '100%',
            height: 'auto',
          },
        }}
        component="p"
        dangerouslySetInnerHTML={Utils.createMarkup(dataResults.content)}
      />
    </Stack>
  );

  const _renderLastNewList = () => (
    <Stack
      justifyContent="space-evenly"
      sx={{ flexDirection: { xs: 'row', md: 'column' }, width: '100%' }}
    >
      {lastResultsList.data?.map((items: any, index: number) => (
        <Box
          key={index}
          sx={{
            ...resultItems,
            mb: '10px',
            height: { xs: '200px', lg: '240px', xl: '280px' },
            width: { xs: '200px', lg: '240px', xl: '280px' },
          }}
          onClick={() =>
            Utils.redirect(`${ROUTERS.RESULTS_DETAIL}?id=${items.id}`)
          }
        >
          <Box
            component="img"
            src={items.imageUrl}
            sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              width: '100%',
              p: '10px 13px',
              zIndex: '2',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: '17px',
                fontFamily: '"Gill Sans Extrabold", sans-serif',
                fontWeight: 'lighter',
                color: 'white',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
              }}
            >
              {items.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Stack>
  );

  const renderMain = () => (
    <Box className="main">
      <Box
        component="section"
        className="hero"
        sx={{
          background: `url('${backgroundImage}') no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: '35% 70%',
          position: 'relative',
          width: '100%',
          height: '50vh',
        }}
      >
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
          <Typography
            variant="h1"
            sx={{
              color: 'white',
              textAlign: 'center',
              fontSize: '35px',
              fontFamily: '"Poppins", sans-serif',
              p: '0.5%',
              textTransform: 'uppercase',
            }}
          >
            {t('title.results')}
          </Typography>
        </Box>
      </Box>
      <Stack
        className="container"
        justifyContent="space-between"
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          maxWidth: '90%',
          m: '6% auto',
        }}
      >
        <Box
          className="previousBtn"
          sx={{
            pr: 'calc(1.5rem * 0.5)',
            my: { xs: '1em', md: '0' },
            cursor: 'pointer',
          }}
          onClick={() => {
            Utils.redirect(ROUTERS.ALL_RESULTS);
          }}
        >
          <Stack direction="row" alignItems="center">
            {/* <KeyboardBackspaceIcon /> */}
            <Typography
              sx={{
                fontFamily: 'inherit',
                fontSize: '20px',
                textWrap: 'nowrap',
                '&:before': {
                  display: 'inline-block',
                  content: '"\\027F5"',
                  transition: 'transform 0.3s ease-out',
                  fontSize: '20px',
                  pl: '8px',
                  mr: '5px',
                },
                '&:hover:before': {
                  transform: 'translateX(-4px)',
                },
              }}
            >
              {t('button.previousPage')}
            </Typography>
          </Stack>
        </Box>
        {isGetLoading ? <Loading /> : _renderResultDetail()}

        <Stack
          className="lastNews"
          justifySelf="end"
          justifyItems="end"
          sx={{
            // ml: { xs: 'center', md: 'center' },
            pl: 'calc(1.5rem * 0.5)',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: { xs: '90%', md: '100%' }, mx: 'auto' }}
          >
            <Box
              component="hr"
              sx={{ height: '1px', width: { xs: '100%', md: '40%' } }}
            />
            <Typography
              variant="h3"
              sx={{
                width: { xs: '50%', md: '100%' },
                fontSize: { xs: '1.75rem', md: '26px' },
                textAlign: 'center',
                fontFamily: '"Poppins", sans-serif',
                textWrap: 'nowrap',
              }}
            >
              {t('title.lastNews')}
            </Typography>
            <Box
              component="hr"
              sx={{ height: '1px', width: { xs: '100%', md: '40%' } }}
            />
          </Stack>
          {isFetchLoading ? <Loading /> : _renderLastNewList()}
        </Stack>
      </Stack>
    </Box>
  );

  return <DefaultLayout content={renderMain()} />;
};

export default ResultDetails;
