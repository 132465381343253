import { sendRequest } from '@/Configs';
import { API } from '@constants';

const { CONTACT } = API;

export const getContact = async (id: string) => {
  return await sendRequest(`${CONTACT.ROOT}${id}`, 'GET');
};

export const fetchContact = async (payload: any) => {
  return await sendRequest(CONTACT.ROOT, 'GET', payload);
};

export const SendContact = async (payload: any) => {
  return await sendRequest(CONTACT.ROOT, 'POST', payload);
};
