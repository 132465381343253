export default {
  //Common

  //Select Box
  choose: 'Choose',
  noOption: 'Option is empty!',

  //Dashboard
  dashboard: 'Dashboard',

  //User
  user: 'User',
  userName: 'Username',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  fullName: 'Emri i plotë',
  confirmPassword: 'Konfirmoni fjalëkalimin',
  showPassword: 'Show Password',
  password: 'Fjalëkalimin',
  uploadImage: 'Ngarko imazh',
  reason: 'Arsyeja',
  title: 'Titull',
  category: 'Kategoria',
  language: 'Gjuhe',
  tags: 'Etiketat',
  meta: 'Meta',
  carouselImageDescription: 'Përshkrimi i imazhit të karuselit',
  description: 'Përshkrim',
  shortDescription: 'Përshkrim i shkurtër',
  descriptionForFirstGif: 'Përshkrimi për Gif-in e parë',
  detailedDescription: 'Pershkrim i detajuar',
  mainDescription: 'Përshkrimi kryesor',
  titleForphoto: 'Titulli për foton',
  descriptionForPhoto: 'Përshkrimi për foton',
  research: 'Hulumtimi',
  statistical: 'Statistikore',
  number: 'Numri',
  titleGraphAge: 'Grafiku i titullit Mosha',
  titleGraphGender: 'Grafiku i titullit Gjinia',
  titleGraphDistribution: 'Title Graph Distribution',
  titleGraphEducation: 'Shpërndarja e grafikut të titullit',
  titleGraphEmployment: 'Grafiku i titullit Punësimi',
  editor: 'Redaktor',
  linkImage: 'Lidhja e imazhit / etiketa e ikonës',
  descriptionForSecondGif: 'Përshkrimi Për Gif-in e Dytë',
  descriptionForThirdGif: 'Përshkrimi Për Gifin e Tretë',
  imageFirstGift: 'Lidhja e imazhit Dhurata e parë',
  imageSecondGift: 'Lidhja e imazhit Dhurata e dytë',
  imageThirdGift: 'Lidhja e imazhit dhuratë e tretë',
  textContactPage: 'Na kontaktoni duke vendosur të dhënat tuaja',
  haveAnAccount: 'Keni një llogari?',
  firstReward: 'Shpërblimi i parë',
  titleFirstReward: 'Titulli Shpërblimi i parë',
  imageFirstReward: 'Imazhi Shpërblimi i parë',
  descriptionFirstReward: 'Përshkrim Shpërblimi i parë',
  secondReward: 'Shpërblimi i dytë',
  titleSecondReward: 'Titulli Shpërblimi i dytë',
  imageSecondReward: 'Shpërblimi i dytë i imazhit',
  descriptionSecondReward: 'Përshkrimi Shpërblimi i dytë',
  thirdReward: 'Shpërblimi i tretë',
  titleThirdReward: 'Shpërblimi i tretë i titullit',
  imageThirdReward: 'Shpërblimi i tretë i imazhit',
  descriptionThirdReward: 'Përshkrim shpërblimi i tretë',
  detailDescription: 'Përshkrimi i detajuar',
  descriptionThirdGift: 'Përshkrim dhurata e tretë',
  name: 'Emri',
  titleGraph: 'Grafiku i titullit',
  content: 'Përmbajtjen',
  url: 'Url',
  graphAge: 'Mosha e grafikut',
  grahpGender: 'Grafikoni gjininë',
  grahpDistribution: 'Shpërndarja e grafikut',
  grahpEducation: 'Edukimi grafik',
  grahpEmployment: 'Grafiku i punësimit',
  searchBy: 'Kërko sipas',
  newPassword: 'Fjalëkalim i ri',
  oldPassword: 'Fjalëkalimi i vjetër',

  // USER PORTAL
  notAMemberYet: 'Nuk keni një llogari?',
  unsubscribeText:
    'Ju lutem merrni kohën tuaj për të na dhënë arsyen pse doni të largoheni nga Idrapoll',
  unsubscribeOption1: 'Marr shumë emaile nga IDRApoll dhe kjo më bezdis',
  unsubscribeOption2: 'Nuk preferoj të plotësoj sondazhe në internet',
  unsubscribeOption3: 'Nuk më pëlqejnë shpërblimet dhe dhuratat',
  unsubscribeOption4:
    'Nuk mendoj që përmbajtja e anketimeve është e përshtatshme',
  unsubscribeOption5: 'Tjetër',
  IAgreeToThe: 'Pajtohem me',
  TermsAndConditions: 'Termat dhe kushtet e përdorimit',
  wouldYouLike:
    "Deshironi t'ju dergojme e-mail ne kohe reale mbi lajmet me te fundit nga IDRAPoll?",
  viewAll: 'Shiko të gjitha',
  country: 'Shteti',
  albania: 'Shqipëri',
  Albania: 'Shqipëri',
  kosovo: 'Kosovë',
  Kosovo: 'Kosovë',
};
