import _ from 'lodash';
import jwt_decode from 'jwt-decode';
import { history } from '@/store';
import { pushNotification, pushAlert } from './Widget.utils';
import dayjs from 'dayjs';

// Declare constants

// declare avaiable screens
// const AVAIABLE_ROUTES = [ROUTER.INITIALAZATION, ROUTER.HOME];

// Check network connection
const checkNetworkConnection = () => {
  return navigator.onLine;
};

// Redirect screen
const redirect = (location: string, state?: any) => {
  return history.push(location, state);
};

const replace = (location: string, state?: any) => {
  return history.replace(location, state);
};

// Check life of token
const checkTokenLifeTime = (token: string | null) => {
  if (!token) {
    pushAlert({
      message: 'Please login to continue...',
      type: 'warning',
    });
    return false;
  }
  const decodedToken: any = jwt_decode(token);
  const dateNow = new Date();
  if (decodedToken.exp < Math.floor(dateNow.getTime() / 1000)) {
    pushNotification({
      message: 'Your token has been expired!',
      type: 'error',
    });
    return false;
  }
  return true;
};

// Return app run mode
const checkAppMode = (modeType?: string) => {
  if (modeType) return import.meta.env.MODE === modeType;
  return import.meta.env.MODE;
};

// Sleep for delay
const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const detectValidPayload = (
  payload: {
    key: string;
    value: any;
    type: string;
    isUpperCase?: boolean;
  }[]
) => {
  let isValid = true;
  const invalidMsg: any = {};
  if (!_.isEmpty(payload) && _.isArray(payload)) {
    _.forEach(
      payload,
      (item: {
        key: string;
        value: any;
        type: string;
        isUpperCase?: boolean;
      }) => {
        const { key, value, type, isUpperCase } = item;
        let message = '';
        const convertKey = isUpperCase
          ? _.upperCase(key.replace(/([A-Z])/g, ' $1').trim())
          : _.capitalize(key.replace(/([A-Z])/g, ' $1').trim());
        if (type === 'text') {
          const isString = _.toString(_.trim(value));
          if (!isString) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
            else message = `${convertKey} is invalid format`;
          }
        }
        if (type === 'mail-string-array' && typeof value === 'string') {
          if (!_.trim(value)) message = `${convertKey} cannot be empty`;
          else {
            const resolveValud = value;
            const emails = resolveValud.split(',');
            const regexEmail = new RegExp(
              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g
            );
            const result = emails.every((email: string) =>
              email.match(regexEmail)
            );
            message = result
              ? ''
              : `${convertKey} contains value is invalid email format`;
          }
        }
        if (type === 'number') {
          const convertNumber = _.toNumber(_.trim(value));
          if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          else if (_.isNaN(convertNumber))
            message = `${convertKey} invalid format`;
        }
        if (type === 'age') {
          const convertNumber = _.toNumber(value);
          if (_.isNaN(convertNumber)) message = `${convertKey} invalid format`;
          else if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          else if (_.isNumber(convertNumber) && convertNumber < 18)
            message = `${convertKey} at least 18 years old`;
        }
        if (type === 'file') {
          const isFile = value instanceof File;
          if (!isFile) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          }
        }
        if (type === 'email') {
          const isEmail = String(value)
            .toLowerCase()
            .match(
              /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
            );
          if (!isEmail) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty!`;
            else message = 'Email is invalid format!';
          }
        }
        if (type === 'account') {
          const isEmail = String(value)
            .toLowerCase()
            .match(
              /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g
            );
          if (!isEmail) {
            if (_.isEmpty(value)) message = `Email cannot be empty`;
            else message = 'Email is invalid format';
          }
        }
        if (type === 'date') {
          if (!value) message = `${convertKey} cannot be empty`;
          else {
            const dateCheck = dayjs(`${value}`).isValid();
            if (!dateCheck) message = `${convertKey} is invalid format`;
          }
        }

        if (type === 'time') {
          if (!value) message = `${convertKey} cannot be empty`;
          else {
            const timeCheck = dayjs(`${value}`, 'HH:mm:ss', true).isValid();
            if (!timeCheck) message = `${convertKey} is invalid format`;
          }
        }

        if (type === 'web') {
          const isWebsite = String(value).match(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
          );
          if (!isWebsite) {
            if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
            else message = 'Website is invalid format';
          }
        }
        if (type === 'password') {
          if (_.isEmpty(value)) message = `${convertKey} cannot be empty`;
          else {
            const isPassword = String(value).match(
              /[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]$/
            );
            if (!isPassword)
              message = `${convertKey} contains characters that aren't allowed`;
            if (String(value).length < 6)
              message = `${convertKey} contains least at 6 characters`;
          }
        }
        if (!_.isEmpty(message)) isValid = false;
        _.assign(invalidMsg, { [key]: message });
      }
    );
  }
  return { isValid, invalidMsg };
};

const createMarkup = (html: any) => {
  return { __html: html };
};

export {
  checkNetworkConnection,
  redirect,
  checkTokenLifeTime,
  checkAppMode,
  sleep,
  replace,
  detectValidPayload,
  createMarkup,
};
