import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import WidgetReducer from './Widget.reducer';
import AuthReducer from './Authentication.reducer';
import systemUserReducer from './SystemUser.reducer';
import AboutUsReducer from './AboutUs.reducer';
import RewardsReducer from './Rewards.reducer';
import ProductAndServicesReducer from './ProductAndServices.reducer';
import WhyIdraPoll from './WhyIdraPoll.reducer';
import UploadReducer from './Upload.reducer';
import CustommersReducer from './Custommers.reducer';
import ResultsReducer from './Results.reducer';
import StatisticalReducer from './Statistical.reducer';
import ContactReducer from './Contact.reducer';
import InboxReducer from './Inbox.reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    ROUTER: connectRouter(history),
    AUTH: AuthReducer,
    WIDGET: WidgetReducer,
    SYSTEM_USER: systemUserReducer,
    ABOUT_US: AboutUsReducer,
    REWARDS: RewardsReducer,
    PRODUCT_AND_SERVICES: ProductAndServicesReducer,
    WHY_IDRA_POLL: WhyIdraPoll,
    UPLOAD: UploadReducer,
    CUSTOMMERS: CustommersReducer,
    RESULTS: ResultsReducer,
    STATISTICAL: StatisticalReducer,
    CONTACT: ContactReducer,
    INBOX: InboxReducer,
  });
export default createRootReducer;
