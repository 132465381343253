import { SxProps, Theme } from '@mui/system';
import { CommonColors } from '@/Themes';

const _borderEffect: SxProps<Theme> = {
  content: '""',
  position: 'absolute',
  height: '2px',
  background: CommonColors.burntSienna,
  transition: 'all 0.5s',
  bottom: 0,
};

const hoverBorderEffect: SxProps<Theme> = {
  position: 'relative',
  cursor: 'pointer',
  '&:after': {
    ..._borderEffect,
    left: '50%',
    width: 0,
  },
  '&:hover': {
    '&:after': {
      width: 1,
      left: 0,
    },
  },
};

const activeBorderEffect: SxProps<Theme> = {
  position: 'relative',
  cursor: 'pointer',
  '&:after': {
    ..._borderEffect,
    // transitionDelay: '2s',
    left: 0,
    width: 1,
  },
};

export const displayInDesktop: SxProps<Theme> = {
  display: { xs: 'none', md: 'flex' },
};

export const displayInMobile: SxProps<Theme> = {
  display: { sm: 'flex', md: 'none' },
};

export const listButtonStyles: SxProps<Theme> = {
  background: CommonColors.mainColor,
  color: 'white',
  borderRadius: 2,
  '&:hover': {
    background: CommonColors.burntSienna,
  },
};

export default {
  hoverBorderEffect,
  displayInDesktop,
  displayInMobile,
  activeBorderEffect,
  listButtonStyles,
};
