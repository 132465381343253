import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Box,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TablePagination,
  IconButton,
  TableFooter,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import { TABLES } from '@constants';
import { tableContainerStyles, tableFooterStyles } from './DataTable.styles';
import { useTranslation } from 'react-i18next';
import { PaginationActions } from '@/Components/Common';
import { RootState, useTypedDispatch } from '@/store';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';
import SystemUserAction from '@/Actions/SystemUser.action';

const { fetchSystemUser, clearSystemUser } = SystemUserAction;

export const DEFAULT_CONFIRM = {
  type: '',
  isOpen: false,
  message: '',
  state: {
    id: '',
    status: '',
    email: '',
  },
};

interface ISectionProps {
  onRowAction(user: any, action: 'edit' | 'delete' | 'change-status'): void;
}

const { SYSTEM_USER_TABLE_HEADER } = TABLES;

const SystemUserTable: React.FC<ISectionProps> = ({ onRowAction }) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');
  const colSpan = SYSTEM_USER_TABLE_HEADER.length + 5;

  const meta: IPaginationMeta = useSelector((state: RootState) =>
    _.get(state.SYSTEM_USER, 'meta')
  );
  const pagination: IPaginationFilter = useSelector((state: RootState) =>
    _.get(state.SYSTEM_USER, 'pagination')
  );

  const users: any = useSelector((state: RootState) =>
    _.get(state.SYSTEM_USER, 'users')
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.SYSTEM_USER, 'isFetchLoading')
  );

  useEffect(() => {
    dispatch(fetchSystemUser(pagination));
    return () => {
      dispatch(clearSystemUser());
    };
  }, []);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    type?: string
  ) => {
    let page = pagination.page;
    if (type === 'lastPage') page = newPage;
    else {
      if (newPage === 0) page = 1;
      else if (newPage < page) page -= 1;
      else if (newPage >= page) page += 1;
    }
    dispatch(fetchSystemUser({ ...pagination, page }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    dispatch(fetchSystemUser({ ...pagination, limit: perPage }));
  };

  // Renders
  const _renderTableHeader = () => {
    return (
      <TableRow>
        <TableCell sx={{ width: 50 }}>{t('table.no')}</TableCell>
        {_.map(SYSTEM_USER_TABLE_HEADER, (item) => (
          <TableCell
            width={170}
            key={`head-${item.value}`}
            sx={{ fontWeight: '700' }}
          >
            {t(`table.${item?.value}`)}
          </TableCell>
        ))}
        <TableCell sx={{ width: 70 }} align="center">
          {t(`table.action`)}
        </TableCell>
      </TableRow>
    );
  };

  const _renderTableBody = () => {
    if (_.isEmpty(users))
      return (
        <TableRow>
          <TableCell colSpan={colSpan + 1}>
            There is no system user to display
          </TableCell>
        </TableRow>
      );
    return _.map(users, (item, index) => {
      return (
        <TableRow hover key={index}>
          <TableCell sx={{ maxWidth: 50 }}>
            {index + 1 + (meta.currentPage - 1) * pagination.limit}
          </TableCell>
          {_.map(SYSTEM_USER_TABLE_HEADER, (head, innerKey) => {
            return (
              <TableCell key={`row${innerKey}-${index}`}>
                {_.get(item, head.value)}
              </TableCell>
            );
          })}
          <TableCell align="center">
            <Tooltip title={t('tooltip.edit')}>
              <span>
                <IconButton onClick={() => onRowAction(item, 'edit')}>
                  <EditIcon fontSize="small" sx={{ fontSize: '25px' }} />
                </IconButton>
              </span>
            </Tooltip>
            {item.status === 'active' ? (
              <Tooltip title={'Active'}>
                <span>
                  <IconButton
                    onClick={() => onRowAction(item, 'change-status')}
                  >
                    <ToggleOnIcon sx={{ color: 'green', fontSize: '30px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title={'Inactive'}>
                <span>
                  <IconButton
                    onClick={() => onRowAction(item, 'change-status')}
                  >
                    <ToggleOffIcon sx={{ color: 'red', fontSize: '30px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  const _renderFooter = () => {
    return (
      <TableFooter sx={tableFooterStyles}>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10]}
            colSpan={colSpan + 5}
            count={meta?.totalItems || 0}
            rowsPerPage={meta?.itemsPerPage || 0}
            page={meta?.currentPage - 1 || 0}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    );
  };

  const _renderTable = () => {
    return (
      <TableContainer component={Paper} sx={tableContainerStyles}>
        <Table stickyHeader>
          <TableHead>{_renderTableHeader()}</TableHead>
          <TableBody>
            {!isFetchLoading ? (
              _renderTableBody()
            ) : (
              <TableRow>
                <TableCell colSpan={colSpan + 1} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {_renderFooter()}
        </Table>
      </TableContainer>
    );
  };

  return <Box>{_renderTable()}</Box>;
};

export default SystemUserTable;
