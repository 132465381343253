import { useState } from 'react';
import _ from 'lodash';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { IMsgUserData, IUserData } from '@/Interfaces/SystemUser.interface';
import { CommonColors } from '@/Themes';
import { RootState, useTypedDispatch } from '@/store';
import SystemUserAction from '@/Actions/SystemUser.action';
import { useSelector } from 'react-redux';
import Utils from '@/Utils';

const { createSystemUser } = SystemUserAction;

interface IPropsSystemUser {
  open: boolean;
  onClose(value?: string): void;
}

const DEFAULT_SYSTEM_USER: IUserData = {
  email: '',
  password: '',
  name: '',
};

const CreateSystemUser: React.FC<IPropsSystemUser> = ({ open, onClose }) => {
  const dispatch = useTypedDispatch();
  const { t } = useTranslation('translation');
  const [showPassword, setShowPassword] = useState(false);
  const [dataUser, setDataUser] = useState<IUserData>(DEFAULT_SYSTEM_USER);
  const [msg, setMsg] = useState<IMsgUserData>(DEFAULT_SYSTEM_USER);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.SYSTEM_USER, 'isFetchLoading')
  );

  const validatePayload = () => {
    const requiredPayload: {
      key: string;
      label: string;
      value: any;
      type: string;
    }[] = [
      {
        key: 'name',
        label: 'name',
        value: dataUser.name,
        type: 'name',
      },
      {
        key: 'password',
        label: 'Password',
        value: dataUser.password,
        type: 'password',
      },
    ];

    const result = Utils.detectValidPayload(requiredPayload);

    return result;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { isValid, invalidMsg } = validatePayload();
    if (!isValid) setMsg(invalidMsg);
    else {
      await dispatch(createSystemUser(dataUser));
      await onClose();
      setDataUser(DEFAULT_SYSTEM_USER);
    }
  };

  return (
    <Dialog open={open} keepMounted fullWidth>
      <DialogTitle>
        <Typography
          sx={{
            color: CommonColors.mainColor,
            letterSpacing: 5,
            fontSize: 25,
          }}
        >
          {t('title.createUser')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <Box sx={{ border: '1px solid #0000001f', p: 4 }}>
            <Box mb={2} mt={1}>
              <InputLabel sx={{ fontWeight: '500', mb: 1 }}>
                {t('label.email')}
              </InputLabel>
              <TextField
                autoComplete="off"
                fullWidth
                size="small"
                value={dataUser.email}
                label={t('label.email')}
                type="email"
                required
                onChange={(e) => {
                  setDataUser({ ...dataUser, email: e.target.value });
                  setMsg({ ...msg, email: '' });
                }}
                error={!_.isEmpty(msg.email)}
                helperText={msg.email}
              />
            </Box>
            <Box mb={2} mt={1}>
              <InputLabel sx={{ fontWeight: '500', mb: 1 }}>
                {t('label.name')}
              </InputLabel>
              <TextField
                autoComplete="no"
                fullWidth
                size="small"
                value={dataUser.name}
                label={t('label.name')}
                required
                onChange={(e) => {
                  setDataUser({ ...dataUser, name: e.target.value });
                  setMsg({ ...msg, name: '' });
                }}
                error={!_.isEmpty(msg.name)}
                helperText={msg.name}
              />
            </Box>
            <Box>
              <InputLabel sx={{ fontWeight: '500', mb: 1 }}>
                {t('label.password')}
              </InputLabel>
              <TextField
                autoComplete="no"
                fullWidth
                size="small"
                value={dataUser.password}
                label={t('label.password')}
                type={showPassword ? 'text' : 'password'}
                required
                error={!_.isEmpty(msg.password)}
                helperText={msg.password}
                onChange={(e) => {
                  setDataUser({ ...dataUser, password: e.target.value });
                  setMsg({ ...msg, password: '' });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <LoadingButton
              variant="contained"
              color="fuzzyWuzzyBrown"
              onClick={() => {
                setDataUser(DEFAULT_SYSTEM_USER);
                onClose();
              }}
              loading={isFetchLoading}
            >
              {t('button.back')}
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              color="oceanGreen"
              loading={isFetchLoading}
            >
              {t('button.save')}
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateSystemUser;
