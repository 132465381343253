import _ from 'lodash';
import { ACTION_TYPES, ROUTERS } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import Swal from 'sweetalert2';
import {
  IPaginationFilter,
  IPaginationMeta,
} from '@/Interfaces/PaginationMeta.interface';
import writeXlsxFile from 'write-excel-file';

const currentLanguage = Utils.getSavedLanguage();
// SINGLE ACTIONS

const clearInbox = () => {
  return {
    type: ACTION_TYPES.CLEAR_INBOX,
  };
};

const setInboxActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_INBOX_ACTION_LOADING,
    payload,
  };
};

const getcontactActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_INBOX_GET_LOADING,
    payload,
  };
};

const fetchInboxActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_INBOX_FETCH_LOADING,
    payload,
  };
};

const getSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_INBOX_BY_ID_SUCCESS,
    payload,
  };
};

const getFail = () => {
  return {
    type: ACTION_TYPES.GET_INBOX_BY_ID_FAILURE,
  };
};

const getInbox = (id: string) => {
  return async (dispatch: any) => {
    dispatch(getcontactActionLoading(true));
    await API.getInbox(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getFail());
        else {
          dispatch(getSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getFail());
      });
  };
};

const fetchSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_INBOX_SUCCESS,
    payload,
  };
};

const fetchFail = () => {
  return {
    type: ACTION_TYPES.FETCH_INBOX_FAILURE,
  };
};

const setInboxPagination = (payload: IPaginationFilter) => {
  return {
    type: ACTION_TYPES.SET_INBOX_PAGINATION,
    payload,
  };
};

const setInboxMeta = (payload?: IPaginationMeta) => {
  return {
    type: ACTION_TYPES.SET_INBOX_META,
    payload,
  };
};

const fetchInbox = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(fetchInboxActionLoading(true));
    await API.fetchInbox(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(fetchFail());
        else {
          const items: any = _.get(result, 'data');
          const pagination: any = {
            page: _.get(result, 'currentPage'),
            limit: _.get(result, 'itemsPerPage'),
          };
          const meta: any = {
            currentPage: _.get(result, 'currentPage'),
            itemCount: items.length,
            itemsPerPage: _.get(result, 'itemsPerPage'),
            totalItems: _.get(result, 'totalItems'),
            totalPages: _.get(result, 'totalPages'),
          };
          dispatch(setInboxMeta(meta));
          dispatch(setInboxPagination(pagination));
          dispatch(fetchSuccess(items));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(fetchFail());
      });
  };
};

const unsubscribeSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UNSUBSCRIBE_SUCCESS,
    payload,
  };
};

const unsubscribeFail = () => {
  return {
    type: ACTION_TYPES.UNSUBSCRIBE_FAILURE,
  };
};

const unsubscribe = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(setInboxActionLoading(true));
    await API.unsubscribe(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(unsubscribeFail());
        else {
          const message =
            currentLanguage === 'en'
              ? 'Thank you for your time with us. We have successfully processed your unsubscribe request.'
              : 'Faleminderit për kohën tuaj me ne. Ne kemi përpunuar me sukses kërkesën tuaj për çregjistrim.';
          dispatch(unsubscribeSuccess(result));
          Swal.fire({
            title: 'UNSUBSCRIBE',
            text: message,
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then((result) => {
            if (result.isConfirmed) {
              Utils.redirect(ROUTERS.HOME);
            } else {
              Utils.redirect(ROUTERS.HOME);
            }
          });
        }
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(unsubscribeFail());
      });
  };
};

const exportToCSV = async (data: any) => {
  const fileName = `Unsubscribe_${new Date().getTime()}` + '.xlsx';
  let totalRows: any[] = [];
  const schema: any[] = [
    {
      column: 'Email',
      type: String,
      width: 50,
      value: (row: any) => row['email'],
      borderStyle: 'medium',
    },
    {
      column: 'Reason',
      type: String,
      width: 50,
      value: (row: any) => row['content'],
      borderStyle: 'medium',
    },
    {
      column: 'Country',
      type: String,
      width: 50,
      value: (row: any) => row['country'],
      borderStyle: 'medium',
    },
    {
      column: 'Date',
      type: String,
      width: 50,
      value: (row: any) => row['createdAt'],
      borderStyle: 'medium',
    },
  ];

  _.forEach(data, (el) => {
    totalRows.push(el);
  });

  await writeXlsxFile(totalRows, {
    schema,
    headerStyle: {
      fontWeight: 'bold',
      fontSize: 14,
      wrap: true,
      borderStyle: 'medium',
    },
    fileName,
  });
};

const exportInbox = (query: any) => {
  return async (dispatch: any) => {
    dispatch(setInboxActionLoading(true));
    await API.exportInbox(query)
      .then(async (response: any) => {
        await exportToCSV(response.data.payload)
        dispatch(unsubscribeSuccess(true));
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(unsubscribeFail());
      });
  };
};

export default {
  getInbox,
  fetchInbox,
  clearInbox,
  unsubscribe,
  exportInbox
};
