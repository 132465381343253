import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IDataCreateStatistical } from '@/Interfaces/Statistical.interface';

// SINGLE ACTIONS

const setStatisticalActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_STATISTICAL_ACTION_LOADING,
    payload,
  };
};
const getStatisticalActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_STATISTICAL_GET_LOADING,
    payload,
  };
};

const clearStatistical = () => {
  return {
    type: ACTION_TYPES.CLEAR_STATISTICAL,
  };
};

const getStatisticalSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.FETCH_STATISTICAL_SUCCESS,
    payload,
  };
};

const getStatisticalFail = () => {
  return {
    type: ACTION_TYPES.FETCH_STATISTICAL_FAILURE,
  };
};

const fetchStatistical = (type: string) => {
  return async (dispatch: any) => {
    dispatch(getStatisticalActionLoading(true));
    await API.fetchStatistical(type)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getStatisticalFail());
        else {
          dispatch(getStatisticalSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getStatisticalFail());
      });
  };
};

const createStatisticalSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_STATISTICAL_SUCCESS,
    payload,
  };
};

const createStatisticalFail = () => {
  return {
    type: ACTION_TYPES.CREATE_STATISTICAL_FAILURE,
  };
};

const createStatistical = (payload: IDataCreateStatistical) => {
  return async (dispatch: any) => {
    dispatch(setStatisticalActionLoading(true));
    await API.createStatistical(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(createStatisticalFail());
        else {
          dispatch(createStatisticalSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createStatisticalFail());
      });
  };
};

const updateStatisticalSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_STATISTICAL_SUCCESS,
    payload,
  };
};

const updateStatisticalFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_STATISTICAL_FAILURE,
  };
};

const updateStatistical = (payload: IDataCreateStatistical) => {
  return async (dispatch: any) => {
    dispatch(getStatisticalActionLoading(true));
    await API.updateStatistical(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateStatisticalFail());
        else {
          dispatch(updateStatisticalSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateStatisticalFail());
      });
  };
};

export default {
  fetchStatistical,
  createStatistical,
  updateStatistical,
  clearStatistical,
};
