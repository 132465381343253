import _ from 'lodash';
import { ACTION_TYPES } from '@constants';
import API from '@/APIs';
import Utils from '@utils';
import { IDataProductAndServices } from '@/Interfaces/ProductAndServices.interface';

const setProductAndServiceActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_PRODUCT_AND_SERVICES_ACTION_LOADING,
    payload,
  };
};
const getProductAndServiceActionLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_PRODUCT_AND_SERVICES_GET_LOADING,
    payload,
  };
};

const clearProductAndService = () => {
  return {
    type: ACTION_TYPES.CLEAR_PRODUCT_AND_SERVICES,
  };
};

const getProductAndServiceSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.GET_PRODUCT_AND_SERVICES_SUCCESS,
    payload,
  };
};

const getProductAndServiceFail = () => {
  return {
    type: ACTION_TYPES.GET_PRODUCT_AND_SERVICES_FAILURE,
  };
};

const getProductAndService = (type: string, research: any) => {
  return async (dispatch: any) => {
    dispatch(getProductAndServiceActionLoading(true));
    await API.getProductsAndServices(type, research)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response, true);
        if (!result) await dispatch(getProductAndServiceFail());
        else {
          dispatch(getProductAndServiceSuccess(result));
        }
        return true;
      })
      .catch(async (error: any) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(getProductAndServiceFail());
      });
  };
};

const updateProductAndServiceSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.UPDATE_PRODUCT_AND_SERVICES_SUCCESS,
    payload,
  };
};

const updateProductAndServiceFail = () => {
  return {
    type: ACTION_TYPES.UPDATE_PRODUCT_AND_SERVICES_FAILURE,
  };
};

const updateProductAndService = (
  type: string,
  payload: IDataProductAndServices
) => {
  return async (dispatch: any) => {
    dispatch(setProductAndServiceActionLoading(true));
    await API.updateProductsAndServices(type, payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(updateProductAndServiceFail());
        else {
          dispatch(updateProductAndServiceSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(updateProductAndServiceFail());
      });
  };
};

const createProductAndServiceSuccess = (payload: any) => {
  return {
    type: ACTION_TYPES.CREATE_PRODUCT_AND_SERVICES_SUCCESS,
    payload,
  };
};

const createProductAndServiceFail = () => {
  return {
    type: ACTION_TYPES.CREATE_PRODUCT_AND_SERVICES_FAILURE,
  };
};

const createProductAndService = (payload: IDataProductAndServices) => {
  return async (dispatch: any) => {
    dispatch(setProductAndServiceActionLoading(true));
    await API.createProductAndService(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) await dispatch(createProductAndServiceFail());
        else {
          dispatch(createProductAndServiceSuccess(result));
        }
      })
      .catch(async (error) => {
        await Utils.resolveFailureResponse(error);
        await dispatch(createProductAndServiceFail());
      });
  };
};

export default {
  getProductAndService,
  updateProductAndService,
  createProductAndService,
  clearProductAndService,
};
