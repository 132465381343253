import * as React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Carousel from 'react-material-ui-carousel';
import { Bar } from 'react-chartjs-2';

import Utils from '@/Utils';
import { RootState, useTypedDispatch } from '@/store';
import { StatisticalActions } from '@/Actions';
import { IDataCreateStatistical } from '@/Interfaces/Statistical.interface';
import { Box, Stack, Typography } from '@mui/material';
import { Loading } from '@/Components/Common';
import CountAnimation from '@/Components/Common/CountAnimation';
import { containerStyle } from './home.style';
import { Images } from '@/Themes';

interface IDataStatistics {
  title: string;
  number: string;
  src: string;
}

const LIST_STATS_IMAGE = [
  {
    src: Images.webst,
  },
  {
    src: Images.avatar2,
  },
  {
    src: Images.project,
  },
];

const { fetchStatistical, clearStatistical } = StatisticalActions;

const StatisticalSection: React.FC = () => {
  const dispatch = useTypedDispatch();
  const payload: IDataCreateStatistical = useSelector((state: RootState) =>
    _.get(state.STATISTICAL, 'statistical')
  );
  const isLoading = useSelector((state: RootState) =>
    _.get(state.STATISTICAL, 'isGetLoading')
  );
  const currentLanguage = Utils.getSavedLanguage();
  const [dataGrahpGender, setDataGrahpGender] =
    React.useState<IDataCreateStatistical['detailGrahpGender']>();
  const [dataStatistics, setDataStatistics] = React.useState<IDataStatistics[]>(
    []
  );

  const LIST_CAROUSEL = [
    { name: payload.titleGrahpEducation, objectKeys: 'titleGrahpEducation' },
    {
      name: payload.titleGrahpDistribution,
      objectKeys: 'titleGrahpDistribution',
    },
    { name: payload.titleGrahpEmployment, objectKeys: 'titleGrahpEmployment' },
    { name: payload.titleGrahpAge, objectKeys: 'titleGrahpAge' },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    type: 'bar',
  };

  React.useEffect(() => {
    dispatch(fetchStatistical(currentLanguage));
    return () => {
      dispatch(clearStatistical());
    };
  }, [currentLanguage]);

  React.useEffect(() => {
    const result: IDataStatistics[] = [];
    if (!_.isEmpty(payload))
      for (let i = 0; i < payload.statistics.length; i++) {
        result.push({
          ...LIST_STATS_IMAGE[i],
          ...payload.statistics[i],
        });
        setDataStatistics(result);
        setDataGrahpGender(payload.detailGrahpGender);
      }
  }, [payload]);

  const chartLabel = (titleGrahp: string) => {
    const labels: any[] = [];
    switch (titleGrahp) {
      case 'titleGrahpAge':
        payload.detailGrahpAge?.map((items) => {
          labels.push(items.title);
        });
        break;
      case 'titleGrahpDistribution':
        payload.detailGrahpDistribution?.map((items) => {
          labels.push(items.title);
        });
        break;
      case 'titleGrahpEducation':
        payload.detailGrahpEducation?.map((items) => {
          labels.push(items.title);
        });
        break;
      case 'titleGrahpEmployment':
        payload.detailGrahpEmployment?.map((items) => {
          labels.push(items.title);
        });
        break;
      default:
        break;
    }
    return labels;
  };

  const chartNumber = (titleGrahp: string) => {
    const data: any[] = [];
    switch (titleGrahp) {
      case 'titleGrahpAge':
        payload.detailGrahpAge?.map((items) => {
          data.push(items.number);
        });
        break;
      case 'titleGrahpDistribution':
        payload.detailGrahpDistribution?.map((items) => {
          data.push(items.number);
        });
        break;
      case 'titleGrahpEducation':
        payload.detailGrahpEducation?.map((items) => {
          data.push(items.number);
        });
        break;
      case 'titleGrahpEmployment':
        payload.detailGrahpEmployment?.map((items) => {
          data.push(items.number);
        });
        break;
      default:
        break;
    }
    return data;
  };

  const dataChart = (titleGrahp: string) => {
    const resolveChart = {
      labels: chartLabel(titleGrahp),
      datasets: [
        {
          backgroundColor: 'RGB(91,125,151)',
          hoverBackgroundColor: '#fd7e14',
          data: chartNumber(titleGrahp),
        },
      ],
    };
    return resolveChart;
  };

  const _renderCarouselItems = () => {
    const render = LIST_CAROUSEL.map((items, index) => (
      <Stack
        key={index}
        className="chartContainer"
        direction="column"
        sx={{
          mx: 'auto',
          px: '5%',
          width: '100%',
          height: '100%',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#ffffff',
            fontFamily: '"Poppins", sans-serif',
            fontSize: { xs: 'calc(1.325rem + .9vw)', lg: '2rem' },
            mb: '4%',
          }}
        >
          {items.name}
        </Typography>
        <Box>
          <Bar
            data={dataChart(items.objectKeys)}
            options={options}
            style={{ width: '100%' }}
          />
        </Box>
      </Stack>
    ));

    render.push(
      <Stack
        className="chartContainer"
        direction="column"
        sx={{
          mx: 'auto',
          px: '5%',
          width: '80%',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#ffffff',
            fontFamily: '"Poppins", sans-serif',
            fontSize: { xs: 'calc(1.325rem + .9vw)', lg: '2rem' },
            mb: '5%',
          }}
        >
          {payload.titleGrahpGender}
        </Typography>
        <Stack
          direction="row"
          sx={{ mt: { xs: '6vw', xl: '3vw', mb: { lg: '5vw', xl: '6vw' } } }}
        >
          <Stack
            className="left"
            direction="column"
            flex={1}
            sx={{
              justifyContent: 'center',
              '@media (min-width: 768px)': { width: '33.33333333%' },
            }}
          >
            <Box
              component="img"
              src={Images.MoustageBeardOutline}
              sx={{
                width: '100%',
                '@media (max-width: 768px)': {
                  width: '70%',
                  mx: 'auto',
                },
              }}
            ></Box>
            <span style={{ color: '#fd7e14', fontSize: 40 }}>
              {dataGrahpGender ? dataGrahpGender[0].number : '0'}%
            </span>
          </Stack>
          <Box
            sx={{
              width: '3px',
              height: '20vw',
              backgroundColor: 'white',
              borderRadius: '5px',
              mx: '10%',
            }}
          ></Box>
          <Stack
            className="right"
            direction="column"
            flex={1}
            sx={{
              justifyContent: 'center',
              '@media (min-width: 768px)': { width: '33.33333333%' },
            }}
          >
            <Box
              component="img"
              src={Images.womanStyleOutline}
              sx={{
                width: '100%',
                '@media (max-width: 768px)': {
                  width: '70%',
                  mx: 'auto',
                },
              }}
            ></Box>
            <span style={{ color: '#fd7e14', fontSize: 40 }}>
              {dataGrahpGender ? dataGrahpGender[1]?.number : '0'}%
            </span>
          </Stack>
        </Stack>
      </Stack>
    );
    return render;
  };

  const _renderStatisticalContent = () => (
    <>
      <Stack
        className="stats"
        sx={{
          flexDirection: 'row',
          '@media screen and (max-width: 768px)': {
            flexDirection: 'column',
          },
        }}
      >
        {!_.isEmpty(dataStatistics) &&
          dataStatistics.map((items, index) => (
            <Stack
              key={index}
              className="first"
              direction="column"
              flex={1}
              mb="10%"
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { lg: '2rem', xs: 'calc(1.325rem + .9vw)' },
                  fontFamily: '"Poppins", sans-serif',
                  color: '#ffffff',
                  fontWeight: 500,
                }}
              >
                {items.title}
              </Typography>
              <Stack direction="row" justifyContent="center">
                <Box component="img" src={items.src} sx={{ width: '20%' }} />
                <span
                  style={{
                    fontSize: 40,
                    color: 'rgb(253, 126, 20)',
                    alignSelf: 'center',
                  }}
                >
                  +<CountAnimation number={items.number} />
                </span>
              </Stack>
            </Stack>
          ))}
      </Stack>
      <Box
        className="bottom"
        sx={{ width: '80%', height: '100%', m: '0 auto' }}
      >
        <Carousel
          cycleNavigation
          stopAutoPlayOnHover={true}
          sx={{
            m: '0 auto',
            height: { md: '500px', lg: '570px', xl: '600px' },
            '.css-1m9128y': {
              mt: { lg: '1vw' },
            },
          }}
        >
          {_renderCarouselItems().map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </Carousel>
      </Box>
    </>
  );

  const renderMain = () => {
    return (
      <Box
        component="section"
        className="statistical"
        sx={{
          background:
            `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)), url('${Images.oldBackground}') fixed center center`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
        }}
      >
        <Stack
          className="container"
          direction="column"
          sx={{
            ...containerStyle,
            pt: '8%',
            pb: '3%',
          }}
        >
          {isLoading ? <Loading /> : _renderStatisticalContent()}
        </Stack>
      </Box>
    );
  };

  return renderMain();
};

export default StatisticalSection;
