export default {
  noDataWereFound: 'No data were found',
  rowPerPage: 'Rows per page',

  no: 'No',
  email: 'Email',
  role: 'Role',
  action: 'Action',
  name: 'Name',
  logo: 'Logo',
  url: 'URL',
  reason: 'Reason',
  content: 'Content',
  title: 'Title',
  date: 'Date',
  landingPage: 'Landing Page',
  published: 'Published',
  rowsPerPage: 'Rows per page',
  language: 'Language',
  country: 'Country',
};
