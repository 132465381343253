import React, { useEffect } from 'react';

import DefaultLayout from '@/Components/DefaultLayout';
import { ResultsTable } from '@/Components/LayoutPart/DataTable';
import { ROUTERS } from '@/Constants';
import { IPaginationsResults, IResults } from '@/Interfaces/Results.interface';
import { CommonColors } from '@/Themes';
import Utils from '@/Utils';
import {
  Button,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LIST } from '@/Constants';
import { ResultsActions } from '@/Actions';
import { RootState, useTypedDispatch } from '@/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { IConfirmStructure } from '@/Interfaces/ConfirmDialog.interface';
import { DEFAULT_CONFIRM } from '@/Constants/ConfirmDialog';
import { Confirm } from '@/Components/Dialogs';

const { deleteResults, fetchResults } = ResultsActions;

const OPTIONS_LANGUAGE = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Albania',
    value: 'sq',
  },
  {
    label: 'Both',
    value: 'both',
  },
];

const DEFAULT_PAGINATION: IPaginationsResults = {
  page: 1,
  limit: 10,
  title: '',
  category: '',
  languageCode: '',
};

const ResultsList = () => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [filter, setFilter] =
    React.useState<IPaginationsResults>(DEFAULT_PAGINATION);
  const [confirmDialog, setConfirmDialog] =
    React.useState<IConfirmStructure>(DEFAULT_CONFIRM);
  const payload: IResults = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'results')
  );
  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.RESULTS, 'isFetchLoading')
  );

  useEffect(() => {
    if (payload) {
      setFilter({
        ...filter,
        page: payload.currentPage,
      });
    }
  }, [payload]);

  const handleDelete = (state: { id?: string; type?: string }) => {
    const { id, type } = state;
    if (id && type === 'delete' && payload) dispatch(deleteResults(id, filter));
    setConfirmDialog(DEFAULT_CONFIRM);
  };

  const onRowAction = (item: any, action: 'detail' | 'delete' | 'edit') => {
    if (action === 'edit')
      Utils.redirect(ROUTERS.RESULTS_UPDATE, { id: item.id });
    if (action === 'delete') {
      const description = `${t('message.areYouSureYouWantToDelelteResult')}`;
      setConfirmDialog({
        isOpen: true,
        message: description,
        state: {
          id: item.id,
          type: action,
        },
      });
    }
  };

  const onCancel = () => {
    setConfirmDialog(DEFAULT_CONFIRM);
    dispatch(fetchResults(DEFAULT_PAGINATION));
  };

  const _renderTop = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography
            sx={{ color: CommonColors.mainColor, letterSpacing: 5 }}
            variant="h4"
          >
            {t('title.resultsFromIDRApoll')}
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign={'end'}>
          <Button
            variant="contained"
            onClick={() => Utils.redirect(ROUTERS.RESULTS_CREATE)}
          >
            {t('button.create')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const _renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={3} display={'flex'}>
          <InputLabel sx={{ width: '8vh', fontWeight: '500', mt: 1 }}>
            {t('label.title')}
          </InputLabel>
          <TextField
            fullWidth
            size="small"
            value={filter?.title}
            label={t('label.title')}
            onChange={(e) => setFilter({ ...filter, title: e.target.value })}
          />
        </Grid>
        <Grid item xs={3} display={'flex'}>
          <InputLabel sx={{ width: '18vh', fontWeight: '500', mt: 1 }}>
            {t('label.category')}
          </InputLabel>
          <Select
            value={filter.category ? filter.category : 'all'}
            fullWidth
            size="small"
            sx={{
              minWidth: '80px',
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onChange={(e) =>
              setFilter({
                ...filter,
                category: e.target.value === 'all' ? '' : e.target.value,
              })
            }
          >
            <MenuItem key="all" value={'all'}>
              All
            </MenuItem>
            {LIST.DROPDOWN_LIST.OPTIONS_CATEGORY.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={3} display={'flex'}>
          <InputLabel sx={{ width: '18vh', fontWeight: '500', mt: 1 }}>
            {t('label.language')}
          </InputLabel>
          <Select
            value={filter.languageCode ? filter.languageCode : 'all'}
            fullWidth
            size="small"
            sx={{
              minWidth: '80px',
              '	.MuiSelect-select': {
                display: 'flex',
                alignItems: 'center',
              },
            }}
            onChange={(e) =>
              setFilter({
                ...filter,
                languageCode: e.target.value === 'all' ? '' : e.target.value,
              })
            }
          >
            <MenuItem key="all" value={'all'}>
              All
            </MenuItem>
            {OPTIONS_LANGUAGE.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              mr: 1,
              background: CommonColors.bismark,
              '&:hover': {
                background: '#486270',
              },
            }}
            onClick={() => dispatch(fetchResults({ ...filter, page: 1 }))}
          >
            {t('button.filter')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: '1px solid #28384580',
              color: CommonColors.gratou,
              '&:hover': {
                border: '1px solid #1e2a3480',
              },
            }}
            onClick={() => {
              setFilter(DEFAULT_PAGINATION);
              dispatch(fetchResults(DEFAULT_PAGINATION));
            }}
          >
            {t('button.reset')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ResultsTable
            onRowAction={onRowAction}
            payload={payload}
            isLoading={isFetchLoading}
            filter={filter}
          />
        </Grid>
      </Grid>
    );
  };

  const renderMain = () => {
    return (
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          {_renderTop()}
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {_renderBody()}
        </Grid>
        <Confirm
          confirm={confirmDialog}
          onCancel={() => onCancel()}
          callback={() => handleDelete(confirmDialog.state)}
        />
      </Grid>
    );
  };
  return <DefaultLayout portalFor="ADMIN" content={renderMain()} />;
};

export default ResultsList;
