export default {
  submit: 'Submit',
  cancel: 'Cancel ',
  clear: 'Clear',
  save: 'Save',
  reset: 'Reset',
  ok: 'Ok',
  close: 'Close',
  back: 'Back',
  loadMore: 'Load more',
  changePassword: 'Change Password',
  userRegistrationForm: 'User Resgistration Form',
  upload: 'Upload',
  export: 'Export',
  viewAll: 'Shiko të gjitha',
  previousPage: 'Faqja e mëparshme',
  users: 'Users',
  login: 'Hyr',
  register: 'Regjistrohu',
  registerNow: 'Regjistrohu tani',
  filter: 'Filter',
  create: 'krijojnë',
  contactUs: 'Na kontakto',
  createCustomers: 'Krijo klientë',
  send: 'Dërgo',
  signIn: 'Hyr',
};
