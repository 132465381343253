import { useLayoutEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Router, useRoutes } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { store, history } from '@/store';
import { CoreTheme } from '@themes';
import UserRouters, { ForbidenRouter } from './User.router';
import AdminRouter from './Admin.router';

// Potals
const RootRouter = ({ history, ...props }: any) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

const App = () => {
  const elements = useRoutes([UserRouters, ForbidenRouter, AdminRouter]);
  return (
    <SnackbarProvider maxSnack={5}>
      <ConfirmProvider>{elements}</ConfirmProvider>
    </SnackbarProvider>
  );
};

const Root = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <RootRouter history={history}>
          <StyledEngineProvider>
            <ThemeProvider theme={CoreTheme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        </RootRouter>
      </Provider>
    </LocalizationProvider>
  );
};

export default hot(Root);
