import { CustommersActions } from '@/Actions';
import { DEFAULT_PAGINATION, LIST } from '@/Constants';
import { ICustomersData } from '@/Interfaces/Customers.interface';
import { useTypedDispatch } from '@/store';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Media from './Media';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';

const { updateCustommers, createCustommers } = CustommersActions;

interface IPropsSystemUser {
  open: boolean;
  type: string;
  onClose(): void;
  data?: ICustomersData;
  pagination: IPaginationFilter;
}

interface isMessage {
  name: string;
  url: string;
  logo: string;
}

const DEFAULT_CUSTOMERS: ICustomersData = {
  languageCode: 'en',
  name: '',
  url: '',
  logo: '',
};

const CustomersDetail: React.FC<IPropsSystemUser> = ({
  open,
  type,
  onClose,
  data,
  pagination,
}) => {
  const { t } = useTranslation('translation');
  const dispatch = useTypedDispatch();
  const [dataCustomers, setDataCustomers] =
    useState<ICustomersData>(DEFAULT_CUSTOMERS);
  const [isMessage, setIsMessage] = useState<isMessage>({
    name: '',
    url: '',
    logo: '',
  });
  const [isOpenMedia, setIsOpenMedia] = useState<{
    isOpen: boolean;
    type: string;
  }>({ isOpen: false, type: '' });

  useEffect(() => {
    if (!_.isEmpty(data)) setDataCustomers(data);
  }, [data]);

  const handleSubmit = () => {
    let flag = false;
    if (
      !_.trim(dataCustomers.name) ||
      !_.trim(dataCustomers.logo) ||
      !_.trim(dataCustomers.url)
    ) {
      setIsMessage({
        ...isMessage,
        name: !_.trim(dataCustomers.name)
          ? `${t('message.canNotLeaveTheNameBlank')}`
          : '',
        url: !_.trim(dataCustomers.url)
          ? `${t('message.canNotLeaveTheUrlBlank')}`
          : '',
        logo: !_.trim(dataCustomers.logo)
          ? `${t('message.canNotLeaveTheLogoBlank')}`
          : '',
      });
      flag = true;
    }
    if (!flag) {
      if (_.isEmpty(data)) {
        dispatch(createCustommers(dataCustomers, DEFAULT_PAGINATION));
        onClose();
      } else {
        dispatch(updateCustommers(pagination, dataCustomers));
        onClose();
      }
    }
  };

  return (
    <Dialog open={open} keepMounted fullWidth>
      <DialogTitle>
        {type === 'create'
          ? t('title.createCustomers')
          : t('title.updateCustomer')}
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ border: '1px solid #0000001f', p: 4 }}>
          <Box display={'flex'} mb={2} mt={1}>
            <InputLabel
              sx={{
                fontWeight: '500',
                fontSize: 16,
                mt: 1,
                minWidth: '13vh',
              }}
            >
              {t('label.language')}
            </InputLabel>
            <Select
              value={dataCustomers.languageCode}
              size="small"
              onChange={(e: any) =>
                setDataCustomers({
                  ...dataCustomers,
                  languageCode: e.target.value,
                })
              }
              sx={{
                minWidth: '100px',
                '	.MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                },
                height: '45px',
              }}
            >
              {LIST.DROPDOWN_LIST.OPTIONS_LANGUAGE.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Box display={'flex'} mb={2} mt={1}>
            <InputLabel sx={{ width: '16vh', fontWeight: '500' }}>
              {t('label.name')}
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              value={dataCustomers.name || ''}
              label={t('label.name')}
              required
              onChange={(e: any) => {
                setDataCustomers({ ...dataCustomers, name: e.target.value });
                setIsMessage({ ...isMessage, name: '' });
              }}
              error={!_.isEmpty(isMessage.name)}
              helperText={isMessage.name}
            />
          </Box>
          <Box display={'flex'}>
            <InputLabel sx={{ width: '16vh', fontWeight: '500' }}>
              {t('label.url')}
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              value={dataCustomers.url}
              label={'Url'}
              required
              onChange={(e: any) => {
                setDataCustomers({ ...dataCustomers, url: e.target.value });
                setIsMessage({ ...isMessage, url: '' });
              }}
              error={!_.isEmpty(isMessage.url)}
              helperText={isMessage.url}
            />
          </Box>
          <Box mt={1}>
            <InputLabel sx={{ fontWeight: '500', my: 1 }}>
              {t('label.linkImage')}
            </InputLabel>
            <TextField
              value={dataCustomers.logo || ''}
              size="small"
              label={t('label.linkImage')}
              required
              fullWidth
              onChange={(e: any) => {
                setDataCustomers({ ...dataCustomers, logo: e.target.value });
                setIsMessage({ ...isMessage, logo: '' });
              }}
              error={!_.isEmpty(isMessage.logo)}
              helperText={isMessage.logo}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setIsOpenMedia({
                          isOpen: true,
                          type: 'logo',
                        });
                      }}
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <LoadingButton
            variant="contained"
            color="fuzzyWuzzyBrown"
            onClick={() => onClose()}
          >
            {t('button.back')}
          </LoadingButton>
          <LoadingButton
            type="submit"
            variant="contained"
            color="oceanGreen"
            onClick={() => handleSubmit()}
          >
            {t('button.save')}
          </LoadingButton>
        </Box>
      </DialogContent>
      {isOpenMedia.isOpen && (
        <Media
          onOpen={{
            status: isOpenMedia.isOpen,
            type: isOpenMedia.type,
          }}
          onClose={() => {
            setIsOpenMedia({ isOpen: false, type: '' });
          }}
          callback={(file: any, type: string) => {
            if (type) {
              setDataCustomers({ ...dataCustomers, logo: file });
              setIsOpenMedia({ isOpen: false, type: '' });
            }
          }}
        />
      )}
    </Dialog>
  );
};

export default CustomersDetail;
