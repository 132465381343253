import {
  ACTION_TYPES,
  DEFAULT_PAGINATION,
  DEFAULT_LOADING_STATES,
  ENUMS,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: {
    ...DEFAULT_PAGINATION,
    page: 1,
    limit: 12,
    status: ENUMS.STATUS.ACTIVE,
  },
  meta: null,
  galleries: [],
  gallery: null,
  isUploadSuccess: false,
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.CLEAR_UPLOAD: {
      return {
        ...state,
        meta: null,
        galleries: [],
        gallery: null,
        isUploadSuccess: false,
      };
    }
    case ACTION_TYPES.SET_UPLOAD_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_UPLOAD_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.SET_UPLOAD_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_UPLOAD_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.SET_UPLOAD_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: payload,
      };
    }
    case ACTION_TYPES.RESET_UPLOAD_REDUCER:
      return DEFAULT_STATES;

    case ACTION_TYPES.FETCH_UPLOAD_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isFetchLoading: false,
        isUploadSuccess: false,
        galleries: payload,
      };
    case ACTION_TYPES.FETCH_UPLOAD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isFetchLoading: false,
        isUploadSuccess: false,
      };

    case ACTION_TYPES.GET_UPLOAD_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        gallery: payload,
      };
    case ACTION_TYPES.GET_UPLOAD_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.CREATE_UPLOAD_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
        isUploadSuccess: true,
      };
    case ACTION_TYPES.CREATE_UPLOAD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
        isUploadSuccess: false,
      };
    case ACTION_TYPES.DELETE_UPLOAD_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.DELETE_UPLOAD_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };

    default:
      return state;
  }
};
