import { sendRequest } from '@/Configs';
import { API } from '@constants';
import { IUserData } from '@/Interfaces/SystemUser.interface';
import { IPaginationFilter } from '@/Interfaces/PaginationMeta.interface';

const { SYSTEM_USER } = API;

export const createSystemUser = async (payload: IUserData) => {
  return await sendRequest(SYSTEM_USER.ROOT, 'POST', payload);
};

export const fetchSystemUser = async (payload: IPaginationFilter) => {
  return sendRequest(SYSTEM_USER.ROOT, 'GET', payload);
};

export const getSyestemUsersById = async (payload: any) => {
  return sendRequest(`${SYSTEM_USER.ROOT}${payload.id}`, 'GET');
};

export const changePasswordSystemUser = async (payload: any) => {
  return sendRequest(SYSTEM_USER.CHANGE_PASSWORD, 'PUT', payload);
};

export const changeStatusSystemUser = async (payload: any) => {
  return sendRequest(SYSTEM_USER.CHANGE_STATUS, 'PUT', payload);
};
