import {
  ACTION_TYPES,
  DEFAULT_LOADING_STATES,
  DEFAULT_PAGINATION,
} from '@constants';

const DEFAULT_STATES = {
  ...DEFAULT_LOADING_STATES,
  pagination: DEFAULT_PAGINATION,
  meta: null,
  contact: [],
  contactDetail: {},
};

export default (
  state = DEFAULT_STATES,
  action: { type: string; payload: string }
) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION_TYPES.SET_CONTACT_PAGINATION:
      return {
        ...state,
        pagination: payload,
      };
    case ACTION_TYPES.SET_CONTACT_META:
      return {
        ...state,
        meta: payload,
      };
    case ACTION_TYPES.CLEAR_CONTACT: {
      return {
        ...state,
        contact: [],
        contactDetail: {},
      };
    }
    case ACTION_TYPES.SET_CONTACT_ACTION_LOADING: {
      return {
        ...state,
        isActionLoading: true,
      };
    }
    case ACTION_TYPES.SET_CONTACT_FETCH_LOADING: {
      return {
        ...state,
        isFetchLoading: payload,
      };
    }
    case ACTION_TYPES.SET_CONTACT_GET_LOADING: {
      return {
        ...state,
        isGetLoading: payload,
      };
    }
    case ACTION_TYPES.GET_CONTACT_BY_ID_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        contactDetail: payload,
      };
    case ACTION_TYPES.GET_CONTACT_BY_ID_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.FETCH_CONTACT_SUCCESS:
      return {
        ...state,
        requestIsSuccess: true,
        requestHasError: false,
        isGetLoading: false,
        contact: payload,
      };
    case ACTION_TYPES.FETCH_CONTACT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isGetLoading: false,
      };
    case ACTION_TYPES.SEND_CONTACT_SUCCESS:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    case ACTION_TYPES.SEND_CONTACT_FAILURE:
      return {
        ...state,
        requestHasError: true,
        requestIsSuccess: false,
        isActionLoading: false,
      };
    default:
      return state;
  }
};
