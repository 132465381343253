export const DEFAULT_CONFIRM = {
  type: '',
  isOpen: false,
  message: '',
  state: {
    id: '',
    status: '' ,
    type: '',
  },
};
