import React from 'react';

import { AppBar, Stack, IconButton, Drawer } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import LanguageSwitch from './LanguageSwitch';

const drawerWidth = 240;

// interface IProps {
//   window?: () => Window;
//   items: IMenu[];
// }

const NavBar: React.FC = () => {
  // Declare states
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const _renderLanguageSwitch = () => <LanguageSwitch />;

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'white',
        color: '#42526e',
        fontSize: '14px',
        top: 0,
        zIndex: 100,
        minHeight: 60,
      }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: false,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            transition: 'all ease 0.25s',
            boxSizing: 'border-box',
            width: drawerWidth,
            overflow: 'hidden',
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#f4f5f7',
            color: 'sidebar.text',
          },
        }}
      ></Drawer>
      <Stack direction="row" alignItems="center">
        <IconButton
          onClick={() => setMobileOpen(true)}
          sx={{ ml: 1, display: { xs: 'block', sm: 'none' } }}
        >
          <MenuOutlinedIcon fontSize="small" />
        </IconButton>
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '5px 16px',
            flex: 1,
          }}
        >
          {_renderLanguageSwitch()}
        </Stack>
      </Stack>
    </AppBar>
  );
};

export default NavBar;
