export default {
  // Side menu
  dashboard: 'PULT',
  systemUser: 'PËRDORUESI I SISTEMIT',
  about: 'ABOUT',
  logout: 'LOGOUT',
  INBOX: 'INBOX',
  contact: 'KONTAKTONI',
  upload: 'NGARKONI',
  whatIsIDRAPoll: 'Çfarë është IDRApoll',
  productsAndServices: 'Produktet dhe shërbimet',
  whyIDRApoll: 'Pse IDRApoll',
  statistical: 'Statistikore',
  resultsFromIDRApoll: 'Rezultatet nga IDRApoll',
  customers: 'Klientët',
  //Appbar menu
  whatIsIDRApoll: 'Çfarë është?',
  rewards: 'Shpërblimet',
  results: 'Rezultate',
  forClients: 'Për Klientët',
  Unsubscribe: 'Çregjistrohu në',
};
