import { sendRequest } from '@/Configs';
import { API } from '@constants';

const { INBOX } = API;

export const getInbox = async (id: string) => {
  return await sendRequest(`${INBOX.ROOT}${id}`, 'GET');
};

export const fetchInbox = async (payload: any) => {
  return await sendRequest(INBOX.ROOT, 'GET', payload);
};

export const unsubscribe = async (payload: any) => {
  return await sendRequest(INBOX.ROOT, 'POST', payload);
};

export const exportInbox = async (payload: any) => {
  return await sendRequest(`${INBOX.ROOT}export/unsubscribes`, 'GET', payload);
};
