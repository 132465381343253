import { sendRequest } from '@/Configs';
import { IDataCreateStatistical } from '@/Interfaces/Statistical.interface';
import { API } from '@constants';

const { STATISTICAL } = API;

export const fetchStatistical = async (id: string) => {
  return await sendRequest(`${STATISTICAL.ROOT}${id}`, 'GET');
};

export const updateStatistical = async (payload: IDataCreateStatistical) => {
  return sendRequest(
    `${STATISTICAL.ROOT}${payload.languageCode}`,
    'PUT',
    payload
  );
};

export const createStatistical = async (payload: IDataCreateStatistical) => {
  return sendRequest(STATISTICAL.ROOT, 'POST', payload);
};
